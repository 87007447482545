<template>
  <div class="full-container">
    <div
      style="width: 100%;float: left;height: 50px;line-height: 50px;font-size: 0.9rem; text-align: left;opacity: 0.9;">
      订单>提现结算
    </div>
    <div style="width:100%;height:auto;float:left;border-radius: 15px;background-color: white;padding-bottom:40px;">

      <div style="width:100%;float:left;margin-top: 10px;">
        <!-- 查询按钮区 -->
        <div style="width: 100%;height: 60px;float: left;line-height: 60px;padding-left: 15px">


        </div>

        <!-- 表单内容 -->
        <div style="width:100%;float:left;padding: 15px;">
          <el-table align="center" @selection-change="handleSelectionChange" height="600" :data="tableData"   style="color: #555;" size="mini">
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column align="center" label="订单号" min-width="20%">
              <template slot-scope="scope">
                {{ scope.row.orderVO ? scope.row.orderVO.orderNo : ""}}
              </template>
            </el-table-column>
            <el-table-column align="center" label="产品名称" min-width="20%">
              <template slot-scope="scope">
                {{ scope.row.orderVO.productVO ? scope.row.orderVO.productVO.name : ""}}
              </template>
            </el-table-column>
            <el-table-column align="center" label="产品金额" min-width="20%">
              <template slot-scope="scope">
                {{ scope.row.orderVO? scope.row.orderVO.productMoney : ""}}
              </template>
            </el-table-column>
            <el-table-column align="center" label="支付金额" min-width="20%">
              <template slot-scope="scope">
                {{ scope.row.orderVO ? scope.row.orderVO.payMoney : ""}}
              </template>
            </el-table-column>

            <el-table-column align="center" label="提现手续费" min-width="20%">
              <template slot-scope="scope">
                0.6%
              </template>
            </el-table-column>
            <el-table-column align="center" label="提现金额" min-width="20%">
              <template slot-scope="scope">
                {{ scope.row.orderVO ? mexFeixd(scope.row.orderVO.payMoney * (1-0.006)) : ""}}
              </template>
            </el-table-column>
            <el-table-column align="center" label="状态" min-width="20%">
              <template slot-scope="scope">
                <span style="color: #409EFF">{{scope.row.isSettlementStr}}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="创建时间" min-width="20%">
              <template slot-scope="scope">
                {{ parseDate(scope.row.createDate ? scope.row.createDate : "") }}
              </template>
            </el-table-column>
          </el-table>
          <div style="width: 100%;float: right;margin-top: 10px;text-align: right;">
            <el-pagination
              @current-change="handleCurrentChange"
              :page-size="selectForm.pageSize"
              :current-page="selectForm.pageIndex"
              layout="total,prev, pager, next"
              :total="selectForm.total">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>





  </div>
</template>

<script>


import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { getAlexBoolListApi } from "@/router/api/common/data-api";

import { pageMerchantOrderSettlementApi } from "@/router/api/order/order-settlement-api";

export default {
  name: "MerchantOrderSettlement",
  data() {
    return {
      alexBoolList: [],

      // 查询
      tableData: [],
      selectForm: {

        pageIndex: 1,
        pageSize: 10,
        total: 0
      },
      selectTableRow: [],




    };
  },

  mounted() {

    this.load();


  },

  methods: {

    load() {

      getAlexBoolListApi({}, res => {
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.alexBoolList = res.data.resultSetData;
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });

      this.pageMerchantOrderSettlement();
    },


    // 查询 begin
    selectReset() {
      this.selectForm = {
        name: null,
        isShelf: null,
        pageIndex: 1,
        pageSize: 10,
        total: 0
      };
      this.pageMerchantOrderSettlement(true);
    },

    handleCurrentChange(e) {
      this.selectForm.pageIndex = e;
      this.pageMerchantOrderSettlement(true);
    },

    handleSelectionChange(val) {
      this.selectTableRow = val;
    },

    pageMerchantOrderSettlement(showMessage) {
      pageMerchantOrderSettlementApi(this.selectForm, res => {
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              if (showMessage) {
                this.showMessage(res.data.msg, "success");
              }
              this.tableData = res.data.resultSetData.list;
              console.info(this.tableData);
              this.selectForm.pageIndex = res.data.resultSetData.pageNum;
              this.selectForm.pageSize = res.data.resultSetData.pageSize;
              this.selectForm.total = res.data.resultSetData.total;
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });
    },



    // 修改 end

    // 工具

    mexFeixd(value){
      if(value){
        return value.toFixed(2);
      }
      return value;
    },

    parseDate(date) {
      if (date) {
        return new Date(date).toLocaleString();
      }
      return "";
    }
  }


}
;

</script>


<style scoped>

/deep/ .el-card__body, .el-main {
  float: left;
  width: 100%;
}

/*  通用 */
.dialog-footer {
  width: 100%;
  float: left;
  text-align: left;
}

.dialog-footer-submit {
  background-color: #25AD7F;
  color: white;
  width: 130px;
  border-radius: 50px;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 100;
}

.dialog-footer-submit:hover {
  background-color: #25AD7F;
  color: white;
  width: 130px;
  border-radius: 50px;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 100;
}

.dialog-footer-cancel {
  background-color: white;
  border: 1px solid #8080805c;
  color: #8080805c;
  width: 130px;
  border-radius: 50px;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 100;
}

.dialog-footer-cancel:hover {
  background-color: white;
  border: 1px solid #8080805c;
  color: #8080805c;
  width: 130px;
  border-radius: 50px;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 100;
}

/* 树形菜单 */

/deep/ .el-tree-node__content {
  height: 35px;
  line-height: 35px;
}

/* 表格 */

/deep/ .el-table th.el-table__cell {
  background-color: #F8F8F8;
}

/deep/ .el-table th.el-table__cell > .cell {
  color: #554 !important;
  font-weight: 600;
  font-size: 1.05rem;
}


/* 模态框 */

/deep/ .el-dialog__title {
  font-weight: 600;
}

/deep/ .el-dialog__header {
  text-align: left;
  border-bottom: 1px solid darkgray;
}

/deep/ .el-dialog__body {
  width: 100%;
  float: left;
  background-color: white;
}

/deep/ .el-upload-dragger {
  height: auto;
}

/deep/ .el-form-item__content {
  text-align: left;
}


/* 纯数字 */
/deep/ .numberInput input::-webkit-outer-spin-button,
/deep/ .numberInput input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

/deep/ .numberInput input[type="number"] {
  -moz-appearance: textfield;
}

</style>
