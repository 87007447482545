<template>
  <div class="home-container" style="background-color: #F8F9FB;padding: 0% 15%;overflow-y: auto;">
    <div class="title-name">
      托托农业平台-商户入驻申请
    </div>
    <div class="form-container">
      <div class="form-row">
        <div class="form-row-left" style="font-size: 1.4rem">主体信息</div>
        <div class="form-row-right"></div>
      </div>
      <div class="form-row">
        <div class="form-row-left" style="opacity: 0.8;">企业名称</div>
        <div class="form-row-right">
          <el-input style="width: 50%" placeholder="企业名称" clearable v-model="insertForm.companyName"  oninput="if(value.length>35)value=value.slice(0,35)"></el-input>
        </div>
      </div>
      <div class="form-row">
        <div class="form-row-left" style="opacity: 0.8;">注册地址</div>
        <div class="form-row-right">
          <el-input style="width: 50%" placeholder="注册地址" clearable v-model="insertForm.companyAddress"  oninput="if(value.length>50)value=value.slice(0,50)"></el-input>
        </div>
      </div>
      <div class="form-row">
        <div class="form-row-left" style="opacity: 0.8;">统一社会信用代码</div>
        <div class="form-row-right">
          <el-input style="width: 50%" placeholder="统一社会信用代码" clearable
                    v-model="insertForm.companyIdCard"  oninput="if(value.length>35)value=value.slice(0,35)"></el-input>
        </div>
      </div>
      <div class="form-row">
        <div class="form-row-left" style="opacity: 0.8;">服务类型</div>
        <div class="form-row-right">
          <el-checkbox true-label=1 false-label=0 v-model="insertForm.isWorkflow" >具备流程服务</el-checkbox>
        </div>
      </div>

      <div class="form-row">
        <div class="form-row-left" style="opacity: 0.8;">营业执照</div>
        <div class="form-row-right">
          <el-upload action="#" list-type="picture-card" :on-change="changeCompanyIdCard"
                     :show-file-list="false" :multiple="false" :auto-upload="false">
            <el-image fit="contain" v-if="companyIdCardFile" :src="companyIdCardFile.url" class="avatar"/>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>
      </div>
      <hr style="width: 100%;float: left;opacity: 0.5"/>

      <div class="form-row">
        <div class="form-row-left" style="font-size: 1.4rem">负责人</div>
        <div class="form-row-right"></div>
      </div>
      <div class="form-row">
        <div class="form-row-left" style="opacity: 0.8;">姓名</div>
        <div class="form-row-right">
          <el-input style="width: 50%" placeholder="姓名" clearable v-model="insertForm.chargeUserName" oninput="if(value.length>15)value=value.slice(0,15)"></el-input>
        </div>
      </div>
      <div class="form-row">
        <div class="form-row-left" style="opacity: 0.8;">身份证号码</div>
        <div class="form-row-right">
          <el-input style="width: 50%" placeholder="身份证号码" clearable
                    v-model="insertForm.chargeUserIdCard" oninput="if(value.length>30)value=value.slice(0,30)"></el-input>
        </div>
      </div>
      <div class="form-row">
        <div class="form-row-left" style="opacity: 0.8;">手机号码</div>
        <div class="form-row-right">
          <el-input style="width: 50%" placeholder="手机号码" type="number" clearable class="numberInput" maxlength="11"
                    v-model="insertForm.chargeUserTel" oninput="if(value.length>11)value=value.slice(0,11)">
<!--            <el-button slot="append">发送验证码</el-button>-->
          </el-input>
        </div>
      </div>
      <div class="form-row">
        <div class="form-row-left" style="opacity: 0.8;">邮箱</div>
        <div class="form-row-right">
          <el-input style="width: 50%" placeholder="邮箱" clearable v-model="insertForm.chargeUserMail" oninput="if(value.length>30)value=value.slice(0,30)"></el-input>
        </div>
      </div>

      <div class="form-row">
        <div class="form-row-left" style="opacity: 0.8;">身份证（正反面）</div>
        <div class="form-row-right">

          <el-upload :limit=3 action="#" list-type="picture-card" :multiple="true" :on-change="changeChargeUserIdCard"
                     :file-list="chargeUserIdCardFileList" :auto-upload="false">
            <i slot="default" class="el-icon-plus"></i>
            <div slot="file" slot-scope="{file}">
              <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
              <span class="el-upload-list__item-actions">
                  <span class="el-upload-list__item-preview" @click="chargeUserIdCardShowDialog(file)">
                    <i class="el-icon-zoom-in"></i>
                  </span>
                  <span class="el-upload-list__item-delete" @click="removeChargeUserIdCard(file)">
                    <i class="el-icon-delete"></i>
                  </span>
                </span>
            </div>
          </el-upload>
          <!-- 大图显示 -->
          <el-dialog :visible.sync="chargeUserIdCardShow">
            <img width="100%" :src="chargeUserIdCardShowUrl" alt="">
          </el-dialog>
        </div>
      </div>


      <div style="width: 100%;float: left;text-align: left;font-size: 0.9rem;margin-bottom: 30px;">
        <el-checkbox v-model="isPermit"/> &nbsp;您已阅读并同意
        <span style="color: #409EFF">《托托农业平台管理协议》</span>、
        <span style="color: #409EFF">《托托农业平台服务条款》</span>、
        <span style="color: #409EFF">《托托农业平台隐私说明》</span>
      </div>

      <div style="width: 100%;float: left;">
        <el-button style="width: 150px; background-color: #25ac7f;color: white;margin-bottom: 30px;"
                   @click="insertSubmit">注册
        </el-button>
      </div>

    </div>
  </div>
</template>

<script>

export default {
  name: "MerchantApply",
  data() {
    return {
      insertForm: {
        companyName: null,
        companyAddress: null,
        companyIdCard: null,
        isWorkflow:0,

        chargeUserName: null,
        chargeUserIdCard: null,
        chargeUserTel: null,
        chargeUserMail: null
      },

      //营业执照
      companyIdCardFile: null,

      //身份证正反面
      chargeUserIdCardFileList: [],
      chargeUserIdCardShow: false,//是否显示大图
      chargeUserIdCardShowUrl: "",//大图的url

      isPermit: false //是否同意

    };
  },

  created() {

  },

  mounted() {
  },

  methods: {

    // 营业执照上传
    changeCompanyIdCard(file) {
      this.companyIdCardFile = file;
    },

    //身份证上传
    changeChargeUserIdCard(file, fileList) {
      this.chargeUserIdCardFileList = fileList;
    },

    removeChargeUserIdCard(file) {
      for (let item in this.chargeUserIdCardFileList) {
        if (item && this.chargeUserIdCardFileList[item] && file && this.chargeUserIdCardFileList[item].uid == file.uid) {
          this.chargeUserIdCardFileList.splice(item, 1);
        }
      }
    },

    chargeUserIdCardShowDialog(e) {
      this.chargeUserIdCardShowUrl = e.url;
      this.chargeUserIdCardShow = true;
    },

    // isWorkflowChange(e){
    //   if(e){
    //     this.insertForm.isWorkflow = 1;
    //   }else{
    //     this.insertForm.isWorkflow = 0;
    //   }
    // },

    insertSubmit() {
      //TODO 非空判断
      if (!this.insertForm.companyName) {
        this.showMessage("请填写企业名称", "error");
        return false
      }

      if (!this.insertForm.companyAddress) {
        this.showMessage("请填写注册地址", "error");
        return false
      }

      if (!this.insertForm.companyIdCard) {
        this.showMessage("请填写统一社会信用代码", "error");
        return false
      }

      if (!this.insertForm.chargeUserName) {
        this.showMessage("请填写负责人姓名", "error");
        return false
      }

      if (!this.insertForm.chargeUserIdCard) {
        this.showMessage("请填写负责人身份证号码", "error");
        return false
      }

      if (!this.insertForm.chargeUserTel) {
        this.showMessage("请填写负责人手机号码", "error");
        return false
      }
      if (!this.insertForm.chargeUserMail) {
        this.showMessage("请填写负责人邮箱", "error");
        return false
      }

      if (!this.isPermit) {
        this.showMessage("请同意并勾选《托托农业平台》相关条款", "warning");
        return false
      }

      //数据组装
      let params = new FormData();
      params.append("companyName", this.insertForm.companyName);
      params.append("companyAddress", this.insertForm.companyAddress);
      params.append("companyIdCard", this.insertForm.companyIdCard);
      params.append("chargeUserName", this.insertForm.chargeUserName);
      params.append("chargeUserIdCard", this.insertForm.chargeUserIdCard);
      params.append("chargeUserTel", this.insertForm.chargeUserTel);
      params.append("chargeUserMail", this.insertForm.chargeUserMail);
      params.append("isWorkflow", this.insertForm.isWorkflow);

      if (this.companyIdCardFile) {
        params.append("companyIdCardMultipartFile", this.companyIdCardFile.raw);
      } else {
        this.showMessage("请上传公司营业执照", "error");
        return false
      }

      if (this.chargeUserIdCardFileList && this.chargeUserIdCardFileList.length > 0) {
        for (let i = 0; i < this.chargeUserIdCardFileList.length; i++) {
          let temp = this.chargeUserIdCardFileList[i].raw;
          params.append("chargeUserIdCardMultipartFile[]", temp);
        }
      } else {
        this.showMessage("请上传负责人的身份证正反面", "error");
        return false
      }

      let url = "/admin/web/merchantDetail/register";
      this.alexPost(url, params, res => {
        //状态200
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.showMessage(res.data.msg, "success");
              this.$router.push("/merchantSuccess");
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      }, {
        headers: {
          "Content-Type": "multipart/form-data",
          "token": localStorage.getItem("token")
        }
      });
    }
  }


};


</script>

<style scoped>
.home-container {
  width: 100%;
  height: 100%;
}


.title-name {
  width: 100%;
  height: 100px;
  line-height: 100px;
  font-weight: 600;
  font-size: 2rem;
  color: #25ac7f
}

.form-container {
  width: 100%;
  float: left;
  background-color: white;
  border-radius: 8px;
  padding: 50px 200px
}

.form-row {
  width: 100%;
  float: left;
  margin-bottom: 30px
}

.form-row-left {
  width: 20%;
  text-align: left;
  float: left;
  height: 40px;
  line-height: 40px;
}

.form-row-right {
  width: 80%;
  text-align: left;
  float: left;
}


/deep/ .el-checkbox__inner {
  border: 1px solid #409EFF
}


/* 纯数字 */
/deep/ .numberInput input::-webkit-outer-spin-button,
/deep/ .numberInput input::-webkit-inner-spin-button {
  -webkit-appearance: none!important;
}
/deep/.numberInput input[type="number"]{
  -moz-appearance: textfield;
}
</style>
