import Vue from "vue";

export function listMerchantSquareApi(params, success) {
  let url = "/admin/web/square/listMerchantSquare";
  Vue.prototype.alexPost(url, params, success);
}

export function listAllSquareApi(params, success) {
  let url = "/admin/web/square/listAllSquare";
  Vue.prototype.alexPost(url, params, success);
}

export function updateIsDelApi(params, success) {
  let url = "/admin/web/square/updateIsDel";
  Vue.prototype.alexPost(url, params, success);
}

export function updateIsLockApi(params, success) {
  let url = "/admin/web/square/updateIsLock";
  Vue.prototype.alexPost(url, params, success);
}
