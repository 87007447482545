import Vue from "vue";

export function pageAllProductApi(params, success) {
  let url = "/admin/web/product/pageAllProduct";
  Vue.prototype.alexPost(url, params, success);
}

export function pageMerchantProductApi(params, success) {
  let url = "/admin/web/product/pageMerchantProduct";
  Vue.prototype.alexPost(url, params, success);
}

export function deleteProductApi(params, success) {
  let url = "/admin/web/product/deleteProduct";
  Vue.prototype.alexPost(url, params, success);
}

export function updateIsShelfApi(params, success) {
  let url = "/admin/web/product/updateIsShelf";
  Vue.prototype.alexPost(url, params, success);
}


