import Vue from "vue";

export function getGoodsListApi(params, success) {
    let url = "/admin/goods/getGoodsList";
    Vue.prototype.alexPost(url, params, success);
}

export function updateGoodsIsShelfApi(params, success,headers) {
    let url = "/admin/goods/updateGoodsIsShelf";
    Vue.prototype.alexPost(url, params, success,headers);
}

export function updateGoodsIsHotApi(params, success,headers) {
    let url = "/admin/goods/updateGoodsIsHot";
    Vue.prototype.alexPost(url, params, success,headers);
}


export function deleteGoodsApi(params, success){
    let url = "/admin/goods/deleteGoods";
    Vue.prototype.alexPost(url, params, success);
}

export function getGoodsInfoApi(params, success){
    let url = "/admin/goods/getGoodsInfo";
    Vue.prototype.alexPost(url, params, success);
}
