import Vue from "vue";


export function treeAllKnowledgeTypeApi(params, success) {
    let url = "/admin/web/knowledgeType/treeAllKnowledgeType";
    Vue.prototype.alexPost(url, params, success);
}

export function insertKnowledgeTypeApi(params, success) {
    let url = "/admin/web/knowledgeType/insertKnowledgeType";
    Vue.prototype.alexPost(url, params, success);
}

export function updateKnowledgeTypeApi(params, success) {
    let url = "/admin/web/knowledgeType/updateKnowledgeType";
    Vue.prototype.alexPost(url, params, success);
}

export function deleteKnowledgeTypeApi(params, success) {
    let url = "/admin/web/knowledgeType/deleteKnowledgeType";
    Vue.prototype.alexPost(url, params, success);
}
