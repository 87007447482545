import Vue from "vue";
import App from "./App.vue";
import TDesign from 'tdesign-vue';
import router from "./router";
import store from "./store";
import axios from "axios";
import GlobalConstant from "@/components/system/GlobalConstant";

import "video.js/dist/video-js.css";

import "@/assets/iconfont/iconfont.css";

import 'tdesign-vue/es/style/index.css';

// TODO 按需导入ElementUI组件
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

Vue.use(ElementUI);
Vue.use(TDesign);

Vue.config.productionTip = false;

// TODO 按需导入Antd组件
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";

Vue.use(Antd);

//请求拦截器
axios.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});



//响应拦截器
axios.interceptors.response.use(function (response) {
    console.info("response success");
    // 对响应数据做点什么
    return response;
}, function (error) {
    console.info(error);
    if (error.response.status == 401) {
        router.push("/login");
    } else if (error.response.status == 403) {
        vueSimple.$message({message: "暂无权限:" + error.request.responseURL, type: "error"});
    }else if (error.response.status == 500) {
        if(error.response.data.msg){
            vueSimple.$message({message: error.response.data.msg, type: "error"});
        }else{
            vueSimple.$message({message: "系统内部错误,请稍后重试（"+error.request.responseURL+"）", type: "error"});
        }

    }
    // 对响应错误做点什么
    return Promise.reject(error);
});
// axios.interceptors.response.use((response) => {
//     if (response.status === 200) {
//         return response;
//     } else if (response.status === 401) {
//         router.push("/login");
//     } else if (response.status === 403) {
//         router.push("/isPassword");
//     }
// }, (error) => {
//     return error;
// })


//导出
//定义全局的vue变量
Vue.prototype.axios = axios;
Vue.prototype.GlobalConstant = GlobalConstant;
Vue.prototype.$message = ElementUI.Message;

//定义全局的vue方法
Vue.prototype.showMessage = function (message, type) {
    //type: success/warning/info/error
    this.$message({message: message, type: type});
};

//全局的post请求
Vue.prototype.alexPost = function (url, params, success, headerParams) {
    if (!headerParams) {
        headerParams = {
            headers: {
                "token": localStorage.getItem("token")
            }
        };
    }
    this.axios.post(url, params, headerParams).then(function (res) {
        console.info("axios then");
        success(res)
    }).catch(function (error) {
        console.info("axios cache");
        if (error.response) {
            console.error(error.response);
        }

    });
};


//全局vue
let vueSimple = new Vue({
    router,
    store,
    render: (h) => h(App)
}).$mount("#app");
