<template>
  <div class="full-container">
    <div
      style="width: 100%;float: left;height: 50px;line-height: 50px;font-size: 0.9rem; text-align: left;opacity: 0.9;">
      农业库>农作物
    </div>
    <div style="width:100%;height:auto;float:left;border-radius: 15px;background-color: white;padding-bottom:40px;">

      <div style="width:100%;float:left;margin-top: 10px;">
        <!-- 查询按钮区 -->
        <div style="width: 100%;height: 60px;float: left;line-height: 60px;padding-left: 15px">

          <!-- 右边按钮 -->
          <div style="width: auto;float: right;margin-right: 20px">

            <el-button size="mini" style="background-color: #25AD7F;border:none;" type="primary" @click="insertShow()">
              <a-icon type="plus-circle" />
              新增农作物
            </el-button>

            <a-popconfirm title="是否删除?(敏感操作!)" ok-text="确定" cancel-text="取消"
                          @confirm="deleteCropBatch()">
              <el-button size="mini" style="background-color: #e32e2e1a;color:#e32e2e;border:1px solid #e32e2e;"
                         type="primary">
                <a-icon type="minus-circle" />
                批量删除
              </el-button>
            </a-popconfirm>
          </div>
        </div>

        <!-- 表单内容 -->
        <div style="width:100%;float:left;padding: 15px;">
          <el-table align="center" @selection-change="handleSelectionChange" height="600" :data="tableData"
                    style="color: #555;" size="mini">
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column align="center" prop="name" label="农作物名称" min-width="20%"></el-table-column>

            <el-table-column align="center" label="创建时间" min-width="20%">
              <template slot-scope="scope">
                {{ parseDate(scope.row.createDate ? scope.row.createDate : "") }}
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作" min-width="20%">
              <template slot-scope="scope">
                <span style="font-size:0.9rem;color:#25AD7F;cursor: pointer;"
                      @click="detailShow(scope.row)">详情</span>
                <span style="margin:0px 5px">|</span>
                <span style="font-size:0.9rem;cursor: pointer;color: #409EFF"
                      @click="updateShow(scope.row)">修改</span>
                <a-popconfirm title="是否删除?(敏感操作!)" ok-text="确定" cancel-text="取消"
                              @confirm="deleteCrop(scope.row)">
                  <span style="margin:0px 5px">|</span>
                  <span style="font-size:0.9rem;color:#e32e2e;cursor: pointer;">删除</span>
                </a-popconfirm>
              </template>
            </el-table-column>
          </el-table>
          <div style="width: 100%;float: right;margin-top: 10px;text-align: right;">
            <el-pagination
              @current-change="handleCurrentChange"
              :page-size="selectForm.pageSize"
              :current-page="selectForm.pageIndex"
              layout="total,prev, pager, next"
              :total="selectForm.total">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>

    <el-dialog title="新增农作物" :visible.sync="insertDialog" width="40%" :before-close="insertCancel">
      <div style="width: 100%;float: left">
        <el-form label-width="15%" style="width:100%;float:left;">

          <el-form-item style="">
            <template slot="label">
              农作物名称 <span style="color: red">*</span>
            </template>
            <el-input size="mini" style="width:85%;float: left;" v-model="insertForm.name"
                      oninput="if(value.length>35)value=value.slice(0,35)" />
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>
        </el-form>

      </div>
      <div class="dialog-footer">
        <el-button class="dialog-footer-submit" @click="insertSubmit">提交</el-button>
        <el-button class="dialog-footer-cancel" @click="insertCancel">取消</el-button>
      </div>
    </el-dialog>

    <!-- 详情 -->
    <el-dialog title="农作物详情" :visible.sync="detailDialog" width="40%" :before-close="detailCancel">
      <div style="width: 100%;float: left">
        <div
          style="width: 100%;float: left;border-bottom: 1px solid #80808054;padding-bottom: 15px;margin-bottom: 20px;"
          v-if="detailInfo">
          <el-descriptions :title="detailInfo.name?detailInfo.name:''" :column=2>
            <el-descriptions-item label="农作物名称">{{ detailInfo.goodsTypeStr ? detailInfo.goodsTypeStr : "" }}
            </el-descriptions-item>
            <el-descriptions-item label="创建时间" :span="2">
              {{ parseDate(detailInfo.createDate ? detailInfo.createDate : "")
              }}
            </el-descriptions-item>
          </el-descriptions>
        </div>

      </div>
      <div class="dialog-footer">
        <el-button class="dialog-footer-cancel" @click="detailCancel">关闭</el-button>
      </div>
    </el-dialog>

    <!-- 修改 -->
    <el-dialog title="修改农作物" :visible.sync="updateDialog" width="40%" :before-close="updateCancel">
      <div style="width: 100%;float: left">
        <el-form label-width="15%" style="width:100%;float:left;">


          <el-form-item style="">
            <template slot="label">
              农作物名称 <span style="color: red">*</span>
            </template>
            <el-input size="mini" style="width:85%;float: left;" v-model="updateForm.name"
                      oninput="if(value.length>35)value=value.slice(0,35)" />
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>


        </el-form>

      </div>
      <div class="dialog-footer">
        <el-button class="dialog-footer-submit" @click="updateSubmit">提交</el-button>
        <el-button class="dialog-footer-cancel" @click="updateCancel">取消</el-button>
      </div>
    </el-dialog>


  </div>
</template>

<script>

import { pageMerchantCropApi ,insertCropApi,deleteCropApi,updateCropApi} from "@/router/api/agriculture/merchant-crop-api";


export default {
  name: "MerchantCrop",
  data() {
    return {

      // 查询
      tableData: [],
      selectForm: {
        pageIndex: 1,
        pageSize: 10,
        total: 0
      },
      selectTableRow: [],

      //新增
      insertDialog: false,
      insertForm: {
        name: null,
      },


      //  详情
      detailDialog: false,
      detailInfo: null,

      //修改
      updateDialog: false,
      updateForm: {
        id: null,

        name: null,

      },

    };
  },

  mounted() {

    this.load();

  },

  methods: {

    load() {
      this.pageMerchantCrop();
    },


    // 查询 begin
    selectReset() {
      this.selectForm = {
        pageIndex: 1,
        pageSize: 10,
        total: 0
      };
      this.pageMerchantCrop(true);
    },

    handleCurrentChange(e) {
      this.selectForm.pageIndex = e;
      this.pageMerchantCrop(true);
    },

    handleSelectionChange(val) {
      this.selectTableRow = val;
    },

    pageMerchantCrop(showMessage) {
      pageMerchantCropApi(this.selectForm, res => {
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              if (showMessage) {
                this.showMessage(res.data.msg, "success");
              }
              this.tableData = res.data.resultSetData.list;
              this.selectForm.pageIndex = res.data.resultSetData.pageNum;
              this.selectForm.pageSize = res.data.resultSetData.pageSize;
              this.selectForm.total = res.data.resultSetData.total;
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });
    },


    // 查询 end


    // 新增 begin

    insertShow() {
      this.insertDialog = true;
    },

    insertCancel() {
      this.insertDialog = false;
      this.insertForm = {
        name: null,
      };

    },





    insertSubmit() {

      if (!this.insertForm.name) {
        this.showMessage("请填写农作物名称", "error");
        return false;
      }

      insertCropApi(this.insertForm, res => {
        //状态200
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.showMessage(res.data.msg, "success");
              this.insertCancel()
              this.pageMerchantCrop();

            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });
    },
    // 新增 end

    // 详情 begin
    detailShow(data) {
      this.detailInfo = data;
      this.detailDialog = true;
    },

    detailCancel() {
      this.detailDialog = false;
      this.detailInfo = null;
    },
    // 详情 end


    // 删除 begin
    deleteCrop(item) {
      if (!item) {
        this.showMessage("请选择需要删除的农作物", "warning");
        return false;
      }
      let params = [
        {
          "id": item.id
        }
      ];
      deleteCropApi(params, res => {
        //状态200
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.showMessage(res.data.msg, "success");
              this.pageMerchantCrop();
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });
    },

    deleteCropBatch() {
      if (!this.selectTableRow || this.selectTableRow.length == 0) {
        this.showMessage("请勾选需要需要删除的农作物", "warning");
        return false;
      }

      let params = [];
      for (let i = 0; i < this.selectTableRow.length; i++) {
        let row = this.selectTableRow[i];
        if (row) {
          params.push({
            "id": row.id
          });
        }
      }

      deleteCropApi(params, res => {
        //状态200
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.showMessage(res.data.msg, "success");
              this.pageMerchantCrop();
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });


    },

    // 删除 end

    // 修改 begin
    updateShow(data) {
      this.updateDialog = true;
      this.updateForm = {
        id: data.id,
        name: data.name,
      };
    },


    updateCancel() {
      this.updateDialog = false;
      this.updateForm = {
        id: null,
        name: null,
      };
    },

    updateSubmit() {

      //TODO 非空判断
      if (!this.updateForm.name) {
        this.showMessage("请填写农作物名称", "error");
        return false;
      }

      updateCropApi(this.updateForm, res => {
        //状态200
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.showMessage(res.data.msg, "success");
              this.updateCancel();
              this.pageMerchantCrop();
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });
    },

    // 修改 end

    // 工具
    parseDate(date) {
      if (date) {
        return new Date(date).toLocaleString();
      }
      return "";
    }
  }


}
;

</script>


<style scoped>

/deep/ .el-card__body, .el-main {
  float: left;
  width: 100%;
}

/*  通用 */
.dialog-footer {
  width: 100%;
  float: left;
  text-align: left;
}

.dialog-footer-submit {
  background-color: #25AD7F;
  color: white;
  width: 130px;
  border-radius: 50px;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 100;
}

.dialog-footer-submit:hover {
  background-color: #25AD7F;
  color: white;
  width: 130px;
  border-radius: 50px;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 100;
}

.dialog-footer-cancel {
  background-color: white;
  border: 1px solid #8080805c;
  color: #8080805c;
  width: 130px;
  border-radius: 50px;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 100;
}

.dialog-footer-cancel:hover {
  background-color: white;
  border: 1px solid #8080805c;
  color: #8080805c;
  width: 130px;
  border-radius: 50px;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 100;
}

/* 树形菜单 */

/deep/ .el-tree-node__content {
  height: 35px;
  line-height: 35px;
}

/* 表格 */

/deep/ .el-table th.el-table__cell {
  background-color: #F8F8F8;
}

/deep/ .el-table th.el-table__cell > .cell {
  color: #554 !important;
  font-weight: 600;
  font-size: 1.05rem;
}


/* 模态框 */

/deep/ .el-dialog__title {
  font-weight: 600;
}

/deep/ .el-dialog__header {
  text-align: left;
  border-bottom: 1px solid darkgray;
}

/deep/ .el-dialog__body {
  width: 100%;
  float: left;
  background-color: white;
}

/deep/ .el-upload-dragger {
  height: auto;
}

/deep/ .el-form-item__content {
  text-align: left;
}


/* 纯数字 */
/deep/ .numberInput input::-webkit-outer-spin-button,
/deep/ .numberInput input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

/deep/ .numberInput input[type="number"] {
  -moz-appearance: textfield;
}

</style>
