import Vue from "vue";

export function listMerchantExpertApi(params, success) {
  let url = "/admin/web/expert/listMerchantExpert";
  Vue.prototype.alexPost(url, params, success);
}

export function deleteExpertApi(params, success) {
  let url = "/admin/web/expert/deleteExpert";
  Vue.prototype.alexPost(url, params, success);
}


