import Vue from "vue";


export function pageAllSysOpenApi(params, success) {
  let url = "/admin/web/sysOpen/pageAllSysOpen";
  Vue.prototype.alexPost(url, params, success);
}

export function updateIsOpenApi(params, success) {
  let url = "/admin/web/sysOpen/updateIsOpen";
  Vue.prototype.alexPost(url, params, success);
}


