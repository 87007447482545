import Vue from "vue";


export function pageMerchantWorkflowApi(params, success) {
  let url = "/admin/web/workflow/pageMerchantWorkflow";
  Vue.prototype.alexPost(url, params, success);
}

export function insertWorkflowApi(params, success) {
  let url = "/admin/web/workflow/insertWorkflow";
  Vue.prototype.alexPost(url, params, success);
}

export function updateWorkflowApi(params, success) {
  let url = "/admin/web/workflow/updateWorkflow";
  Vue.prototype.alexPost(url, params, success);
}



export function getWorkflowApi(params, success) {
  let url = "/admin/web/workflow/getWorkflow";
  Vue.prototype.alexPost(url, params, success);
}



export function insertWorkflowDetailApi(params, success) {
  let url = "/admin/web/workflow/insertWorkflowDetail";
  Vue.prototype.alexPost(url, params, success);
}

export function deleteWorkflowDetailApi(params, success) {
  let url = "/admin/web/workflow/deleteWorkflowDetail";
  Vue.prototype.alexPost(url, params, success);
}

export function updateWorkflowDetailApi(params, success) {
  let url = "/admin/web/workflow/updateWorkflowDetail";
  Vue.prototype.alexPost(url, params, success);
}

export function sendMessageApi(params, success) {
  let url = "/admin/web/workflow/sendMessage";
  Vue.prototype.alexPost(url, params, success);
}




