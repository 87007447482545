<script>
//静态全局变量
const test = {
  key: "123456",
  value:"abc"
};

export default {
  name: "GlobalConstant",
  test
};
</script>
