import Vue from "vue";

export function pageApproveApi(params, success) {
  let url = "/admin/web/approve/pageApprove";
  Vue.prototype.alexPost(url, params, success);
}

export function auditApi(params, success) {
  let url = "/admin/web/approve/audit";
  Vue.prototype.alexPost(url, params, success);
}


