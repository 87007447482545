import Vue from "vue";

export function loginUserApi(params, success) {
  let url = "/admin/web/user/loginUser";
  Vue.prototype.alexPost(url, params, success);
}

export function logoutApi(params, success) {
  let url =  "/admin/web/user/logout" ;
  Vue.prototype.alexPost(url, params, success);
}

export function pageUserApi(params, success) {
  let url =  "/admin/web/user/pageUser" ;
  Vue.prototype.alexPost(url, params, success);
}

