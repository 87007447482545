<template>
  <div class="full-container">
    <div
      style="width: 100%;float: left;height: 50px;line-height: 50px;font-size: 0.9rem; text-align: left;opacity: 0.9;">
      专家库
    </div>
    <div style="width:100%;height:auto;float:left;border-radius: 15px;background-color: white;padding-bottom:40px;">

      <div style="width:100%;float:left;margin-top: 10px;">
        <!-- 查询按钮区 -->
        <div style="width: 100%;height: 60px;float: left;line-height: 60px;padding-left: 15px">
          <!-- 右边按钮 -->
          <div style="width: auto;float: right;margin-right: 20px">
            <el-button size="mini" style="background-color: #25AD7F;border:none;" type="primary" @click="insertShow()">
              <a-icon type="plus-circle" />
              新增专家
            </el-button>
          </div>
        </div>

        <!-- 表单内容 -->
        <div style="width:100%;float:left;padding: 15px;">
          <div v-if="expertList&&expertList.length>0" v-for="item in expertList"
               style="width: 30%;margin-left: 2.5%;float: left;margin-bottom: 30px;">
            <el-card class="box-card" style="width: 100%;float: left;">
              <!-- 标题 -->
              <div slot="header" class="clearfix" v-if="item">
                <div style="float:left;width: 40%;text-align: left;">
                  {{ item.name }}
                </div>
                <div style="float:left;width: 60%;text-align: right;">
                  <a-popconfirm title="是否删除?(敏感操作!)" ok-text="确定" cancel-text="取消"
                                @confirm="deleteExpert(item)">
                    <el-button style="float: right; padding: 3px 0;margin-left:10px;color:#e32e2e;" type="text">删除
                    </el-button>
                  </a-popconfirm>

                  <el-button @click="updateShow(item)" style="float: right; padding: 3px 0;color:#409EFF;" type="text">
                    修改
                  </el-button>
                  <el-button @click="detailShow(item)" style="float: right; padding: 3px 0;color:#25AD7F" type="text">
                    详情
                  </el-button>
                </div>
              </div>
              <!-- 内容 -->
              <div style="width: 100%;float: left;border-bottom:1px solid #EBEEF5">
                <!-- 照片 -->
                <div style="width: 100px;float: left;text-align: left;">
                  <el-image v-if="item.mainImageGuid" title="点击放大"
                            style="width: 100px; height: 100px"
                            :src="'/admin/web/file/downloadFile?id='+item.mainImageGuid"
                            :preview-src-list="['/admin/web/file/downloadFile?id='+item.mainImageGuid]">
                  </el-image>
                </div>
                <!-- 文本 -->
                <div style="width: calc(100% - 100px);float: left;text-align: left;padding-left:10px;">
                  <div style="width: 100%;float: left;font-size:0.8rem;margin-bottom: 15px">
                    <div style="width: 20%;float: left;opacity: 0.8;">
                      年限：
                    </div>
                    <div class="text-ellipsis" style="width: 80%;float: left;fonts-size:0.8rem;word-wrap: break-word">
                      {{ item.year ? item.year : "0" }}年
                    </div>
                  </div>
                  <div style="width: 100%;float: left;font-size:0.8rem;margin-bottom: 15px">
                    <div style="width: 20%;float: left;opacity: 0.8;">
                      头衔：
                    </div>
                    <div class="text-ellipsis" style="width: 80%;float: left;fonts-size:0.8rem;word-wrap: break-word">
                      {{ item.level ? item.level : "0" }}
                    </div>
                  </div>
                  <div style="width: 100%;float: left;font-size:0.8rem;margin-bottom: 15px">
                    <div style="width: 20%;float: left;opacity: 0.8;">
                      技能：
                    </div>
                    <div class="text-ellipsis" style="width: 80%;float: left;fonts-size:0.8rem;word-wrap: break-word">
                      {{ item.skill ? item.skill : "0" }}
                    </div>
                  </div>
                </div>
              </div>
              <!-- 底部按钮 -->
              <div style="width: 100%;float: left;padding-top: 18px;">
                <el-button @click="clickPrivateMessage(item)"
                           style="float: right; padding: 3px 0;margin-left: 10px;color:#25AD7F" type="text">私信咨询
                </el-button>
                <el-button style="float: right; padding: 3px 0;color:gray;cursor: not-allowed;" type="text">发帖
                </el-button>
              </div>
            </el-card>
          </div>
        </div>
        <div style="width: 100%;float:left;" v-show="!(expertList&&expertList.length>0)">
          <el-empty description="还没有添加专家">
            <el-button style="background-color: #25AD7F;border:none;" type="primary" @click="insertShow()">
              添加专家
            </el-button>
          </el-empty>
        </div>
      </div>
    </div>

    <el-dialog title="新增专家" :visible.sync="insertDialog" width="40%" :before-close="insertCancel">
      <div style="width: 100%;float: left">
        <el-form label-width="15%" style="width:100%;float:left;">
          <el-form-item style="">
            <template slot="label">
              专家名称 <span style="color: red">*</span>
            </template>
            <el-input size="mini" style="width:85%;float: left;" v-model="insertForm.name"
                      oninput="if(value.length>35)value=value.slice(0,35)" />
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>

          <el-form-item style="">
            <template slot="label">
              工作人员
            </template>
            <el-select size="mini" style="float: left;" v-model="insertForm.userId" clearable filterable>
              <el-option v-for="item in merchantUserList" :key="item.id" :label="item.name + '-'+item.no"
                         :value="item.id">
              </el-option>
            </el-select>
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>


          <el-form-item>
            <template slot="label">
              年限 <span style="color: red">*</span>
            </template>
            <el-input size="mini" style="width:85%;float: left;" type="number" class="numberInput"
                      v-model="insertForm.year"
                      oninput="if(value.length>10)value=value.slice(0,10)" />
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>

          <el-form-item label="">
            <template slot="label">
              资历头衔
            </template>
            <el-input size="mini" style="width:85%;float: left;" v-model="insertForm.level"
                      oninput="if(value.length>35)value=value.slice(0,35)" />
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>

          <el-form-item label="">
            <template slot="label">
              擅长技能
            </template>
            <el-input size="mini" style="width:85%;float: left;" v-model="insertForm.skill"
                      oninput="if(value.length>250)value=value.slice(0,250)" />
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>

          <el-form-item label="">
            <template slot="label">
              专家介绍
            </template>
            <el-input size="mini" style="width:85%;float: left;" v-model="insertForm.context"
                      oninput="if(value.length>500)value=value.slice(0,500)" />
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>
          <el-form-item label="">
            <template slot="label">
              头像 <span style="color: red">*</span>
            </template>
            <el-upload action="#" list-type="picture-card" :on-change="changeCompanyIdCard"
                       :show-file-list="false" :multiple="false" :auto-upload="false">
              <el-image fit="contain" v-if="mainImageFile" :src="mainImageFile.url" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>
          <el-form-item label="">
            <template slot="label">
              二维码
            </template>
            <el-upload action="#" list-type="picture-card" :on-change="changeQrcodeCompanyIdCard"
                       :show-file-list="false" :multiple="false" :auto-upload="false">
              <el-image fit="contain" v-if="qrcodeImageFile" :src="qrcodeImageFile.url" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>

          <el-form-item label="">
            <template slot="label">
              详细图片<span style="color: red">*</span>
            </template>
            <el-upload :limit=9 action="#" list-type="picture-card" :multiple="true" :on-change="changeChargeUserIdCard"
                       :file-list="detailImageFileList" :auto-upload="false">
              <i slot="default" class="el-icon-plus"></i>
              <div slot="file" slot-scope="{file}">
                <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
                <span class="el-upload-list__item-actions">
                  <span class="el-upload-list__item-preview" @click="detailImageShowDialog(file)">
                    <i class="el-icon-zoom-in"></i>
                  </span>
                  <span class="el-upload-list__item-delete" @click="removeChargeUserIdCard(file)">
                    <i class="el-icon-delete"></i>
                  </span>
                </span>
              </div>
            </el-upload>
            <!-- 大图显示 -->
            <el-dialog :visible.sync="detailImageShow">
              <img width="100%" :src="detailImageShowUrl" alt="">
            </el-dialog>
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>
        </el-form>

      </div>
      <div class="dialog-footer">
        <el-button class="dialog-footer-submit" @click="insertSubmit">提交</el-button>
        <el-button class="dialog-footer-cancel" @click="insertCancel">取消</el-button>
      </div>
    </el-dialog>

    <!-- 详情 -->
    <el-dialog title="专家详情" :visible.sync="detailDialog" width="40%" :before-close="detailCancel">
      <div style="width: 100%;float: left">
        <div
          style="width: 100%;float: left;border-bottom: 1px solid #80808054;padding-bottom: 15px;margin-bottom: 20px;"
          v-if="detailInfo">
          <el-descriptions :title="detailInfo.name?detailInfo.name:''" :column=2>
            <el-descriptions-item label="年限">{{ detailInfo.year ? detailInfo.year : "0" }}年
            </el-descriptions-item>
            <el-descriptions-item label="资历头衔">{{ detailInfo.level ? detailInfo.level : "" }}
            </el-descriptions-item>
            <el-descriptions-item label="擅长技能">{{ detailInfo.skill ? detailInfo.skill : "" }}
            </el-descriptions-item>
            <el-descriptions-item label="专家介绍">{{ detailInfo.context ? detailInfo.context : "" }}
            </el-descriptions-item>
            <el-descriptions-item label="创建时间" :span="2">
              {{ parseDate(detailInfo.createDate ? detailInfo.createDate : "")
              }}
            </el-descriptions-item>
            <el-descriptions-item label="头像" :span="2">
              <el-image v-if="detailInfo.mainImageGuid" title="点击放大"
                        style="width: 100px; height: 100px"
                        :src="'/admin/web/file/downloadFile?id='+detailInfo.mainImageGuid"
                        :preview-src-list="['/admin/web/file/downloadFile?id='+detailInfo.mainImageGuid]">
              </el-image>
            </el-descriptions-item>
            <el-descriptions-item label="详细照片" :span="2">
              <div style="width: 100%;float: left;" v-if="detailInfo.detailImageGuid"
                   v-for="guid in detailInfo.detailImageGuid">
                <el-image title="点击放大"
                          style="width: 100%; height: 100px"
                          :src="'/admin/web/file/downloadFile?id='+guid"
                          :preview-src-list="['/admin/web/file/downloadFile?id='+guid]">
                </el-image>
                <br />
              </div>

            </el-descriptions-item>
          </el-descriptions>
        </div>

      </div>
      <div class="dialog-footer">
        <el-button class="dialog-footer-cancel" @click="detailCancel">关闭</el-button>
      </div>
    </el-dialog>

    <!-- 修改 -->
    <el-dialog title="修改专家" :visible.sync="updateDialog" width="40%" :before-close="updateCancel">
      <div style="width: 100%;float: left">
        <el-form label-width="15%" style="width:100%;float:left;">
          <el-form-item style="">
            <template slot="label">
              专家名称 <span style="color: red">*</span>
            </template>
            <el-input size="mini" style="width:85%;float: left;" v-model="updateForm.name"
                      oninput="if(value.length>35)value=value.slice(0,35)" />
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>

          <el-form-item style="">
            <template slot="label">
              工作人员
            </template>
            <el-select size="mini" style="float: left;" v-model="updateForm.userId" clearable filterable>
              <el-option v-for="item in merchantUserList" :key="item.id" :label="item.name + '-'+item.no"
                         :value="item.id">
              </el-option>
            </el-select>
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>


          <el-form-item>
            <template slot="label">
              年限 <span style="color: red">*</span>
            </template>
            <el-input size="mini" style="width:85%;float: left;" type="number" class="numberInput"
                      v-model="updateForm.year"
                      oninput="if(value.length>10)value=value.slice(0,10)" />
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>

          <el-form-item label="">
            <template slot="label">
              资历头衔
            </template>
            <el-input size="mini" style="width:85%;float: left;" v-model="updateForm.level"
                      oninput="if(value.length>35)value=value.slice(0,35)" />
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>

          <el-form-item label="">
            <template slot="label">
              擅长技能
            </template>
            <el-input size="mini" style="width:85%;float: left;" v-model="updateForm.skill"
                      oninput="if(value.length>250)value=value.slice(0,250)" />
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>

          <el-form-item label="">
            <template slot="label">
              专家介绍
            </template>
            <el-input size="mini" style="width:85%;float: left;" v-model="updateForm.context"
                      oninput="if(value.length>500)value=value.slice(0,500)" />
            <div style="width: 15%;float: left;text-indent: 5px;">ant</div>
          </el-form-item>
          <el-form-item label="">
            <template slot="label">
              头像 <span style="color: red">*</span>
            </template>
            <el-upload action="#" list-type="picture-card" :on-change="updateChangeCompanyIdCard"
                       :show-file-list="false" :multiple="false" :auto-upload="false">
              <el-image ref="mainref" fit="contain" v-if="updateMainImageFileUrl" :src="updateMainImageFileUrl"
                        class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>
          <el-form-item label="">
            <template slot="label">
              二维码
            </template>
            <el-upload action="#" list-type="picture-card" :on-change="updateChangeQrcodeCompanyIdCard"
                       :show-file-list="false" :multiple="false" :auto-upload="false">
              <el-image ref="mainref" fit="contain" v-if="updateQrcodeImageFileUrl" :src="updateQrcodeImageFileUrl"
                        class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>
          <el-form-item label="">
            <template slot="label">
              详细图片<span style="color: red">*</span>
            </template>
            <el-upload :limit=9 action="#" list-type="picture-card" :multiple="true"
                       :on-change="updateChangeChargeUserIdCard"
                       :file-list="updateDetailImageFileListUrl" :auto-upload="false">
              <i slot="default" class="el-icon-plus"></i>
              <div slot="file" slot-scope="{file}">
                <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
                <span class="el-upload-list__item-actions">
                  <span class="el-upload-list__item-preview" @click="updateDetailImageShowDialog(file)">
                    <i class="el-icon-zoom-in"></i>
                  </span>
                  <span class="el-upload-list__item-delete" @click="updateRemoveChargeUserIdCard(file)">
                    <i class="el-icon-delete"></i>
                  </span>
                </span>
              </div>
            </el-upload>
            <!-- 大图显示 -->
            <el-dialog :visible.sync="updateDetailImageShow">
              <img width="100%" :src="updateDetailImageShowUrl" alt="">
            </el-dialog>
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>
        </el-form>

      </div>
      <div class="dialog-footer">
        <el-button class="dialog-footer-submit" @click="updateSubmit">提交</el-button>
        <el-button class="dialog-footer-cancel" @click="updateCancel">取消</el-button>
      </div>
    </el-dialog>

    <!-- 私信 -->
    <el-drawer :title="(privateMessageData?privateMessageData.name+'：私信咨询':'：私信咨询' )"
               :visible.sync="privateMessageShow" direction="rtl">
      <el-empty description="暂无私信"></el-empty>
    </el-drawer>


  </div>
</template>

<script>

import { deleteExpertApi, listMerchantExpertApi } from "@/router/api/expert/expert-api";
import { getMyMerchantUserApi } from "@/router/api/merchant/merchant-api";

export default {
  name: "MerchantExpert",
  data() {
    return {

      merchantUserList: [],

      expertList: [],

      //新增
      insertDialog: false,
      insertForm: {
        userId: null,
        name: null,
        year: null,
        level: null,
        skill: null,
        context: null
      },
      mainImageFile: null,
      qrcodeImageFile: null,
      detailImageFileList: [],
      detailImageShow: false,//是否显示大图
      detailImageShowUrl: "",//大图的url

      //  详情
      detailDialog: false,
      detailInfo: null,

      //私信
      privateMessageShow: false,
      privateMessageData: false,

      //修改
      updateDialog: false,
      updateForm: {
        id: null,
        name: null,
        userId: null,
        year: null,
        level: null,
        skill: null,
        context: null
      },


      updateMainImageFile: null,
      updateMainImageFileUrl: null,

      updateQrcodeImageFile: null,
      updateQrcodeImageFileUrl: null,

      updateDetailImageFileListUrl: [],
      updateDetailImageShow: false,//是否显示大图
      updateDetailImageShowUrl: ""//大图的url


    };
  },

  mounted() {

    this.load();


  },

  methods: {

    load() {
      this.listMerchantExpert();


      getMyMerchantUserApi({}, res => {
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.merchantUserList = res.data.resultSetData;
              console.info(this.expertList);
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });

    },


    // 查询 begin
    listMerchantExpert(showMessage) {
      listMerchantExpertApi({}, res => {
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              if (showMessage) {
                this.showMessage(res.data.msg, "success");
              }
              this.expertList = res.data.resultSetData;
              console.info(this.expertList);
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });
    },
    // 查询 end


    // 新增 begin

    insertShow() {
      this.insertDialog = true;
    },

    insertCancel() {
      this.insertDialog = false;
      this.insertForm = {
        userId: null,
        name: null,
        year: null,
        level: null,
        skill: null,
        context: null
      };
      this.mainImageFile = null;
      this.qrcodeImageFile = null;
      this.detailImageFileList = [];
      this.detailImageShow = false;
      this.detailImageShowUrl = "";
    },


    // 营业执照上传
    changeCompanyIdCard(file) {
      this.mainImageFile = file;
    },

    changeQrcodeCompanyIdCard(file) {
      debugger;
      this.qrcodeImageFile = file;
    },

    //身份证上传
    changeChargeUserIdCard(file, fileList) {
      this.detailImageFileList = fileList;
    },

    removeChargeUserIdCard(file) {
      for (let item in this.detailImageFileList) {
        if (item && this.detailImageFileList[item] && file && this.detailImageFileList[item].uid == file.uid) {
          this.detailImageFileList.splice(item, 1);
        }
      }
    },

    detailImageShowDialog(e) {
      this.detailImageShowUrl = e.url;
      this.detailImageShow = true;
    },


    insertSubmit() {

      //TODO 非空判断
      if (!this.insertForm.name) {
        this.showMessage("请填写专家姓名", "error");
        return false;
      }

      if (!this.insertForm.year) {
        this.showMessage("请填写年限", "error");
        return false;
      }


      //数据组装
      let params = new FormData();
      if (this.insertForm.userId) {
        params.append("userId", this.insertForm.userId);
      }
      params.append("name", this.insertForm.name);
      params.append("year", this.insertForm.year);
      params.append("level", this.insertForm.level);
      params.append("skill", this.insertForm.skill);
      params.append("context", this.insertForm.context);
      debugger;
      if (this.mainImageFile) {
        params.append("mainImageMultipartFile", this.mainImageFile.raw);
      } else {
        this.showMessage("请上传头像", "error");
        return false;
      }

      if (this.qrcodeImageFile) {
        params.append("qrcodeImageMultipartFile", this.qrcodeImageFile.raw);
      }

      if (this.detailImageFileList && this.detailImageFileList.length > 0) {
        for (let i = 0; i < this.detailImageFileList.length; i++) {
          let temp = this.detailImageFileList[i].raw;
          params.append("detailImageMultipartFile[]", temp);
        }
      } else {
        this.showMessage("请上传详细照片", "error");
        return false;
      }

      let url = "/admin/web/expert/insertExpert";
      this.alexPost(url, params, res => {
        //状态200
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.showMessage(res.data.msg, "success");
              this.insertCancel();
              this.listMerchantExpert();
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      }, {
        headers: {
          "Content-Type": "multipart/form-data",
          "token": localStorage.getItem("token")
        }
      });
    },
    // 新增 end

    // 详情 begin
    detailShow(data) {
      this.detailInfo = data;
      this.detailDialog = true;
    },

    detailCancel() {
      this.detailDialog = false;
      this.detailInfo = null;
    },
    // 详情 end

    // 私信 begin
    clickPrivateMessage(item) {
      this.privateMessageShow = true;
      this.privateMessageData = item;
    },
    // 私信 end


    //删除 begin
    deleteExpert(item) {
      if (!item) {
        this.showMessage("请选择需要删除的专家", "warning");
        return false;
      }
      let params = {
        "id": item.id
      };
      deleteExpertApi(params, res => {
        //状态200
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.showMessage(res.data.msg, "success");
              this.listMerchantExpert();
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });
    },
    //删除 end

    // 修改 begin

    updateShow(data) {
      this.updateDialog = true;
      this.updateForm = {
        id: data.id,
        userId: data.userId,
        name: data.name,
        year: data.year,
        level: data.level,
        skill: data.skill,
        context: data.context
      };


      this.updateMainImageFileUrl = "/admin/web/file/downloadFile?id=" + data.mainImageGuid;//缩略图返显 上传
      if (data.qrcodeImageGuid) {
        this.updateQrcodeImageFileUrl = "/admin/web/file/downloadFile?id=" + data.qrcodeImageGuid;//缩略图返显 上传
      }
      this.updateDetailImageFileListUrl = [];//详情页 图片数组
      if (data.detailImageGuid && data.detailImageGuid.length > 0) {
        for (let i = 0; i < data.detailImageGuid.length; i++) {
          let guid = data.detailImageGuid[i];
          this.updateDetailImageFileListUrl.push({
            name: guid,
            url: "/admin/web/file/downloadFile?id=" + guid
          });
        }
      }
    },


    updateCancel() {
      this.updateDialog = false;
      this.updateForm = {
        id: null,
        name: null,
        year: null,
        level: null,
        skill: null,
        context: null
      };
      this.updateMainImageFile = null;
      this.updateMainImageFileUrl = null; //返现

      this.updateQrcodeImageFile = null;
      this.updateQrcodeImageFileUrl = null; //返现

      this.updateDetailImageShow = false;
      this.updateDetailImageShowUrl = "";
      this.updateDetailImageFileListUrl = [];
    },


    // 营业执照上传
    updateChangeCompanyIdCard(file) {
      this.updateMainImageFile = file;
      this.updateMainImageFileUrl = file.url;
    },

    // 营业执照上传
    updateChangeQrcodeCompanyIdCard(file) {
      this.updateQrcodeImageFile = file;
      this.updateQrcodeImageFileUrl = file.url;
    },

    //身份证上传
    updateChangeChargeUserIdCard(file, fileList) {
      this.updateDetailImageFileListUrl = fileList;
    },

    updateRemoveChargeUserIdCard(file) {
      for (let item in this.updateDetailImageFileListUrl) {
        if (item && this.updateDetailImageFileListUrl[item] && file && this.updateDetailImageFileListUrl[item].uid == file.uid) {
          this.updateDetailImageFileListUrl.splice(item, 1);
        }
      }
    },

    updateDetailImageShowDialog(e) {
      this.updateDetailImageShowUrl = e.url;
      this.updateDetailImageShow = true;
    },


    updateSubmit() {

      //TODO 非空判断
      if (!this.updateForm.name) {
        this.showMessage("请填写专家姓名", "error");
        return false;
      }

      if (!this.updateForm.year) {
        this.showMessage("请填写年限", "error");
        return false;
      }


      //数据组装
      let params = new FormData();
      params.append("id", this.updateForm.id);
      if (this.updateForm.userId) {
        params.append("userId", this.updateForm.userId);
      }
      params.append("name", this.updateForm.name);
      params.append("year", this.updateForm.year);
      params.append("level", this.updateForm.level);
      params.append("skill", this.updateForm.skill);
      params.append("context", this.updateForm.context);

      if (this.updateMainImageFile) {
        params.append("mainImageMultipartFile", this.updateMainImageFile.raw);
      }
      if (this.updateQrcodeImageFile) {
        params.append("qrcodeImageMultipartFile", this.updateQrcodeImageFile.raw);
      }
      if (this.updateDetailImageFileListUrl && this.updateDetailImageFileListUrl.length > 0) {
        for (let i = 0; i < this.updateDetailImageFileListUrl.length; i++) {
          let temp = this.updateDetailImageFileListUrl[i];
          if (temp) {
            if (temp.status == "success") {
              params.append("detailImageListFileGuid[]", temp.name);
            }
            if (temp.status == "ready") {
              params.append("detailImageMultipartFile[]", temp.raw);
            }
          }
        }
      }

      let url = "/admin/web/expert/updateExpert";
      this.alexPost(url, params, res => {
        //状态200
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.showMessage(res.data.msg, "success");
              this.updateCancel();
              this.listMerchantExpert();
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      }, {
        headers: {
          "Content-Type": "multipart/form-data",
          "token": localStorage.getItem("token")
        }
      });
    },

    // 修改 end

    // 工具
    parseDate(date) {
      if (date) {
        return new Date(date).toLocaleString();
      }
      return "";
    }
  }


}
;

</script>


<style scoped>

/deep/ .el-card__body, .el-main {
  float: left;
  width: 100%;
}

/*  通用 */
.dialog-footer {
  width: 100%;
  float: left;
  text-align: left;
}

.dialog-footer-submit {
  background-color: #25AD7F;
  color: white;
  width: 130px;
  border-radius: 50px;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 100;
}

.dialog-footer-submit:hover {
  background-color: #25AD7F;
  color: white;
  width: 130px;
  border-radius: 50px;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 100;
}

.dialog-footer-cancel {
  background-color: white;
  border: 1px solid #8080805c;
  color: #8080805c;
  width: 130px;
  border-radius: 50px;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 100;
}

.dialog-footer-cancel:hover {
  background-color: white;
  border: 1px solid #8080805c;
  color: #8080805c;
  width: 130px;
  border-radius: 50px;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 100;
}

/* 树形菜单 */

/deep/ .el-tree-node__content {
  height: 35px;
  line-height: 35px;
}

/* 表格 */

/deep/ .el-table th.el-table__cell {
  background-color: #F8F8F8;
}

/deep/ .el-table th.el-table__cell > .cell {
  color: #554 !important;
  font-weight: 600;
  font-size: 1.05rem;
}


/* 模态框 */

/deep/ .el-dialog__title {
  font-weight: 600;
}

/deep/ .el-dialog__header {
  text-align: left;
  border-bottom: 1px solid darkgray;
}

/deep/ .el-dialog__body {
  width: 100%;
  float: left;
  background-color: white;
}

/deep/ .el-upload-dragger {
  height: auto;
}

/deep/ .el-form-item__content {
  text-align: left;
}


/* 纯数字 */
/deep/ .numberInput input::-webkit-outer-spin-button,
/deep/ .numberInput input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

/deep/ .numberInput input[type="number"] {
  -moz-appearance: textfield;
}


.text-ellipsis {
  white-space: nowrap;
  /* 确保文本在一行内显示 */
  overflow: hidden;
  /* 超出部分隐藏 */
  text-overflow: ellipsis;
  /* 使用省略号表示超出的文本 */
  width: 100%;
  /* 让div充满容器的宽度 */
}
</style>
