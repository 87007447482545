import Vue from "vue";

export function listMerchantRoleApi(params, success) {
    let url = "/admin/web/authRole/listMerchantRole";
    Vue.prototype.alexPost(url, params, success);
}

export function listMyRoleApi(params, success) {
    let url = "/admin/web/authRole/listMyRole";
    Vue.prototype.alexPost(url, params, success);
}

export function pageAuthRoleApi(params, success) {
    let url = "/admin/web/authRole/pageAuthRole";
    Vue.prototype.alexPost(url, params, success);
}

