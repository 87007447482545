<template>
  <div class="home-container">
    <div style="width:20%;float: left;height: 100%;border-right:1px solid #c5abab;">
      <div style="width:100%;height: 70px;float: left;background-color: white;text-align: left;">
        <el-image style="width:auto;height: 50px;margin: 10px 10px;" :src="require('@/assets/home/logo.png')" />
      </div>
      <navMenu style="width:100%;height: calc(100% - 70px);float: left;overflow-y: auto;"></navMenu>
    </div>
    <div style="height: 100%;width: 80%;float: left">
      <navHead style="width:100%;height: 70px;float: left;"></navHead>
      <router-view  style="width:100%;height: calc(100% - 70px);float: left;background-color: #F1F3F3;overflow-y:auto;padding: 0px 20px 20px 20px;" />
    </div>
  </div>
</template>

<script>
import navMenu from "@/components/nav/NavMenu";
import navHead from "@/components/nav/NavHead";

import { listMyRoleApi } from "@/router/api/auth/role-api";

export default {
  name: "home",
  components: { navMenu, navHead },
  data() {
    return {
      token: localStorage.getItem("token"),
      loginUser: JSON.parse(localStorage.getItem("user"))
    };
  },

  created() {


    //首页跳转
    // this.$router.push("/index");
  },

  mounted() {
    this.load();
  },

  methods: {

    load() {
      this.listMyRole();
    },

    listMyRole(showMessage) {
      let params = {};
      listMyRoleApi(params, res => {
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              if (showMessage) {
                this.showMessage(res.data.msg, "success");
              }
              let myRoleList = res.data.resultSetData;
              let indexUrl = "/emptyIndex";
              // TODO hard code
              if (myRoleList && myRoleList.length > 0) {
                let isAdmin = false;
                for (let i = 0; i < myRoleList.length; i++) {
                  let role = myRoleList[i];
                  if (role) {
                    if (role.roleType == "admim" || role.roleType == "super_admin") {
                      indexUrl = "/index";
                      isAdmin = true;
                    }
                  }
                }
                if (!isAdmin) {
                  for (let i = 0; i < myRoleList.length; i++) {
                    let role = myRoleList[i];
                    if (role) {
                      if (role.roleType == "merchant") {
                        indexUrl = "/merchantCharts";
                      }
                    }
                  }
                }
              }
              this.$router.push(indexUrl);
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });
    }
  }
};
</script>

<style scoped>
.home-container {
  width: 100%;
  height: 100%;
}


</style>
