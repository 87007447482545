<template>
  <div class="full-container" >
    <el-menu active-text-color="#25AD7F" text-color="#4C5042" style="text-align: left;text-indent: 15px;">
      <template v-for="item in authMenu">
        <el-menu-item v-if="!item.childrenMenu" :index="item.id" @click="menuClick(item.id, item.url)">
          <a-icon style="margin-right: 10px;font-size: 1.2rem" :type="item.icon" />
          <span style="font-weight: 600;font-size: 1.1rem;letter-spacing: 3px;">{{ item.name }}</span>
        </el-menu-item>
        <el-submenu v-if="item.childrenMenu" :index="item.id">
          <template slot="title">
            <a-icon style="margin-right: 10px;font-size: 1.2rem" :type="item.icon" />
            <span style="font-weight: 600;font-size: 1.1rem;letter-spacing: 3px">{{ item.name }}</span>
          </template>
          <el-menu-item style="text-indent: 35px" v-if="item.childrenMenu" v-for="children in item.childrenMenu"
                        :index="children.id"
                        @click="menuClick(children.id, children.url)">
            <span slot="title" style="letter-spacing: 3px;font-size: 1.1rem;">{{ children.name }}</span>
          </el-menu-item>
        </el-submenu>
      </template>
    </el-menu>

  </div>
</template>
<script>

import { getUserAuthMenuApi } from "@/router/api/auth/menu-api";
import { getMyMerchantApi } from "@/router/api/merchant/merchant-api";

export default {
  name: "NavMenu",
  data() {
    return {
      currentMenu: 1,

      authMenu: [],
      myMerchantInfo: {},

      loginUser: JSON.parse(localStorage.getItem("user"))
    };
  },

  mounted() {
    this.getMyMerchant();
  },

  methods: {
    getUserAuthMenu() {
      let params = {};
      getUserAuthMenuApi(params, res => {
        //状态200
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.authMenu = [];
                let treeMenu = res.data.resultSetData;
              if(treeMenu&&treeMenu.length>0){
                for(let i = 0 ;i<treeMenu.length;i++){
                  let menu = treeMenu[i]
                  if(menu){
                    if(this.myMerchantInfo.isWorkflow==0&&menu.name.indexOf("服务")!==-1){
                      continue
                    }else{
                      this.authMenu.push(menu);
                    }
                  }
                }
              }
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });
    },

    getMyMerchant(showMessage) {
      let params = {};
      getMyMerchantApi(params, res => {
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              if (showMessage) {
                this.showMessage(res.data.msg, "success");
              }
              this.myMerchantInfo = res.data.resultSetData;
              this.getUserAuthMenu();
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });
    },

    menuClick(menuId, path) {
      if (menuId != this.currentMenu) {
        this.currentMenu = menuId;
        this.$router.push(path);
      } else if (location.pathname != path) {
        this.$router.push(path);
      }
    }
  }
};
</script>

<style scoped>

/deep/ .el-submenu__title:hover {
  background-color: #E1F0EC;
}

/deep/ .el-submenu__title:focus, .el-submenu__title:hover {
  background-color: #E1F0EC;
}

/deep/ .el-menu-item:focus, .el-menu-item:hover {
  background-color: #E1F0EC;
}


/*/deep/ .el-submenu__title i{*/
/*  color: #E1F0EC;*/
/*  font-weight: 600;*/
/*}*/
</style>
