import Vue from "vue";

export function insertProjectApi(params, success) {
  let url = "/admin/web/project/insertProject";
  Vue.prototype.alexPost(url, params, success);
}

export function updateProjectApi(params, success) {
  let url = "/admin/web/project/updateProject";
  Vue.prototype.alexPost(url, params, success);
}

export function deleteProjectApi(params, success) {
  let url = "/admin/web/project/deleteProject";
  Vue.prototype.alexPost(url, params, success);
}

export function pageMerchantProjectApi(params, success) {
  let url = "/admin/web/project/pageMerchantProject";
  Vue.prototype.alexPost(url, params, success);
}

export function listAllProjectApi(params, success) {
  let url = "/admin/web/project/listAllProject";
  Vue.prototype.alexPost(url, params, success);
}




