import Vue from "vue";

export function pageMerchantApi(params, success) {
  let url = "/admin/web/merchant/pageMerchant";
  Vue.prototype.alexPost(url, params, success);
}

export function getMyMerchantApi(params, success) {
  let url = "/admin/web/merchant/getMyMerchant";
  Vue.prototype.alexPost(url, params, success);
}

export function updateOrInsertMyMerchantPublicAccountApi(params, success) {
  let url = "/admin/web/merchant/updateOrInsertMyMerchantPublicAccount";
  Vue.prototype.alexPost(url, params, success);
}

export function pageMyMerchantUserApi(params, success) {
  let url = "/admin/web/merchant/pageMyMerchantUser";
  Vue.prototype.alexPost(url, params, success);
}

export function registerMerchantUserApi(params, success) {
  let url = "/admin/web/merchant/registerMerchantUser";
  Vue.prototype.alexPost(url, params, success);
}

export function deleteMerchantUserApi(params, success) {
  let url = "/admin/web/merchant/deleteMerchantUser";
  Vue.prototype.alexPost(url, params, success);
}


export function updateMerchantUserApi(params, success) {
  let url = "/admin/web/merchant/updateMerchantUser";
  Vue.prototype.alexPost(url, params, success);
}




export function getMyMerchantUserApi(params, success) {
  let url = "/admin/web/merchant/getMyMerchantUser";
  Vue.prototype.alexPost(url, params, success);
}





