import Vue from "vue";

export function getGoodsDetailListApi(params, success) {
    let url = "/admin/goodsDetail/getGoodsDetailList";
    Vue.prototype.alexPost(url, params, success);
}

export function updateGoodsDetailIsShareApi(params, success,headers) {
    let url = "/admin/goodsDetail/updateGoodsDetailIsShare";
    Vue.prototype.alexPost(url, params, success,headers);
}


export function deleteGoodsDetailApi(params, success){
    let url = "/admin/goodsDetail/deleteGoodsDetail";
    Vue.prototype.alexPost(url, params, success);
}

