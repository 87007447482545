<template>
  <div class="full-container">

    <div style="width:100%;height:auto;float:left;border-radius: 15px;background-color: white;padding-bottom:40px;">

      <div style="margin-top: 10px;">
        <!-- 查询按钮区 -->
        <div style="width: 100%;height: 60px;float: left;line-height: 60px;padding-left: 15px">
          <div style="width: auto;float: left;margin-right: 20px">
            <el-input size="mini" style="width: 200px;float: left;" placeholder="请输入商品名称关键字" clearable
                      prefix-icon="el-icon-search" v-model="selectForm.key"></el-input>
          </div>
          <div style="width: auto;float: left;margin-right: 20px">
            <el-select placeholder="是否上架" size="mini" style="width:100px;float: left;" v-model="selectForm.isShelf"
                       clearable filterable>
              <el-option v-for="item in alexBoolList" :key="item.value" :label="item.zhName" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div style="width: auto;float: left;margin-right: 20px">
            <el-select placeholder="状态" size="mini" style="width:100px;float: left;" v-model="selectForm.isHot"
                       clearable filterable>
              <el-option v-for="item in alexBoolList" :key="item.value" :label="item.zhName" :value="item.value">
              </el-option>
            </el-select>
          </div>

          <div style="width: auto;float: left;margin-right: 20px">
            <el-button size="mini" style="background-color: #25AD7F" type="primary" @click="getGoodsList(true)">搜索
            </el-button>
          </div>
          <!-- 右边按钮 -->
          <div style="width: auto;float: right;margin-right: 20px">

            <el-button size="mini" style="background-color: #25AD7F" type="primary" @click="getGoodsList(true)">
              <a-icon type="plus-circle" />
              新增商品
            </el-button>

            <el-button size="mini" style="background-color: #E1F0EC;color:#25AD7F;border:1px solid #25AD7F;"
                       type="primary" @click="getGoodsList(true)">
              <a-icon type="minus-circle" />
              批量删除
            </el-button>

            <el-button size="mini" style="background-color: #E1F0EC;color:#25AD7F;border:1px solid #25AD7F;"
                       type="primary" @click="getGoodsList(true)">
              <a-icon type="minus-circle" />
              批量导出
            </el-button>


          </div>
        </div>

        <!-- 表单内容 -->
        <div style="padding: 15px;">
          <el-table align="center" :data="goodsTableData" style="color: #555;" size="mini">
            <el-table-column style="text-align: center" type="selection" min-width="10%"></el-table-column>
            <el-table-column align="center" sortable prop="serial" label="排序值" min-width="20%"></el-table-column>
            <el-table-column align="center" prop="name" label="商品名称" min-width="20%"></el-table-column>
            <el-table-column align="center" label="商品主图" min-width="20%">
              <template slot-scope="scope">
                <el-image v-if="scope.row.mainImageGuid" title="点击放大"
                          style="width: 100px; height: 100px"
                          :src="'/admin/file/downloadFile?id='+scope.row.mainImageGuid"
                          :preview-src-list="['/admin/file/downloadFile?id='+scope.row.mainImageGuid]">
                </el-image>
              </template>
            </el-table-column>

            <el-table-column align="center" sortable prop="price" label="单价(元)" min-width="20%"></el-table-column>
            <el-table-column align="center" label="支付方式" min-width="20%">
              <template slot-scope="scope">
                线上支付/线下付款
              </template>
            </el-table-column>

            <el-table-column align="center" label="是否上架" min-width="20%">
              <template slot-scope="scope">
                <el-switch @change="updateGoodsIsShelf(scope.row.id,scope.row.isShelf)" v-model="scope.row.isShelf"
                           active-value="1" inactive-value="0" active-color="#25AD7F" inactive-color="#F1F3F3">
                </el-switch>
              </template>
            </el-table-column>

            <el-table-column align="center" label="是否热卖" min-width="20%">
              <template slot-scope="scope">
                <el-switch @change="updateGoodsIsHot(scope.row.id,scope.row.isHot)" v-model="scope.row.isHot"
                           active-value="1" inactive-value="0" active-color="#25AD7F" inactive-color="#F1F3F3">
                </el-switch>
              </template>
            </el-table-column>

            <el-table-column align="center" label="状态" min-width="20%">
              <template slot-scope="scope">
                {{ scope.row.id % 2 == 0 ? "审核中" : "审核通过" }}
              </template>
            </el-table-column>

            <el-table-column align="center" label="操作" min-width="20%">
              <template slot-scope="scope">
                <span style="font-size:1rem;color:#1C53D9;cursor: pointer;"
                      @click="gotoGoodsDetail(scope.row.id)">详情</span>
                <span style="margin:0px 5px">|</span>
                <span style="font-size:1rem;color:#25AD7F;cursor: pointer;"
                      @click="updateGoods(scope.row.id)">编辑</span>
                <a-popconfirm title="是否删除?(敏感操作!)" ok-text="确定" cancel-text="取消"
                              @confirm="deleteGoods(scope.row.id)">
                  <span style="margin:0px 5px">|</span>
                  <span style="font-size:1rem;color:#E61F19;cursor: pointer;">删除</span>
                </a-popconfirm>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>


      <el-dialog title="新增系列" :visible.sync="insertDialog" width="30%" :before-close="insertCancel">
        <div>
          <el-form label-width="15%">

            <el-form-item style="" label="排序值">
              <el-input type="number" style="width:85%;float: left;" v-model="insertForm.serial" />
              <div style="width: 15%;float: left;"></div>
            </el-form-item>

            <el-form-item label="系列名称">
              <el-input maxlength="20" show-word-limit style="width:85%;float: left;" v-model="insertForm.name" />
              <div style="width: 15%;float: left;"></div>
            </el-form-item>

            <el-form-item label="系列主图">
              <el-upload action="#" list-type="picture-card" :on-change="changeMainImage"
                         :show-file-list="false" :multiple="false" :auto-upload="false">
                <el-image :fit="contain" v-if="mainPictureUrl" :src="mainPictureUrl.url" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>

            <el-form-item label="系列单价">

              <el-input type="number" style="width:85%;float: left;" v-model="insertForm.price" />
              <div style="width: 15%;float: left;">元/抽</div>
            </el-form-item>

            <el-form-item label="是否上架">
              <el-switch v-model="insertForm.isShelf" :active-value="1" :inactive-value="0" active-color="#13ce66"
                         inactive-color="#ff4949"></el-switch>
            </el-form-item>

            <el-form-item label="是否热卖">
              <el-switch v-model="insertForm.isHot" :active-value="1" :inactive-value="0" active-color="#13ce66"
                         inactive-color="#ff4949"></el-switch>
            </el-form-item>


            <el-form-item label="图片详情">
              <el-upload action="#" list-type="picture-card" :multiple="true" :on-change="detailPictureChange"
                         :file-list="detailPictureUrl" :auto-upload="false">
                <i slot="default" class="el-icon-plus"></i>
                <div slot="file" slot-scope="{file}">
                  <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
                  <span class="el-upload-list__item-actions">
                  <span class="el-upload-list__item-preview" @click="bigImageShow(file)">
                    <i class="el-icon-zoom-in"></i>
                  </span>
                  <span class="el-upload-list__item-delete" @click="detailPictureRemove(file)">
                    <i class="el-icon-delete"></i>
                  </span>
                </span>
                </div>
              </el-upload>
              <!-- 大图显示 -->
              <el-dialog :visible.sync="detailPictureShow">
                <img width="100%" :src="bigPictureUrl" alt="">
              </el-dialog>
            </el-form-item>
          </el-form>

        </div>
        <span slot="footer" class="dialog-footer">
          <el-button style="background-color: #AFE3FB;color: black" @click="insertCancel">取 消</el-button>
          <el-button style="background-color: #02A7F0;color: black" type="success"
                     @click="insertSubmit">确 定</el-button>
      </span>
      </el-dialog>


    </div>

  </div>
</template>

<script>

import { getAlexBoolListApi } from "@/router/api/common/data-api";

import {
  updateGoodsIsShelfApi,
  updateGoodsIsHotApi,
  deleteGoodsApi,
  getGoodsListApi
} from "@/router/api/goodsApi";

export default {
  name: "GoodsIndex",
  data() {
    return {


      alexBoolList: [],

      selectForm: {
        isShelf: null,
        isHot: null,
        key: null
      },
      goodsTableData: [],

      insertDialog: false,
      insertForm: {
        serial: null,
        name: null,
        price: null,
        isShelf: 1,
        isHot: 1
      },
      mainPictureUrl: "", //缩略图返显 上传
      detailPictureUrl: [],//详情页 图片数组
      detailPictureShow: false, //是否显示大图
      bigPictureUrl: ""//大图的url

    };
  },

  mounted() {

    this.load();


  },

  methods: {

    load() {

      this.getGoodsList();

      getAlexBoolListApi({}, res => {
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.alexBoolList = res.data.resultSetData;
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });


    },


    // 查询 begin
    selectFormReset() {
      this.selectForm = {
        isShelf: null,
        isHot: null,
        key: null
      };
    },

    fileGuidConvertToUrl(stringList) {
      let urlList = [];
      if (stringList && stringList.length > 0) {
        for (let i = 0; i < stringList.length; i++) {
          urlList.push("/admin/file/downloadFile?id=" + stringList[i]);
        }

      }
      return urlList;
    },

    getGoodsList(showMessage) {
      getGoodsListApi(this.selectForm, res => {
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              if (showMessage) {
                this.showMessage(res.data.msg, "success");
              }
              this.goodsTableData = res.data.resultSetData;
              console.info(this.goodsTableData);
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });
    },

    // 查询 end

    // 新增 begin

    insertShow() {
      this.insertDialog = true;
    },

    insertCancel() {
      this.insertDialog = false;
      this.insertForm = {
        serial: null,
        name: null,
        price: null,
        isShelf: 1,
        isHot: 1
      };
      this.mainPictureUrl = "";//缩略图返显 上传
      this.detailPictureUrl = [];//详情页 图片数组
      this.detailPictureShow = false; //是否显示大图
      this.bigPictureUrl = "";//大图的url
    },

    //选择缩略图后
    changeMainImage(file) {
      this.mainPictureUrl = file;
    },

    //详情 显示大图
    bigImageShow(e) {
      this.bigPictureUrl = e.url;
      this.detailPictureShow = true;
    },

    //详情 图片改变
    detailPictureChange(file, fileList) {
      this.detailPictureUrl = fileList;
    },

    //详情 删除图片
    detailPictureRemove(file) {
      for (let item in this.detailPictureUrl) {
        if (item && this.detailPictureUrl[item] && file && this.detailPictureUrl[item].uid == file.uid) {
          this.detailPictureUrl.splice(item, 1);
        }
      }
    },


    insertSubmit() {
      //数据组装
      let params = new FormData();
      params.append("name", this.insertForm.name);
      params.append("serial", this.insertForm.serial);
      params.append("price", this.insertForm.price);
      params.append("isShelf", this.insertForm.isShelf);
      params.append("isHot", this.insertForm.isHot);

      if (this.mainPictureUrl) {
        params.append("mainImageFileUpload", this.mainPictureUrl.raw);
      }

      if (this.detailPictureUrl && this.detailPictureUrl.length > 0) {
        for (let i = 0; i < this.detailPictureUrl.length; i++) {
          let temp = this.detailPictureUrl[i].raw;
          params.append("detailImageListFileUpload[]", temp);
        }
      }

      let url = "/admin/goods/insertGoods";
      this.alexPost(url, params, res => {
        //状态200
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.showMessage(res.data.msg, "success");
              this.insertCancel();
              this.getGoodsList();
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      }, {
        headers: {
          "Content-Type": "multipart/form-data",
          "token": localStorage.getItem("token")
        }
      });
    },

    // 新增 end


    //修改 begin

    updateGoodsIsShelf(goodsId, isShelf) {
      let params = {
        "id": goodsId,
        "isShelf": isShelf
      };
      updateGoodsIsShelfApi(params, res => {
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.showMessage(res.data.msg, "success");
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });
    },

    updateGoodsIsHot(goodsId, isHot) {
      let params = {
        "id": goodsId,
        "isHot": isHot
      };
      updateGoodsIsHotApi(params, res => {
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.showMessage(res.data.msg, "success");
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });
    },

    //修改 end

    //////////////////////////////////////////////////////////////////////////////////////////


    deleteGoods(goodsId) {
      let params = {
        "id": goodsId
      };
      deleteGoodsApi(params, res => {
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.showMessage(res.data.msg, "success");
              this.getGoodsList();
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });

    },

    updateGoods() {

    },

    gotoGoodsDetail(goodsId) {
      this.$router.push("/goodsDetail?goodsId=" + goodsId);
    }

  }

};

</script>


<style scoped>

/deep/ .el-table th.el-table__cell{
  background-color: #F8F8F8;
}

/deep/ .el-table th.el-table__cell > .cell {
  color: #554 !important;
  font-weight: 600;
  font-size: 1.05rem;
}

/deep/ .el-dialog__title {
  font-weight: 600;
}

/deep/ .el-dialog__header {
  border-bottom: 1px solid darkgray;
}

/deep/ .el-upload-dragger {
  height: auto;
}

/deep/ .el-form-item__content {
  text-align: left;
}

</style>
