import { render, staticRenderFns } from "./GoodsIndex.vue?vue&type=template&id=ae536482&scoped=true"
import script from "./GoodsIndex.vue?vue&type=script&lang=js"
export * from "./GoodsIndex.vue?vue&type=script&lang=js"
import style0 from "./GoodsIndex.vue?vue&type=style&index=0&id=ae536482&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ae536482",
  null
  
)

export default component.exports