import Vue from "vue";

export function insertOrderSettlementApi(params, success) {
  let url = "/admin/web/orderSettlement/insertOrderSettlement";
  Vue.prototype.alexPost(url, params, success);
}

export function pageMerchantOrderSettlementApi(params, success) {
  let url = "/admin/web/orderSettlement/pageMerchantOrderSettlement";
  Vue.prototype.alexPost(url, params, success);
}

export function pageOrderSettlementApi(params, success) {
  let url = "/admin/web/orderSettlement/pageOrderSettlement";
  Vue.prototype.alexPost(url, params, success);
}

export function updateOrderSettlementApi(params, success) {
  let url = "/admin/web/orderSettlement/updateOrderSettlement";
  Vue.prototype.alexPost(url, params, success);
}

export function countMerchantSettlementMoneyApi(params, success) {
  let url = "/admin/web/orderSettlement/countMerchantSettlementMoney";
  Vue.prototype.alexPost(url, params, success);
}



