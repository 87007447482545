import Vue from "vue";

export function insertTrainApi(params, success) {
  let url = "/admin/web/train/insertTrain";
  Vue.prototype.alexPost(url, params, success);
}

export function updateTrainApi(params, success) {
  let url = "/admin/web/train/updateTrain";
  Vue.prototype.alexPost(url, params, success);
}

export function deleteTrainApi(params, success) {
  let url = "/admin/web/train/deleteTrain";
  Vue.prototype.alexPost(url, params, success);
}

export function pageMerchantTrainApi(params, success) {
  let url = "/admin/web/train/pageMerchantTrain";
  Vue.prototype.alexPost(url, params, success);
}

export function listAllTrainApi(params, success) {
  let url = "/admin/web/train/listAllTrain";
  Vue.prototype.alexPost(url, params, success);
}




