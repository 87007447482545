import Vue from "vue";

export function insertCropApi(params, success) {
  let url = "/admin/web/crop/insertCrop";
  Vue.prototype.alexPost(url, params, success);
}

export function updateCropApi(params, success) {
  let url = "/admin/web/crop/updateCrop";
  Vue.prototype.alexPost(url, params, success);
}

export function deleteCropApi(params, success) {
  let url = "/admin/web/crop/deleteCrop";
  Vue.prototype.alexPost(url, params, success);
}

export function pageMerchantCropApi(params, success) {
  let url = "/admin/web/crop/pageMerchantCrop";
  Vue.prototype.alexPost(url, params, success);
}

export function listAllCropApi(params, success) {
  let url = "/admin/web/crop/listAllCrop";
  Vue.prototype.alexPost(url, params, success);
}




