import Vue from "vue";


export function insertKnowledgeApi(params, success) {
    let url = "/admin/web/knowledge/insertKnowledge";
    Vue.prototype.alexPost(url, params, success);
}

export function updateKnowledgeApi(params, success) {
    let url = "/admin/web/knowledge/updateKnowledge";
    Vue.prototype.alexPost(url, params, success);
}

export function deleteKnowledgeApi(params, success) {
    let url = "/admin/web/knowledge/deleteKnowledge";
    Vue.prototype.alexPost(url, params, success);
}

export function pageAllKnowledgeApi(params, success) {
    let url = "/admin/web/knowledge/pageAllKnowledge";
    Vue.prototype.alexPost(url, params, success);
}

export function deleteKnowledgeVideoApi(params, success) {
    let url = "/admin/web/knowledgeVideo/deleteKnowledgeVideo";
    Vue.prototype.alexPost(url, params, success);
}


