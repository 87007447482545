<template>
  <div class="full-container">
    <div
      style="width: 100%;float: left;height: 50px;line-height: 50px;font-size: 0.9rem; text-align: left;opacity: 0.9;">
      权限>角色管理
    </div>
    <div style="width:100%;height:auto;float:left;border-radius: 15px;background-color: white;padding-bottom:40px;">

      <div style="width:100%;float:left;margin-top: 10px;">
        <!-- 查询按钮区 -->
        <div style="width: 100%;height: 60px;float: left;line-height: 60px;padding-left: 15px">
          <!-- 左边 -->
          <div style="width: auto;float: left;margin-right: 20px;text-align: left;">
            <label style="float: left;font-size: 0.9rem;opacity: 0.9;">平台：</label>
            <el-select size="mini" style="float: left;" v-model="selectForm.platform" clearable filterable>
              <el-option v-for="item in platformList" :key="item.value" :label="item.name" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div style="width: auto;float: left;margin-right: 20px">
            <label style="float: left;font-size: 0.9rem;opacity: 0.9;">角色名称：</label>
            <el-input size="mini" style="width: 200px;float: left;" placeholder="模糊查询" clearable prefix-icon="el-icon-search" v-model="selectForm.name"></el-input>
          </div>
          <div style="width: auto;float: left;margin-right: 20px">
            <el-button size="mini" style="background-color: #25AD7F;border:none;" type="primary"
                       @click="pageAuthRole(true)">搜索
            </el-button>
            <el-button size="mini" style="background-color: #25AD7F1A;border:1px solid #25AD7F;color:#25AD7F"
                       type="primary" @click="selectReset()">重置
            </el-button>
          </div>

          <!-- 右边按钮 -->
          <div style="width: auto;float: right;margin-right: 20px">

            <el-button disabled plain  size="mini" style="background-color: #25AD7F" type="primary">
              <a-icon type="plus-circle" />
              新增角色
            </el-button>

            <el-button disabled plain  size="mini" style="background-color: #e32e2e1a;color:#e32e2e;border:1px solid #e32e2e;"
                       type="primary" >
              <a-icon type="minus-circle" />
              批量删除
            </el-button>

            <el-button disabled plain  size="mini" style="background-color: #E1F0EC;color:#25AD7F;border:1px solid #25AD7F;" type="primary">
              <a-icon type="minus-circle" />
              批量导出
            </el-button>
          </div>
        </div>

        <!-- 表单内容 -->
        <div style="width:100%;float:left;padding: 15px;">
          <el-table align="center" height="600" :data="tableData" style="color: #555;" size="mini">
            <el-table-column align="center" prop="platform" label="平台" min-width="20%"></el-table-column>
            <el-table-column align="center" prop="name" label="角色名称" min-width="20%"></el-table-column>
            <el-table-column align="center" prop="isReadOnly" label="是否只读" min-width="20%">
              <template slot-scope="scope">
                {{ scope.row.isReadOnly==1?'是':'否' }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="roleType" label="权限类型" min-width="20%"></el-table-column>
            <el-table-column align="center" prop="remark" label="备注" min-width="20%"></el-table-column>
          </el-table>
          <div style="width: 100%;float: right;margin-top: 10px;text-align: right;">
            <el-pagination
              @current-change="handleCurrentChange"
              :page-size="selectForm.pageSize"
              :current-page="selectForm.pageIndex"
              layout="total,prev, pager, next"
              :total="selectForm.total">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>

import {
  listPlatformApi
} from "@/router/api/common/data-api";

import { pageAuthRoleApi } from "@/router/api/auth/role-api";

export default {
  name: "AuthRoleIndex",
  data() {
    return {


      platformList: [],

      //查询
      tableData: [],
      selectForm: {
        name: null,
        platform: null,
        pageIndex: 1,
        pageSize: 10,
        total: 0
      }
    };
  },

  mounted() {

    this.load();


  },

  methods: {

    load() {
      this.pageAuthRole();

      listPlatformApi({}, res => {
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.platformList = res.data.resultSetData;
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });

    },

    // 查询
    selectReset() {
      this.selectForm = {
        name: null,
        platform: null,
        pageIndex: 1,
        pageSize: 10,
        total: 0
      };
      this.pageAuthRole(true);
    },

    handleCurrentChange(e) {
      this.selectForm.pageIndex = e;
      this.pageAuthRole(true);
    },

    pageAuthRole(showMessage) {

      pageAuthRoleApi(this.selectForm, res => {
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              if (showMessage) {
                this.showMessage(res.data.msg, "success");
              }
              this.tableData = res.data.resultSetData.list;
              this.selectForm.pageIndex = res.data.resultSetData.pageNum;
              this.selectForm.pageSize = res.data.resultSetData.pageSize;
              this.selectForm.total = res.data.resultSetData.total;
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });
    }


  }

};

</script>


<style scoped>

.dialog-footer {
  width: 100%;
  float: left;
  text-align: left;
}

.dialog-footer-submit {
  background-color: #25AD7F;
  color: white;
  width: 130px;
  border-radius: 50px;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 100;
}

.dialog-footer-submit:hover {
  background-color: #25AD7F;
  color: white;
  width: 130px;
  border-radius: 50px;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 100;
}

.dialog-footer-cancel {
  background-color: white;
  border: 1px solid #8080805c;
  color: #8080805c;
  width: 130px;
  border-radius: 50px;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 100;
}

.dialog-footer-cancel:hover {
  background-color: white;
  border: 1px solid #8080805c;
  color: #8080805c;
  width: 130px;
  border-radius: 50px;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 100;
}

/* 树形菜单 */

/deep/ .el-tree-node__content {
  height: 35px;
  line-height: 35px;
}

/* 表格 */

/deep/ .el-table th.el-table__cell {
  background-color: #F8F8F8;
}

/deep/ .el-table th.el-table__cell > .cell {
  color: #554 !important;
  font-weight: 600;
  font-size: 1.05rem;
}
/* 表格body的背景颜色 */
/deep/ .el-table--enable-row-transition .el-table__body td.el-table__cell{
  background-color: #80808073;
}


/* 模态框 */

/deep/ .el-dialog__title {
  font-weight: 600;
}

/deep/ .el-dialog__header {
  text-align: left;
  border-bottom: 1px solid darkgray;
}

/deep/ .el-dialog__body {
  width: 100%;
  float: left;
  background-color: white;
}

/deep/ .el-upload-dragger {
  height: auto;
}

/deep/ .el-form-item__content {
  text-align: left;
}

</style>
