<template>
  <div class="full-container">
    <div
      style="width: 100%;float: left;height: 50px;line-height: 50px;font-size: 0.9rem; text-align: left;opacity: 0.9;">
      农业资料库>药肥登记证号
    </div>
    <div style="width:100%;height:auto;float:left;border-radius: 15px;background-color: white;padding-bottom:40px;">

      <div style="width:100%;float:left;margin-top: 10px;">
        <!-- 查询按钮区 -->
        <div style="width: 100%;height: 60px;float: left;line-height: 60px;padding-left: 15px">
          <!-- 左边查询区 -->
          <div style="width: auto;float: left;margin-right: 20px">
            <label style="float: left;font-size: 0.9rem;opacity: 0.9;">登记证号：</label>
            <el-input size="mini" style="width: 200px;float: left;" placeholder="模糊查询" clearable
                      prefix-icon="el-icon-search" v-model="selectForm.no"></el-input>
          </div>
          <div style="width: auto;float: left;margin-right: 20px">
            <label style="float: left;font-size: 0.9rem;opacity: 0.9;">企业名称：</label>
            <el-input size="mini" style="width: 200px;float: left;" placeholder="模糊查询" clearable
                      prefix-icon="el-icon-search" v-model="selectForm.companyName"></el-input>
          </div>
          <div style="width: auto;float: left;margin-right: 20px">
            <label style="float: left;font-size: 0.9rem;opacity: 0.9;">药肥名称：</label>
            <el-input size="mini" style="width: 200px;float: left;" placeholder="模糊查询" clearable
                      prefix-icon="el-icon-search" v-model="selectForm.name"></el-input>
          </div>
          <div style="width: auto;float: left;margin-right: 20px">
            <el-button size="mini" style="background-color: #25AD7F;border:none;" type="primary"
                       @click="pageAllQualification(true)">搜索
            </el-button>
            <el-button size="mini" style="background-color: #25AD7F1A;border:1px solid #25AD7F;color:#25AD7F"
                       type="primary" @click="selectReset()">重置
            </el-button>
          </div>



        </div>

        <!-- 表单内容 -->
        <div style="width:100%;float:left;padding: 15px;">
          <el-table align="center" @selection-change="handleSelectionChange" height="600" :data="tableData"
                    style="color: #555;" size="mini">
            <el-table-column align="center" prop="no" label="登记证号" min-width="30%"></el-table-column>
            <el-table-column align="center" prop="companyName" label="登记证持有人" min-width="30%"></el-table-column>
            <el-table-column align="center" prop="name" label="药肥名称" min-width="20%"></el-table-column>
            <el-table-column align="center" prop="dosage" label="剂型" min-width="20%"></el-table-column>
            <el-table-column align="center" prop="toxicity" label="毒性" min-width="20%"></el-table-column>
            <el-table-column align="center" prop="proportioning" label="有效成分及其含量" min-width="20%"></el-table-column>
            <el-table-column align="center" prop="type" label="药肥类别" min-width="20%"></el-table-column>
            <el-table-column align="center" prop="crop" label="作物/场所" min-width="20%"></el-table-column>
            <el-table-column align="center" prop="control" label="防治对象" min-width="20%"></el-table-column>
            <el-table-column align="center" prop="standard" label="产品质量标准号" min-width="20%"></el-table-column>
            <el-table-column align="center" label="操作" min-width="20%">
              <template slot-scope="scope">
                <span style="font-size:0.9rem;color:#25AD7F;cursor: pointer;"     @click="detailShow(scope.row)">详情</span>
              </template>
            </el-table-column>
          </el-table>
          <div style="width: 100%;float: right;margin-top: 10px;text-align: right;">
            <el-pagination
              @current-change="handleCurrentChange"
              :page-size="selectForm.pageSize"
              :current-page="selectForm.pageIndex"
              layout="total,prev, pager, next"
              :total="selectForm.total">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>



    <!-- 详情 -->
    <el-dialog title="产品详情" :visible.sync="detailDialog" width="40%" :before-close="detailCancel">
      <div style="width: 100%;float: left">
        <div
          style="width: 100%;float: left;border-bottom: 1px solid #80808054;padding-bottom: 15px;margin-bottom: 20px;"
          v-if="detailInfo">
          <el-descriptions :title="detailInfo.name?detailInfo.name:''" :column=2>
            <el-descriptions-item label="登记证号">{{ detailInfo.no ? detailInfo.no : "" }}
            </el-descriptions-item>
            <el-descriptions-item label="登记证持有人">{{ detailInfo.companyName ? detailInfo.companyName : "" }}
            </el-descriptions-item>
            <el-descriptions-item label="药肥名称">{{ detailInfo.name ? detailInfo.name : "" }}
            </el-descriptions-item>
            <el-descriptions-item label="剂型">{{ detailInfo.dosage ? detailInfo.dosage : "" }}
            </el-descriptions-item>
            <el-descriptions-item label="毒性">{{ detailInfo.toxicity ? detailInfo.toxicity : "" }}
            </el-descriptions-item>
            <el-descriptions-item label="有效成分及其含量">{{ detailInfo.proportioning? detailInfo.proportioning: "" }}
            </el-descriptions-item>
            <el-descriptions-item label="药肥类别">{{ detailInfo.type ? detailInfo.type : "" }}
            </el-descriptions-item>
            <el-descriptions-item label="作物/场所">{{ detailInfo.crop ? detailInfo.crop : "" }}
            </el-descriptions-item>
            <el-descriptions-item label="防治对象">{{ detailInfo.control ? detailInfo.control : "" }}
            </el-descriptions-item>
            <el-descriptions-item label="使用方法">{{ detailInfo.useMethod ? detailInfo.useMethod : "" }}
            </el-descriptions-item>
            <el-descriptions-item label="产品质量标准号">{{ detailInfo.standard ? detailInfo.standard : "" }}
            </el-descriptions-item>
          </el-descriptions>
        </div>

      </div>
      <div class="dialog-footer">
        <el-button class="dialog-footer-cancel" @click="detailCancel">关闭</el-button>
      </div>
    </el-dialog>



  </div>
</template>

<script>


import * as XLSX from "xlsx";

import { pageAllQualificationApi } from "@/router/api/tool/qualification-api";

export default {
  name: "QualificationIndex",
  data() {
    return {
      alexBoolList: [],

      // 查询
      tableData: [],
      selectForm: {
        no: null,
        companyName: null,
        name: null,
        pageIndex: 1,
        pageSize: 10,
        total: 0
      },
      selectTableRow: [],



      //  详情
      detailDialog: false,
      detailInfo: null,


    };
  },

  mounted() {

    this.load();


  },

  methods: {

    load() {


      this.pageAllQualification();
    },


    // 查询 begin
    selectReset() {
      this.selectForm = {
        no: null,
        companyName: null,
        name: null,
        pageIndex: 1,
        pageSize: 10,
        total: 0
      };
      this.pageAllQualification(true);
    },

    handleCurrentChange(e) {
      this.selectForm.pageIndex = e;
      this.pageAllQualification(true);
    },

    handleSelectionChange(val) {
      this.selectTableRow = val;
    },

    pageAllQualification(showMessage) {
      pageAllQualificationApi(this.selectForm, res => {
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              if (showMessage) {
                this.showMessage(res.data.msg, "success");
              }
              this.tableData = res.data.resultSetData.list;
              this.selectForm.pageIndex = res.data.resultSetData.pageNum;
              this.selectForm.pageSize = res.data.resultSetData.pageSize;
              this.selectForm.total = res.data.resultSetData.total;
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });
    },

    exportTable() {
      if (!this.selectTableRow || this.selectTableRow.length == 0) {
        this.showMessage("请勾选需要导出的数据", "warning");
        return false;
      }

      const data = [
        [
          "名称",
          "标题",
          "规格",
          "配料",
          "特色",
          "产品性能",
          "作物（或范围）",
          "防治对象",
          "制剂用药量",
          "使用方法",
          "注意事项",
          "价格",
          "是否上架",
      ]
        ]

      //添加数据

      for (let i = 0; i < this.selectTableRow.length; i++) {
        let row = this.selectTableRow[i];
        if (row) {
          let cell1 = row.name ? row.name: "";
          let cell2 = row.title ? row.title: "";
          let cell3 = row.specification ? row.specification: "";
          let cell4 = row.proportioning ? row.proportioning: "";
          let cell5 = row.feature ? row.feature: "";
          let cell6 = row.context ? row.context: "";
          let cell7 = row.crop ? row.crop: "";
          let cell8 = row.control ? row.control: "";
          let cell9 = row.useDose ? row.useDose: "";
          let cell10 = row.useMethod ? row.useMethod: "";
          let cell11 = row.attention ? row.attention: "";
          let cell12 = row.price ? row.price: "";
          let cell13 = row.isShelf ? row.isShelf: "";

          data.push([cell1, cell2, cell3, cell4, cell5,cell6,cell7, cell8, cell9, cell10, cell11,cell12, cell13]);
        }
      }
      // 将数据转换为工作表
      const worksheet = XLSX.utils.aoa_to_sheet(data);

      // 创建工作簿并添加工作表
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      // 生成Excel文件
      const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

      // 使用blob和FileReader创建一个Blob URL
      const dataBlob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });
      const blobUrl = window.URL.createObjectURL(dataBlob);

      // 使用saveAs下载文件
      saveAs(dataBlob, "商户用户-" + new Date().toLocaleString() + ".xlsx");

      // 清理
      window.URL.revokeObjectURL(blobUrl);
    },

    // 查询 end


    // 新增 begin

    insertShow() {
      this.insertDialog = true;
    },

    insertCancel() {
      this.insertDialog = false;
      this.insertForm = {
        name: null,
        title: null,
        specification: null,
        proportioning: null,
        feature: null,
        context: null,
        crop: null,
        control: null,
        useDose: null,
        useMethod: null,
        attention: null,
        price: null,
        isShelf: 1
      };
      this.mainImageFile = null;
      this.detailImageFileList = [];
      this.detailImageShow = false;
      this.detailImageShowUrl = "";
    },


    // 营业执照上传
    changeCompanyIdCard(file) {
      this.mainImageFile = file;
    },

    //身份证上传
    changeChargeUserIdCard(file, fileList) {
      this.detailImageFileList = fileList;
    },

    removeChargeUserIdCard(file) {
      for (let item in this.detailImageFileList) {
        if (item && this.detailImageFileList[item] && file && this.detailImageFileList[item].uid == file.uid) {
          this.detailImageFileList.splice(item, 1);
        }
      }
    },

    detailImageShowDialog(e) {
      this.detailImageShowUrl = e.url;
      this.detailImageShow = true;
    },


    insertSubmit() {

      //TODO 非空判断
      if (!this.insertForm.name) {
        this.showMessage("请填写产品名称", "error");
        return false;
      }
      if (!this.insertForm.title) {
        this.showMessage("请填写产品标题", "error");
        return false;
      }
      if (!this.insertForm.price) {
        this.showMessage("请输入产品价格", "error");
        return false;
      }

      //数据组装
      let params = new FormData();
      params.append("name", this.insertForm.name);
      params.append("title", this.insertForm.title);
      params.append("specification", this.insertForm.specification);
      params.append("proportioning", this.insertForm.proportioning);
      params.append("feature", this.insertForm.feature);
      params.append("context", this.insertForm.context);
      params.append("crop", this.insertForm.crop);
      params.append("control", this.insertForm.control);
      params.append("useDose", this.insertForm.useDose);
      params.append("useMethod", this.insertForm.useMethod);
      params.append("attention", this.insertForm.attention);
      params.append("price", this.insertForm.price);
      params.append("isShelf", this.insertForm.isShelf);

      if (this.mainImageFile) {
        params.append("mainImageMultipartFile", this.mainImageFile.raw);
      } else {
        this.showMessage("请上传缩略图", "error");
        return false;
      }

      if (this.detailImageFileList && this.detailImageFileList.length > 0) {
        for (let i = 0; i < this.detailImageFileList.length; i++) {
          let temp = this.detailImageFileList[i].raw;
          params.append("detailImageMultipartFile[]", temp);
        }
      } else {
        this.showMessage("请上传详细照片", "error");
        return false;
      }

      let url = "/admin/web/product/insertProduct";
      this.alexPost(url, params, res => {
        //状态200
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.showMessage(res.data.msg, "success");
              this.insertCancel();
              this.pageAllQualification();
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      }, {
        headers: {
          "Content-Type": "multipart/form-data",
          "token": localStorage.getItem("token")
        }
      });
    },
    // 新增 end

    // 详情 begin
    detailShow(data) {
      this.detailInfo = data;
      this.detailDialog = true;
    },

    detailCancel() {
      this.detailDialog = false;
      this.detailInfo = null;
    },
    // 详情 end


    // 删除 begin
    deleteProduct(item) {
      if (!item) {
        this.showMessage("请选择需要删除的产品", "warning");
        return false;
      }
      let params = [
        {
          "id": item.id
        }
      ];
      deleteProductApi(params, res => {
        //状态200
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.showMessage(res.data.msg, "success");
              this.pageAllQualification();
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });


    },

    deleteProductBatch() {

      if (!this.selectTableRow || this.selectTableRow.length == 0) {
        this.showMessage("请勾选需要需要删除的产品", "warning");
        return false;
      }

      let params = [];
      for (let i = 0; i < this.selectTableRow.length; i++) {
        let row = this.selectTableRow[i];
        if (row) {
          params.push({
            "id": row.id
          });
        }
      }

      deleteProductApi(params, res => {
        //状态200
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.showMessage(res.data.msg, "success");
              this.pageAllQualification();
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });


    },

    // 删除 end

    // 修改 begin

    updateIsShelf(id, isShelf) {
      let params = {
        "id": id,
        "isShelf": isShelf
      };
      updateIsShelfApi(params, res => {
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.showMessage(res.data.msg, "success");
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });
    },

    updateShow(data) {
      this.updateDialog = true;
      this.updateForm = {
        id: data.id,
        name: data.name,
        title: data.title,
        specification: data.specification,
        proportioning:data.proportioning,
        feature:data.feature,
        context: data.context,
        crop: data.crop,
        control: data.control,
        useDose: data.useDose,
        useMethod: data.useMethod,
        attention: data.attention,
        price: data.price,
        isShelf:data.isShelf,
      };


      this.updateMainImageFileUrl = "/admin/web/file/downloadFile?id=" + data.mainImageGuid;//缩略图返显 上传
      this.updateDetailImageFileListUrl = [];//详情页 图片数组
      if (data.detailImageGuid && data.detailImageGuid.length > 0) {
        for (let i = 0; i < data.detailImageGuid.length; i++) {
          let guid = data.detailImageGuid[i];
          this.updateDetailImageFileListUrl.push({
            name: guid,
            url: "/admin/web/file/downloadFile?id=" + guid
          });
        }
      }
    },


    updateCancel() {
      this.updateDialog = false;
      this.updateForm = {
        id: null,
        name: null,
        title: null,
        specification: null,
        proportioning: null,
        feature: null,
        context: null,
        crop: null,
        control: null,
        useDose: null,
        useMethod: null,
        attention: null,
        price: null,
        isShelf: null
      };
      this.updateMainImageFile = null;
      this.updateMainImageFileUrl = null; //返现

      this.updateDetailImageShow = false;
      this.updateDetailImageShowUrl = "";
      this.updateDetailImageFileListUrl = [];
    },


    // 营业执照上传
    updateChangeCompanyIdCard(file) {
      this.updateMainImageFile = file;
      this.updateMainImageFileUrl = file.url;
    },

    //身份证上传
    updateChangeChargeUserIdCard(file, fileList) {
      this.updateDetailImageFileListUrl = fileList;
    },

    updateRemoveChargeUserIdCard(file) {
      for (let item in this.updateDetailImageFileListUrl) {
        if (item && this.updateDetailImageFileListUrl[item] && file && this.updateDetailImageFileListUrl[item].uid == file.uid) {
          this.updateDetailImageFileListUrl.splice(item, 1);
        }
      }
    },

    updateDetailImageShowDialog(e) {
      this.updateDetailImageShowUrl = e.url;
      this.updateDetailImageShow = true;
    },


    updateSubmit() {

      //TODO 非空判断
      if (!this.updateForm.name) {
        this.showMessage("请填写产品名称", "error");
        return false;
      }
      if (!this.updateForm.title) {
        this.showMessage("请填写产品标题", "error");
        return false;
      }
      if (!this.updateForm.price) {
        this.showMessage("请输入产品价格", "error");
        return false;
      }

      //数据组装
      let params = new FormData();
      params.append("id", this.updateForm.id);
      params.append("name", this.updateForm.name);
      params.append("title", this.updateForm.title);
      params.append("specification", this.updateForm.specification);
      params.append("proportioning", this.updateForm.proportioning);
      params.append("feature", this.updateForm.feature);
      params.append("context", this.updateForm.context);
      params.append("crop", this.updateForm.crop);
      params.append("control", this.updateForm.control);
      params.append("useDose", this.updateForm.useDose);
      params.append("useMethod", this.updateForm.useMethod);
      params.append("attention", this.updateForm.attention);
      params.append("price", this.updateForm.price);
      params.append("isShelf", this.updateForm.isShelf);

      if (this.updateMainImageFile) {
        params.append("mainImageMultipartFile", this.updateMainImageFile.raw);
      }

      if (this.updateDetailImageFileListUrl && this.updateDetailImageFileListUrl.length > 0) {
        for (let i = 0; i < this.updateDetailImageFileListUrl.length; i++) {
          let temp = this.updateDetailImageFileListUrl[i];
          if (temp) {
            if (temp.status == "success") {
              params.append("detailImageListFileGuid[]", temp.name);
            }
            if (temp.status == "ready") {
              params.append("detailImageMultipartFile[]", temp.raw);
            }
          }
        }
      }

      let url = "/admin/web/product/updateProduct";
      this.alexPost(url, params, res => {
        //状态200
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.showMessage(res.data.msg, "success");
              this.updateCancel();
              this.pageAllQualification();
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      }, {
        headers: {
          "Content-Type": "multipart/form-data",
          "token": localStorage.getItem("token")
        }
      });
    },

    // 修改 end

    // 工具
    parseDate(date) {
      if (date) {
        return new Date(date).toLocaleString();
      }
      return "";
    }
  }


}
;

</script>


<style scoped>

/deep/ .el-card__body, .el-main {
  float: left;
  width: 100%;
}

/*  通用 */
.dialog-footer {
  width: 100%;
  float: left;
  text-align: left;
}

.dialog-footer-submit {
  background-color: #25AD7F;
  color: white;
  width: 130px;
  border-radius: 50px;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 100;
}

.dialog-footer-submit:hover {
  background-color: #25AD7F;
  color: white;
  width: 130px;
  border-radius: 50px;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 100;
}

.dialog-footer-cancel {
  background-color: white;
  border: 1px solid #8080805c;
  color: #8080805c;
  width: 130px;
  border-radius: 50px;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 100;
}

.dialog-footer-cancel:hover {
  background-color: white;
  border: 1px solid #8080805c;
  color: #8080805c;
  width: 130px;
  border-radius: 50px;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 100;
}

/* 树形菜单 */

/deep/ .el-tree-node__content {
  height: 35px;
  line-height: 35px;
}

/* 表格 */

/deep/ .el-table th.el-table__cell {
  background-color: #F8F8F8;
}

/deep/ .el-table th.el-table__cell > .cell {
  color: #554 !important;
  font-weight: 600;
  font-size: 1.05rem;
}


/* 模态框 */

/deep/ .el-dialog__title {
  font-weight: 600;
}

/deep/ .el-dialog__header {
  text-align: left;
  border-bottom: 1px solid darkgray;
}

/deep/ .el-dialog__body {
  width: 100%;
  float: left;
  background-color: white;
}

/deep/ .el-upload-dragger {
  height: auto;
}

/deep/ .el-form-item__content {
  text-align: left;
}


/* 纯数字 */
/deep/ .numberInput input::-webkit-outer-spin-button,
/deep/ .numberInput input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

/deep/ .numberInput input[type="number"] {
  -moz-appearance: textfield;
}

</style>
