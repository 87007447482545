import Vue from "vue";
import VueRouter from "vue-router";

//登录
import Login from "../views/login";
//首页框架
import Home from "../views/home";

//主页
import Index from "@/views/index/Index";
import MerchantCharts from "@/views/index/merchant-charts";
import EmptyIndex from "@/views/index/empty-index";

//商户入驻申请
import MerchantApply from "../views/merchant-apply";
import MerchantSuccess from "@/views/merchant-success";
import MerchantIndex from "@/views/merchant/merchant-index"; //商户管理
import MerchantPublicAccount from "@/views/merchant/merchant-public-account";//商户对公账号
import MerchantUserSetting from "@/views/merchant/merchant-user-setting";//商户用户设置
import MerchantSetting from "@/views/merchant/merchant-setting"; //商户资质
import MerchantInfo from "@/views/merchant/merchant-info";

// 权限
import MenuSettingIndex from "@/views/sys/auth/menu-setting-index";
import AuthRoleIndex from "@/views/sys/auth/auth-role-index";
import UserIndex from "@/views/user/user-index";

//系统
import SysLogIndex from "@/views/sys/log/sys-log-index";


//审核
import ApproveIndex from "@/views/sys/approve/approve-index";

//供需市场
import MerchantSquare from "@/views/square/merchant-square";
import SquareIndex from "@/views/square/square-index";

//专家组
import MerchantExpert from "@/views/expert/merchant-expert";

//商品
import MerchantProduct from "@/views/product/merchant-product";

//订单
import MerchantOrder from "@/views/order/merchant-order";
import MerchantOrderSettlement from "@/views/order/merchant-order-settlement";
import OrderSettlementIndex from "@/views/order/order-settlement-index";
import MerchantWorkflow from "@/views/order/merchant-workflow";
import UserWorkflow from "@/views/order/user-workflow";





//商品
import GoodsIndex from "@/views/goods/GoodsIndex";
import GoodsDetailIndex from "@/views/goods/GoodsDetailIndex";

//工具
import QualificationIndex from "@/views/tool/qualification-index";


//学习中心
import KnowledgeType from "@/views/knowledge/knowledge-type";
import KnowledgeIndex from "@/views/knowledge/knowledge-index";

//微信小程序
import BannerIndex from "@/views/banner/banner-index";
import SysOpenIndex from "@/views/sys/open/sys-open-index";


import axios from "axios";

import MerchantCrop from "@/views/agriculture/merchant-crop";
import MerchantDrug from "@/views/agriculture/merchant-drug";
import MerchantProject from "@/views/agriculture/merchant-project";
import MerchantTrain from "@/views/agriculture/merchant-train";

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

const subMenu = [
  //首页
  {
    path: "/index",
    component: Index
  },
  {
    path: "/merchantCharts",
    component: MerchantCharts
  },
  {
    path: "/emptyIndex",
    component: EmptyIndex
  },

  //菜单设置
  {
    path: "/menuSettingIndex",
    component: MenuSettingIndex
  },
  {
    path: "/authRoleIndex",
    component: AuthRoleIndex
  },
  {
    path: "/userIndex",
    component: UserIndex
  },

  //系统日志
  {
    path: "/sysLogIndex",
    component: SysLogIndex
  },

  //审核
  {
    path: "/approveIndex",
    component: ApproveIndex
  },

  //商户
  {
    path: "/merchantIndex",
    component: MerchantIndex
  },
  {
    path: "/merchantPublicAccount",
    component: MerchantPublicAccount
  },
  {
    path: "/merchantUserSetting",
    component: MerchantUserSetting
  },
  {
    path: "/merchantSetting",
    component: MerchantSetting
  },  {
    path: "/merchantInfo",
    component: MerchantInfo
  },

  //供需市场
  {
    path: "/merchantSquare",
    component: MerchantSquare
  },
  {
    path: "/squareIndex",
    component: SquareIndex
  },
  {
    path: "/merchantExpert",
    component: MerchantExpert
  },
  {
    path: "/merchantProduct",
    component: MerchantProduct
  },
  {
    path: "/merchantOrder",
    component: MerchantOrder
  },
  {
    path: "/merchantOrderSettlement",
    component: MerchantOrderSettlement
  },  {
    path: "/orderSettlementIndex",
    component: OrderSettlementIndex
  },

  {
    path: "/merchantWorkflow",
    component: MerchantWorkflow
  },
  {
    path: "/userWorkflow",
    component: UserWorkflow
  },

  //工具
  {
    path: "/qualificationIndex",
    component: QualificationIndex
  },

  {
    path: "/knowledgeType",
    component: KnowledgeType
  },
  {
    path: "/knowledgeIndex",
    component: KnowledgeIndex
  },

  {
    path: "/bannerIndex",
    component: BannerIndex
  },
  {
    path: "/sysOpenIndex",
    component: SysOpenIndex
  },

  {
    path: "/merchantCrop",
    component: MerchantCrop
  },
  {
    path: "/merchantDrug",
    component: MerchantDrug
  },
  {
    path: "/merchantProject",
    component: MerchantProject
  },
  {
    path: "/merchantTrain",
    component: MerchantTrain
  },






  {
    path: "/goodsIndex",
    component: GoodsIndex
  },
  {
    path: "/goodsDetail",
    component: GoodsDetailIndex
  },


  {
    path: "/user",
    component: UserIndex
  }


];


const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    children: subMenu
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    children: subMenu
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/merchantApply",
    name: "MerchantApply",
    component: MerchantApply
  },
  {
    path: "/merchantSuccess",
    name: "merchantSuccess",
    component: MerchantSuccess
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  // 返回 false 以取消导航
  //如果是跳转到登录页 则放行
  if (to.path == "/login" || to.path == "/merchantApply" || to.path == "/merchantSuccess") {
    return next();
  } else {
    //获取缓存中的token
    let token = localStorage.getItem("token");
    if (token) {
      let url = "/admin/web/user/tokenIsOk";
      let params = { "token": token };
      axios.post(url, params).then(res => {
        if (res && res.data && res.data.code && res.data.code == "000001") {
          return next();
        } else {
          // TODO 需要提示错误信息
          next({ name: "Login" });
        }
      }).catch(function() {
        Vue.prototype.showMessage("登录令牌已经超时，请重新登录", "warning");
        next({ name: "Login" });
      });
    } else {//缓存中没有token 则跳转到登录页面
      next({ name: "Login" });
    }
  }
  return false;
});


export default router;
