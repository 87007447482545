<template>
  <div class="home-container" style="background-color: #F8F9FB;padding: 0% 15%;overflow-y: auto;">
    <div class="title-name">
      托托农业平台-商户入驻申请
    </div>
    <div class="form-container">
      <el-result icon="success" title="入驻申请已提交" subTitle="您的商户申请已经提交成功，托托农业平台将于5个工作日内审核。审核结果请留意主体负责人的手机短信和邮箱，谢谢。">
        <template slot="extra">
        </template>
      </el-result>

    </div>
  </div>
</template>

<script>

export default {
  name: "MerchantSuccess",
  data() {
    return {


    };
  },

  created() {

  },

  mounted() {
  },

  methods: {
    closeWindow(){
      debugger;
      //关闭页面
      if (navigator.userAgent.indexOf('Firefox') != -1 || navigator.userAgent.indexOf('Chrome')!= -1) {
        window.location.href = 'about:blank'
        window.close()
      } else {
        window.opener = null
        window.open('', '_self')
        window.close()
      }
    }

  }


};


</script>

<style scoped>
.home-container {
  width: 100%;
  height: 100%;
}


.title-name {
  width: 100%;
  height: 100px;
  line-height: 100px;
  font-weight: 600;
  font-size: 2rem;
  color: #25ac7f
}

.form-container {
  width: 100%;
  float: left;
  background-color: white;
  border-radius: 8px;
  padding: 50px 200px
}

</style>
