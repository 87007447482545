<template>
  <div class="full-container">
    <div
      style="width: 100%;float: left;height: 50px;line-height: 50px;font-size: 0.9rem; text-align: left;opacity: 0.9;">
      商户设置>对公账号
    </div>
    <div style="width:100%;height:auto;float:left;border-radius: 15px;background-color: white;padding-bottom:40px;">

      <div style="width:100%;float:left;margin-top: 10px;">
        <div style="width: 100%;float: left;height: 50px;line-height: 50px;font-size: 1.1rem;font-weight: 600;color:#6bd36b">
          托托农业平台温馨提醒：为确保您的提现流程顺畅无阻，请细心核对并确认您的公司账户信息完全正确哦！
        </div>
        <div style="width: 100%;float: left;height: 50px;line-height: 50px;font-size: 0.9rem;font-weight: 600;">
          （商户结算费率为0.6%，结算周期为每周一）
        </div>

        <!-- 表单内容 -->
        <div style="width:100%;float:left;padding: 15px;">
          <el-form label-width="15%" style="width: 100%;float:left;">
            <el-form-item style="">
              <template slot="label">
                账户名称 <span style="color: red">*</span>
              </template>
              <el-input size="mini" style="width:85%;float: left;" v-model="updateForm.name" />
              <div style="width: 15%;float: left;text-indent: 5px;"></div>
            </el-form-item>

            <el-form-item>
              <template slot="label">
                对公账户 <span style="color: red">*</span>
              </template>
              <el-input size="mini" style="width:85%;float: left;" v-model="updateForm.publicAccount" />
              <div style="width: 15%;float: left;text-indent: 5px;"></div>
            </el-form-item>

            <el-form-item label="">
              <template slot="label">
                开户银行 <span style="color: red">*</span>
              </template>
              <el-input size="mini" style="width:85%;float: left;" v-model="updateForm.bank" />
              <div style="width: 15%;float: left;text-indent: 5px;"></div>
            </el-form-item>

            <el-form-item label="">
              <template slot="label">
                开户支行 <span style="color: red">*</span>
              </template>
              <el-input size="mini" style="width:85%;float: left;" v-model="updateForm.subBank" />
              <div style="width: 15%;float: left;text-indent: 5px;"></div>
            </el-form-item>
          </el-form>
        </div>
        <div class="dialog-footer" style="padding-left: 50px;">
          <el-button class="dialog-footer-submit" @click="updateSubmit">提交</el-button>
          <el-button class="dialog-footer-cancel" @click="updateCancel">取消</el-button>
        </div>
      </div>
    </div>


  </div>
</template>

<script>

import { getMyMerchantApi, updateOrInsertMyMerchantPublicAccountApi } from "@/router/api/merchant/merchant-api";


export default {
  name: "MerchantIndex",
  data() {
    return {

      //查询
      merchantInfo: {},
      updateForm: {
        name: null,
        publicAccount: null,
        bank: null,
        subBank: null
      }
    };
  },

  mounted() {

    this.load();


  },

  methods: {

    load() {
      this.getMyMerchant();
    },


    getMyMerchant(showMessage) {
      //查询前先重置
      this.merchantInfo = {};
      this.updateForm = {
        name: null,
        publicAccount: null,
        bank: null,
        subBank: null
      };
      getMyMerchantApi(this.selectForm, res => {
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              if (showMessage) {
                this.showMessage(res.data.msg, "success");
              }
              this.merchantInfo = res.data.resultSetData;
              //对公账户信息是否为空
              if (res.data.resultSetData.merchantPublicAccountVO) {
                this.updateForm.id = res.data.resultSetData.merchantPublicAccountVO.id;
                this.updateForm.name = res.data.resultSetData.merchantPublicAccountVO.name;
                this.updateForm.publicAccount = res.data.resultSetData.merchantPublicAccountVO.publicAccount;
                this.updateForm.bank = res.data.resultSetData.merchantPublicAccountVO.bank;
                this.updateForm.subBank = res.data.resultSetData.merchantPublicAccountVO.subBank;
              } else {
                this.updateForm.name = this.merchantInfo.merchantDetailVO ? this.merchantInfo.merchantDetailVO.companyName : null;
              }
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });
    },

    updateSubmit() {

      if (!this.updateForm.name) {
        this.showMessage("请填写账户名称", "error");
        return false;
      }
      if (!this.updateForm.publicAccount) {
        this.showMessage("请填写对公账号", "error");
        return false;
      }
      if (!this.updateForm.bank) {
        this.showMessage("请填写开户银行", "error");
        return false;
      }
      if (!this.updateForm.subBank) {
        this.showMessage("请填写开户支行", "error");
        return false;
      }

      updateOrInsertMyMerchantPublicAccountApi(this.updateForm, res => {
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.showMessage(res.data.msg, "success");
              this.getMyMerchant();
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });

    },

    updateCancel() {
      this.getMyMerchant();

    },

    // 工具
    parseDate(date) {
      if (date) {
        return new Date(date).toLocaleString();
      }
      return "";
    }
  }

};

</script>


<style scoped>

.dialog-footer {
  width: 100%;
  float: left;
  text-align: left;
}

.dialog-footer-submit {
  background-color: #25AD7F;
  color: white;
  width: 130px;
  border-radius: 50px;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 100;
}

.dialog-footer-submit:hover {
  background-color: #25AD7F;
  color: white;
  width: 130px;
  border-radius: 50px;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 100;
}

.dialog-footer-cancel {
  background-color: white;
  border: 1px solid #8080805c;
  color: #8080805c;
  width: 130px;
  border-radius: 50px;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 100;
}

.dialog-footer-cancel:hover {
  background-color: white;
  border: 1px solid #8080805c;
  color: #8080805c;
  width: 130px;
  border-radius: 50px;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 100;
}

/* 树形菜单 */

/deep/ .el-tree-node__content {
  height: 35px;
  line-height: 35px;
}

/* 表格 */

/deep/ .el-table th.el-table__cell {
  background-color: #F8F8F8;
}

/deep/ .el-table th.el-table__cell > .cell {
  color: #554 !important;
  font-weight: 600;
  font-size: 1.05rem;
}


/* 模态框 */

/deep/ .el-dialog__title {
  font-weight: 600;
}

/deep/ .el-dialog__header {
  text-align: left;
  border-bottom: 1px solid darkgray;
}

/deep/ .el-dialog__body {
  width: 100%;
  float: left;
  background-color: white;
}

/deep/ .el-upload-dragger {
  height: auto;
}

/deep/ .el-form-item__content {
  text-align: left;
}

</style>
