<template>
  <div class="login-container">
    <!-- 背景图片 -->
    <img class="login-bg" :src="require('../assets/login/login-bg.png')" />
    <!-- 登录面板 -->
    <div class="login-panel">
      <div style="width: 100%;float: left; margin-top:30%;padding-left: 18%;padding-right: 18%;">
        <div class="login-form-row"  style="margin-top: 0%;color: #25AC7F;font-weight: 900;font-size: 2rem;text-align: left;">
          欢迎登录
        </div>
        <!-- 表单 -->
        <div class="login-form-row" style="margin-top: 45px;">
          <div class="login-form-row-input">
            <el-input ref="loginNameFocus" autocomplete="on"  v-model="loginName" @keyup.enter.native="clickByEnterKey"  :style="buttonHeight"  placeholder="用户名">
              <i slot="prefix" style="font-size: 1.1rem" class="el-input__icon el-icon-user"></i>
            </el-input>
          </div>
        </div>
        <div class="login-form-row" style="margin-top: 7%;">
          <div class="login-form-row-input">
            <el-input v-model="loginPassword"  @keyup.enter.native="clickByEnterKey" type="password" :style="buttonHeight" placeholder="密码">
              <i slot="prefix" style="font-size: 1.1rem" class="el-input__icon el-icon-lock"></i>
            </el-input>
          </div>
        </div>
        <div class="login-form-row" style="margin-top: 7.8%;margin-bottom: 10px;">
          <div style="width: 48%;float: left;margin-right: 4%;">
            <el-button  @click="submit" class="login-user-button">登录</el-button>
          </div>
          <div style="width: 48%;float: left;">
            <el-button  @click="gotoMerchantApplyPage" class="register-merchant-button">商户入驻</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { loginUserApi } from "@/router/api/user-api";

export default {
  name: "login",
  data() {
    return {
      windowHeight: document.documentElement.clientHeight,
      windowWidth: document.documentElement.clientWidth,
      loginName: null,
      loginPassword: null,
    };
  },
  created() {

  },

  mounted() {
    this.$refs["loginNameFocus"].focus();
    window.onresize = () => {
      return (() => {
        this.windowHeight = document.documentElement.clientHeight;  // 高
        this.windowWidth = document.documentElement.clientWidth;// 宽
        this.buttonHeight = this.windowHeight * 0.092 + "px";
      })();
    };
  },
  computed: {
    buttonHeight: function() {
      return {
        height: this.windowHeight * 0.092 + "px"
      };
    }
  },

  methods: {

    gotoMerchantApplyPage(){
      window.open(this.$router.resolve({name: 'MerchantApply'}).href, '_blank');
    },

    //表单回车键
    clickByEnterKey(e) {
      if (e.keyCode == 13) {
        this.submit();
      }
    },

    submit() {
      if (!this.loginName) {
        this.showMessage("请输入用户名", "warning");
        return false;
      }
      if (!this.loginPassword) {
        this.showMessage("请输入密码", "warning");
        return false;
      }
      let params = {
        "no": this.loginName,
        "loginPassword": this.loginPassword
      };


      //登录请求
      loginUserApi(params, res => {
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {//登录成功 保存token到localStorage
              localStorage.setItem("token", res.data.resultSetData.token);
              localStorage.setItem("user", JSON.stringify(res.data.resultSetData));
              //显示登录成功消息
              this.showMessage(res.data.msg, "success");
              //跳转到首页
              this.$router.push("/home");
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("登录请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });
    },

  }
};

</script>

<style scoped>
.login-container {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;

}

.login-bg {
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: -1;
}

.login-panel {
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0%;
  left: 50%;
  z-index: 1;
  background-color: #ffffff;
}

.login-form-row {
  width: 100%;
  float: left;
}

.login-form-row-input {
  float: left;
  width: 100%;
}


/deep/ .el-input__inner {
  background-color: #F4F8F7;
  height: 100%;
  font-size: 1.1rem;
}

/deep/ .el-input__inner:focus{
  border-color:#25AC7F;
}

.login-user-button {
  height: 50px;
  line-height: 0px;
  border: none;
  color: white;
  width: 100%;
  font-size: 1.1rem;
  border-radius: 50px;
  letter-spacing: 10px;
  background-image: url('@/assets/login/login-bottom-bg.png'); /* 替换为你的图片路径 */
  background-size: cover; /* 背景图片覆盖整个按钮 */
  background-position: center; /* 背景图片居中 */
  font-weight: 600;
}

.login-user-button:hover {
  height: 50px;
  line-height: 0px;
  border: none;
  color: white;
  width: 100%;
  font-size: 1.1rem;
  border-radius: 50px;
  letter-spacing: 10px;
  background-image: url('@/assets/login/login-bottom-bg.png'); /* 替换为你的图片路径 */
  background-size: cover; /* 背景图片覆盖整个按钮 */
  background-position: center; /* 背景图片居中 */
  font-weight: 600;
}

.register-merchant-button{
  height: 50px;
  line-height: 0px;
  border: none;
  color: #49AC23;
  width: 100%;
  font-size: 1.1rem;
  border-radius: 50px;
  letter-spacing: 10px;
  background-image: url('@/assets/login/login-register-bottom-bg.png'); /* 替换为你的图片路径 */
  background-size: 100% 100%; /* 背景图片覆盖整个按钮 */
  background-position: center; /* 背景图片居中 */
  font-weight: 600;
}

.register-merchant-button:hover{
  height: 50px;
  line-height: 0px;
  border: none;
  color: #49AC23;
  width: 100%;
  font-size: 1.1rem;
  border-radius: 50px;
  letter-spacing: 10px;
  background-image: url('@/assets/login/login-register-bottom-bg.png'); /* 替换为你的图片路径 */
  background-size: 100% 100%; /* 背景图片覆盖整个按钮 */
  background-position: center; /* 背景图片居中 */
  font-weight: 600;
}

input:focus {
  outline: none;
}

/deep/ input {
  caret-color: black;
  text-indent: 5px;
}

</style>
