import Vue from "vue";

export function pageAllOrderApi(params, success) {
  let url = "/admin/web/order/pageAllOrder";
  Vue.prototype.alexPost(url, params, success);
}

export function pageMerchantOrderApi(params, success) {
  let url = "/admin/web/order/pageMerchantOrder";
  Vue.prototype.alexPost(url, params, success);
}

export function updateOrderTransformNoApi(params, success) {
  let url = "/admin/web/order/updateOrderTransformNo";
  Vue.prototype.alexPost(url, params, success);
}



export function countMerchantOrderApi(params, success) {
  let url = "/admin/web/order/countMerchantOrder";
  Vue.prototype.alexPost(url, params, success);
}



export function countMoneyMerchantOrderApi(params, success) {
  let url = "/admin/web/order/countMoneyMerchantOrder";
  Vue.prototype.alexPost(url, params, success);
}

export function countProductServiceOrderApi(params, success) {
  let url = "/admin/web/order/countProductServiceOrder";
  Vue.prototype.alexPost(url, params, success);
}

export function countWorkflowDetailOrderApi(params, success) {
  let url = "/admin/web/order/countWorkflowDetailOrder";
  Vue.prototype.alexPost(url, params, success);
}

export function countUserBeginWorkflowDetailOrderApi(params, success) {
  let url = "/admin/web/order/countUserBeginWorkflowDetailOrder";
  Vue.prototype.alexPost(url, params, success);
}

export function countUserWorkflowDetailOrderApi(params, success) {
  let url = "/admin/web/order/countUserWorkflowDetailOrder";
  Vue.prototype.alexPost(url, params, success);
}

// all

export function countAllOrderApi(params, success) {
  let url = "/admin/web/order/countAllOrder";
  Vue.prototype.alexPost(url, params, success);
}

export function countMoneyAllOrderApi(params, success) {
  let url = "/admin/web/order/countMoneyAllOrder";
  Vue.prototype.alexPost(url, params, success);
}

export function countProductServiceAllOrderApi(params, success) {
  let url = "/admin/web/order/countProductServiceAllOrder";
  Vue.prototype.alexPost(url, params, success);
}

export function countWorkflowDetailAllOrderApi(params, success) {
  let url = "/admin/web/order/countWorkflowDetailAllOrder";
  Vue.prototype.alexPost(url, params, success);
}


