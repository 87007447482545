<template>
  <div class="full-container">
    <div
      style="width: 100%;float: left;height: 50px;line-height: 50px;font-size: 0.9rem; text-align: left;opacity: 0.9;">
      农产品
    </div>
    <div style="width:100%;height:auto;float:left;border-radius: 15px;background-color: white;padding-bottom:40px;">

      <div style="width:100%;float:left;margin-top: 10px;">
        <!-- 查询按钮区 -->
        <div style="width: 100%;height: 60px;float: left;line-height: 60px;padding-left: 15px">
          <!-- 左边查询区 -->
          <div style="width: auto;float: left;margin-right: 20px">
            <label style="float: left;font-size: 0.9rem;opacity: 0.9;">商品名称：</label>
            <el-input size="mini" style="width: 200px;float: left;" placeholder="模糊查询" clearable
                      prefix-icon="el-icon-search" v-model="selectForm.name"></el-input>
          </div>
          <div style="width: auto;float: left;margin-right: 20px">
            <label style="float: left;font-size: 0.9rem;opacity: 0.9;">是否上架：</label>
            <el-select size="mini" style="width:100px;float: left;" v-model="selectForm.isShelf" clearable filterable>
              <el-option v-for="item in alexBoolList" :key="item.value" :label="item.zhName" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div style="width: auto;float: left;margin-right: 20px">
            <el-button size="mini" style="background-color: #25AD7F;border:none;" type="primary"
                       @click="pageMerchantProduct(true)">搜索
            </el-button>
            <el-button size="mini" style="background-color: #25AD7F1A;border:1px solid #25AD7F;color:#25AD7F"
                       type="primary" @click="selectReset()">重置
            </el-button>
          </div>
          <!-- 右边按钮 -->
          <div style="width: auto;float: right;margin-right: 20px">

            <el-button size="mini" style="background-color: #25AD7F;border:none;" type="primary" @click="insertShow()">
              <a-icon type="plus-circle" />
              新增产品
            </el-button>
            <el-button size="mini" style="background-color: #25AD7F1A;border:1px solid #25AD7F;color:#25AD7F"
                       type="primary" @click="exportTable">
              <a-icon type="plus-circle" />
              批量导出
            </el-button>

            <a-popconfirm title="是否删除?(敏感操作!)" ok-text="确定" cancel-text="取消"
                          @confirm="deleteProductBatch()">
              <el-button size="mini" style="background-color: #e32e2e1a;color:#e32e2e;border:1px solid #e32e2e;"
                         type="primary">
                <a-icon type="minus-circle" />
                批量删除
              </el-button>
            </a-popconfirm>
          </div>


        </div>

        <!-- 表单内容 -->
        <div style="width:100%;float:left;padding: 15px;">
          <el-table align="center" @selection-change="handleSelectionChange" height="600" :data="tableData"
                    style="color: #555;" size="mini">
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column align="center" prop="goodsTypeStr" label="类型" min-width="20%"></el-table-column>
            <el-table-column align="center"  label="对应类型" min-width="20%">
              <template slot-scope="scope">
                {{scope.row.productTypeStr?scope.row.productTypeStr:''}}
                {{scope.row.workflowTypeStr?scope.row.workflowTypeStr:''}}
              </template>

            </el-table-column>
            <el-table-column align="center" prop="name" label="名称" min-width="20%"></el-table-column>
            <el-table-column align="center" prop="title" label="描述" min-width="20%"></el-table-column>
            <el-table-column align="center" sortable prop="price" label="价格" min-width="20%"></el-table-column>
            <el-table-column align="center" sortable prop="payPrice" label="折后价" min-width="20%"></el-table-column>
            <el-table-column label="是否上架" min-width="20%">
              <template slot-scope="scope">
                <el-switch @change="updateIsShelf(scope.row.id,scope.row.isShelf)" v-model="scope.row.isShelf"
                           active-value="1" inactive-value="0" active-color="#13ce66" inactive-color="#ff4949">
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column align="center" label="商品主图" min-width="20%">
              <template slot-scope="scope">
                <el-image v-if="scope.row.mainImageGuid" title="点击放大"
                          style="width: 100px; height: 100px"
                          :src="'/admin/web/file/downloadFile?id='+scope.row.mainImageGuid"
                          :preview-src-list="['/admin/web/file/downloadFile?id='+scope.row.mainImageGuid]">
                </el-image>
              </template>
            </el-table-column>
            <el-table-column align="center" label="创建时间" min-width="20%">
              <template slot-scope="scope">
                {{ parseDate(scope.row.createDate ? scope.row.createDate : "") }}
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作" min-width="20%">
              <template slot-scope="scope">
                <span style="font-size:0.9rem;color:#25AD7F;cursor: pointer;"
                      @click="detailShow(scope.row)">详情</span>
                <span style="margin:0px 5px">|</span>
                <span style="font-size:0.9rem;cursor: pointer;color: #409EFF"
                      @click="updateShow(scope.row)">修改</span>
                <a-popconfirm title="是否删除?(敏感操作!)" ok-text="确定" cancel-text="取消"
                              @confirm="deleteProduct(scope.row)">
                  <span style="margin:0px 5px">|</span>
                  <span style="font-size:0.9rem;color:#e32e2e;cursor: pointer;">删除</span>
                </a-popconfirm>
              </template>
            </el-table-column>
          </el-table>
          <div style="width: 100%;float: right;margin-top: 10px;text-align: right;">
            <el-pagination
              @current-change="handleCurrentChange"
              :page-size="selectForm.pageSize"
              :current-page="selectForm.pageIndex"
              layout="total,prev, pager, next"
              :total="selectForm.total">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>

    <el-dialog title="新增产品" :visible.sync="insertDialog" width="40%" :before-close="insertCancel">
      <div style="width: 100%;float: left">
        <el-form label-width="15%" style="width:100%;float:left;">

          <el-form-item style="">
            <template slot="label">
              产品类型 <span style="color: red">*</span>
            </template>
            <el-radio  v-model="insertForm.goodsType" label="product">农产品</el-radio>
            <el-radio :disabled="myMerchantInfo.isWorkflow!=1"    v-model="insertForm.goodsType"  label="service">农服务</el-radio>
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>

          <el-form-item style="" v-show="insertForm.goodsType=='product'">
            <template slot="label">
              农产品类型
            </template>
            <el-select size="mini" style="float: left;" v-model="insertForm.productType" clearable filterable>
              <el-option v-for="item in productTypeList" :key="item.value" :label="item.name" :value="item.value">
              </el-option>
            </el-select>
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>

          <el-form-item style="" v-show="insertForm.goodsType=='service'">
            <template slot="label">
              农服务类型
            </template>
            <el-select size="mini" style="float: left;" v-model="insertForm.workflowType" clearable filterable>
              <el-option v-for="item in workflowTypeList" :key="item.value" :label="item.name" :value="item.value">
              </el-option>
            </el-select>
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>

          <el-form-item style="">
            <template slot="label">
              产品名称 <span style="color: red">*</span>
            </template>
            <el-input size="mini" style="width:85%;float: left;" v-model="insertForm.name" placeholder="例如：草施避"
                      oninput="if(value.length>35)value=value.slice(0,35)" />
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>
          <el-form-item style="">
            <template slot="label">
              标题 <span style="color: red">*</span>
            </template>
            <el-input size="mini" style="width:85%;float: left;" v-model="insertForm.title"
                      placeholder="例如：草施避--内吸型灭生性除草剂"
                      oninput="if(value.length>35)value=value.slice(0,35)" />
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>
          <el-form-item style="">
            <template slot="label">
              价格 <span style="color: red">*</span>
            </template>
            <el-input size="mini" style="width:85%;float: left;" v-model="insertForm.price" type="number"
                      class="numberInput" placeholder="例如：9.9"
                      oninput="if(value.length>35)value=value.slice(0,35)" />
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>
          <el-form-item style="">
            <template slot="label">
              折后价 <span style="color: red">*</span>
            </template>
            <el-input size="mini" style="width:85%;float: left;" v-model="insertForm.payPrice" type="number"
                      class="numberInput" placeholder="例如：9.9"
                      oninput="if(value.length>35)value=value.slice(0,35)" />
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>


          <el-form-item style="">
            <template slot="label">
              特色
            </template>
            <el-input size="mini" style="width:85%;float: left;" v-model="insertForm.feature"
                      placeholder="例如：全川代理，市场干净，价格合理，便于推广"
                      oninput="if(value.length>35)value=value.slice(0,35)" />
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>
          <el-form-item style="">
            <template slot="label">
              产品性能
            </template>
            <el-input size="mini" style="width:85%;float: left;" type="textarea"
                      :rows="5" v-model="insertForm.context"
                      placeholder="例如：本品为台沃植保重点推广品种，是内吸传导型灭生性除草剂。为内吸传导型灭生性除草剂。主要通过抑制植物体内烯醇丙酮基莽草素磷酸合成酶，从而抑制莽草素向苯丙氨酸、酪氨酸及色氨酸的转化，使蛋白质的合成受到干扰导致植物死亡。本品以内吸传导性强，它不仅能通过茎叶传导到地下部分，而且在同一植株的不同分蘖间也能进行传导，对多年生深根杂草有效。"
                       />
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>


          <el-form-item style="">
            <template slot="label">
              是否上架 <span style="color: red">*</span>
            </template>
            <el-switch v-model="insertForm.isShelf" :active-value="1" :inactive-value="0" active-color="#13ce66"
                       inactive-color="#ff4949"></el-switch>
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>


          <el-form-item label="">
            <template slot="label">
              缩略图 <span style="color: red">*</span>
            </template>
            <el-upload action="#" list-type="picture-card" :on-change="changeCompanyIdCard"
                       :show-file-list="false" :multiple="false" :auto-upload="false">
              <el-image fit="contain" v-if="mainImageFile" :src="mainImageFile.url" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <div style="width: 15%;float: left;text-indent: 5px;color: red">方形</div>
          </el-form-item>
          <el-form-item label="">
            <template slot="label">
              详细图片<span style="color: red">*</span>
            </template>
            <el-upload :limit=9 action="#" list-type="picture-card" :multiple="true" :on-change="changeChargeUserIdCard"
                       :file-list="detailImageFileList" :auto-upload="false">
              <i slot="default" class="el-icon-plus"></i>
              <div slot="file" slot-scope="{file}">
                <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
                <span class="el-upload-list__item-actions">
                  <span class="el-upload-list__item-preview" @click="detailImageShowDialog(file)">
                    <i class="el-icon-zoom-in"></i>
                  </span>
                  <span class="el-upload-list__item-delete" @click="removeChargeUserIdCard(file)">
                    <i class="el-icon-delete"></i>
                  </span>
                </span>
              </div>
            </el-upload>
            <!-- 大图显示 -->
            <el-dialog :visible.sync="detailImageShow">
              <img width="100%" :src="detailImageShowUrl" alt="">
            </el-dialog>
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>
        </el-form>

      </div>
      <div class="dialog-footer">
        <el-button class="dialog-footer-submit" @click="insertSubmit">提交</el-button>
        <el-button class="dialog-footer-cancel" @click="insertCancel">取消</el-button>
      </div>
    </el-dialog>

    <!-- 详情 -->
    <el-dialog title="产品详情" :visible.sync="detailDialog" width="40%" :before-close="detailCancel">
      <div style="width: 100%;float: left">
        <div
          style="width: 100%;float: left;border-bottom: 1px solid #80808054;padding-bottom: 15px;margin-bottom: 20px;"
          v-if="detailInfo">
          <el-descriptions :title="detailInfo.name?detailInfo.name:''" :column=2>

            <el-descriptions-item label="类型">{{ detailInfo.goodsTypeStr ? detailInfo.goodsTypeStr : ''}}
            </el-descriptions-item>

            <el-descriptions-item  label="对应类型">{{ detailInfo.productTypeStr ? detailInfo.productTypeStr :'' }}{{ detailInfo.workflowTypeStr ? detailInfo.workflowTypeStr : ''}}
            </el-descriptions-item>

            <el-descriptions-item label="标题">{{ detailInfo.title ? detailInfo.title : "" }}
            </el-descriptions-item>
            <el-descriptions-item label="价格">{{ detailInfo.price ? detailInfo.price : "0" }}元
            </el-descriptions-item>
            <el-descriptions-item label="折后价">{{ detailInfo.payPrice ? detailInfo.payPrice : "0" }}元
            </el-descriptions-item>
            <el-descriptions-item label="特色">{{ detailInfo.feature ? detailInfo.feature : "" }}
            </el-descriptions-item>
            <el-descriptions-item label="产品性能">{{ detailInfo.context? detailInfo.context: "" }}
            </el-descriptions-item>




            <el-descriptions-item label="创建时间" :span="2">
              {{ parseDate(detailInfo.createDate ? detailInfo.createDate : "")
              }}
            </el-descriptions-item>
            <el-descriptions-item label="缩略图" :span="2">
              <el-image v-if="detailInfo.mainImageGuid" title="点击放大"
                        style="width: 100px; height: 100px"
                        :src="'/admin/web/file/downloadFile?id='+detailInfo.mainImageGuid"
                        :preview-src-list="['/admin/web/file/downloadFile?id='+detailInfo.mainImageGuid]">
              </el-image>
            </el-descriptions-item>
            <el-descriptions-item label="详细照片" :span="2">
              <div style="width: 100%;float: left;" v-if="detailInfo.detailImageGuid" v-for="guid in detailInfo.detailImageGuid">
                <el-image title="点击放大"
                          style="width: 100px; height: auto"
                          :src="'/admin/web/file/downloadFile?id='+guid"
                          :preview-src-list="['/admin/web/file/downloadFile?id='+guid]">
                </el-image>
                <br />
              </div>

            </el-descriptions-item>
          </el-descriptions>
        </div>

      </div>
      <div class="dialog-footer">
        <el-button class="dialog-footer-cancel" @click="detailCancel">关闭</el-button>
      </div>
    </el-dialog>

    <!-- 修改 -->
    <el-dialog title="修改产品" :visible.sync="updateDialog" width="40%" :before-close="updateCancel">
      <div style="width: 100%;float: left">
        <el-form label-width="15%" style="width:100%;float:left;">


          <el-form-item style="">
            <template slot="label">
              产品类型 <span style="color: red">*</span>
            </template>
            <el-radio  v-model="updateForm.goodsType" label="product">农产品</el-radio>
            <el-radio :disabled="myMerchantInfo.isWorkflow!=1"    v-model="updateForm.goodsType"  label="service">农服务</el-radio>
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>

          <el-form-item style="" v-show="updateForm.goodsType=='product'">
            <template slot="label">
              农产品类型
            </template>
            <el-select size="mini" style="float: left;" v-model="updateForm.productType" clearable filterable>
              <el-option v-for="item in productTypeList" :key="item.value" :label="item.name" :value="item.value">
              </el-option>
            </el-select>
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>

          <el-form-item style="" v-show="updateForm.goodsType=='service'">
            <template slot="label">
              农服务类型
            </template>
            <el-select size="mini" style="float: left;" v-model="updateForm.workflowType" clearable filterable>
              <el-option v-for="item in workflowTypeList" :key="item.value" :label="item.name" :value="item.value">
              </el-option>
            </el-select>
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>

          <el-form-item style="">
            <template slot="label">
              产品名称 <span style="color: red">*</span>
            </template>
            <el-input size="mini" style="width:85%;float: left;" v-model="updateForm.name" placeholder="例如：草施避"
                      oninput="if(value.length>35)value=value.slice(0,35)" />
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>
          <el-form-item style="">
            <template slot="label">
              标题 <span style="color: red">*</span>
            </template>
            <el-input size="mini" style="width:85%;float: left;" v-model="updateForm.title"
                      placeholder="例如：草施避--内吸型灭生性除草剂"
                      oninput="if(value.length>35)value=value.slice(0,35)" />
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>
          <el-form-item style="">
            <template slot="label">
              价格 <span style="color: red">*</span>
            </template>
            <el-input size="mini" style="width:85%;float: left;" v-model="updateForm.price" type="number"
                      class="numberInput" placeholder="例如：9.9"
                      oninput="if(value.length>35)value=value.slice(0,35)" />
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>
          <el-form-item style="">
            <template slot="label">
              折后价 <span style="color: red">*</span>
            </template>
            <el-input size="mini" style="width:85%;float: left;" v-model="updateForm.payPrice" type="number"
                      class="numberInput" placeholder="例如：9.9"
                      oninput="if(value.length>35)value=value.slice(0,35)" />
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>


          <el-form-item style="">
            <template slot="label">
              特色
            </template>
            <el-input size="mini" style="width:85%;float: left;" v-model="updateForm.feature"
                      placeholder="例如：全川代理，市场干净，价格合理，便于推广"
                      oninput="if(value.length>35)value=value.slice(0,35)" />
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>
          <el-form-item style="">
            <template slot="label">
              产品性能
            </template>
            <el-input size="mini" style="width:85%;float: left;" type="textarea"
                      :rows="5" v-model="updateForm.context"
                      placeholder="例如：本品为台沃植保重点推广品种，是内吸传导型灭生性除草剂。为内吸传导型灭生性除草剂。主要通过抑制植物体内烯醇丙酮基莽草素磷酸合成酶，从而抑制莽草素向苯丙氨酸、酪氨酸及色氨酸的转化，使蛋白质的合成受到干扰导致植物死亡。本品以内吸传导性强，它不仅能通过茎叶传导到地下部分，而且在同一植株的不同分蘖间也能进行传导，对多年生深根杂草有效。"
                       />
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>

          <el-form-item style="">
            <template slot="label">
              是否上架 <span style="color: red">*</span>
            </template>
            <el-switch v-model="updateForm.isShelf" active-value="1" inactive-value="0" active-color="#13ce66"
                       inactive-color="#ff4949"></el-switch>
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>

          <el-form-item label="">
            <template slot="label">
              缩略图 <span style="color: red">*</span>
            </template>
            <el-upload action="#" list-type="picture-card" :on-change="updateChangeCompanyIdCard"
                       :show-file-list="false" :multiple="false" :auto-upload="false">
              <el-image ref="mainref" fit="contain" v-if="updateMainImageFileUrl" :src="updateMainImageFileUrl"
                        class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>
          <el-form-item label="">
            <template slot="label">
              详细图片<span style="color: red">*</span>
            </template>
            <el-upload :limit=9 action="#" list-type="picture-card" :multiple="true"
                       :on-change="updateChangeChargeUserIdCard"
                       :file-list="updateDetailImageFileListUrl" :auto-upload="false">
              <i slot="default" class="el-icon-plus"></i>
              <div slot="file" slot-scope="{file}">
                <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
                <span class="el-upload-list__item-actions">
                  <span class="el-upload-list__item-preview" @click="updateDetailImageShowDialog(file)">
                    <i class="el-icon-zoom-in"></i>
                  </span>
                  <span class="el-upload-list__item-delete" @click="updateRemoveChargeUserIdCard(file)">
                    <i class="el-icon-delete"></i>
                  </span>
                </span>
              </div>
            </el-upload>
            <!-- 大图显示 -->
            <el-dialog :visible.sync="updateDetailImageShow">
              <img width="100%" :src="updateDetailImageShowUrl" alt="">
            </el-dialog>
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>
        </el-form>

      </div>
      <div class="dialog-footer">
        <el-button class="dialog-footer-submit" @click="updateSubmit">提交</el-button>
        <el-button class="dialog-footer-cancel" @click="updateCancel">取消</el-button>
      </div>
    </el-dialog>


  </div>
</template>

<script>


import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { getAlexBoolListApi,listProductTypeApi,listGoodsTypeApi,listWorkflowTypeApi } from "@/router/api/common/data-api";
import { pageMerchantProductApi, deleteProductApi,updateIsShelfApi } from "@/router/api/product/product-api";
import { getMyMerchantApi } from "@/router/api/merchant/merchant-api";

export default {
  name: "MerchantProduct",
  data() {
    return {
      alexBoolList: [],
      productTypeList:[],
      workflowTypeList:[],
      myMerchantInfo:{},

      // 查询
      tableData: [],
      selectForm: {
        name: null,
        isShelf: null,
        pageIndex: 1,
        pageSize: 10,
        total: 0
      },
      selectTableRow: [],

      //新增
      insertDialog: false,
      insertForm: {
        goodsType:"product",
        productType:null,
        workflowType:null,
        name: null,
        title: null,

        feature: null,
        context: null,

        price: null,
        payPrice: null,
        isShelf: 1
      },
      mainImageFile: null,
      detailImageFileList: [],
      detailImageShow: false,//是否显示大图
      detailImageShowUrl: "",//大图的url

      //  详情
      detailDialog: false,
      detailInfo: null,

      //修改
      updateDialog: false,
      updateForm: {
        id: null,

        goodsType:null,
        productType:null,
        workflowType:null,

        name: null,
        title: null,

        feature: null,
        context: null,

        price: null,
        payPrice: null,
        isShelf: null
      },
      updateMainImageFile: null,
      updateMainImageFileUrl: null,
      updateDetailImageFileListUrl: [],
      updateDetailImageShow: false,//是否显示大图
      updateDetailImageShowUrl: ""//大图的url
    };
  },

  mounted() {

    this.load();

  },

  methods: {

    load() {

      this.getMyMerchant();

      getAlexBoolListApi({}, res => {
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.alexBoolList = res.data.resultSetData;
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });

      listProductTypeApi({}, res => {
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.productTypeList = res.data.resultSetData;
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });

      listWorkflowTypeApi({}, res => {
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.workflowTypeList = res.data.resultSetData;
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });

      this.pageMerchantProduct();
    },

    getMyMerchant(showMessage) {
      let params = {};
      getMyMerchantApi(params, res => {
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              if (showMessage) {
                this.showMessage(res.data.msg, "success");
              }
              this.myMerchantInfo = res.data.resultSetData;
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });
    },

    // 查询 begin
    selectReset() {
      this.selectForm = {
        name: null,
        isShelf: null,
        pageIndex: 1,
        pageSize: 10,
        total: 0
      };
      this.pageMerchantProduct(true);
    },

    handleCurrentChange(e) {
      this.selectForm.pageIndex = e;
      this.pageMerchantProduct(true);
    },

    handleSelectionChange(val) {
      this.selectTableRow = val;
    },

    pageMerchantProduct(showMessage) {
      pageMerchantProductApi(this.selectForm, res => {
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              if (showMessage) {
                this.showMessage(res.data.msg, "success");
              }
              this.tableData = res.data.resultSetData.list;
              this.selectForm.pageIndex = res.data.resultSetData.pageNum;
              this.selectForm.pageSize = res.data.resultSetData.pageSize;
              this.selectForm.total = res.data.resultSetData.total;
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });
    },

    exportTable() {
      if (!this.selectTableRow || this.selectTableRow.length == 0) {
        this.showMessage("请勾选需要导出的数据", "warning");
        return false;
      }

      const data = [
        [
          "名称",
          "标题",
          "特色",
          "产品性能",
          "价格",
          "折后价",
          "是否上架",
      ]
        ]

      //添加数据

      for (let i = 0; i < this.selectTableRow.length; i++) {
        let row = this.selectTableRow[i];
        if (row) {
          let cell1 = row.name ? row.name: "";
          let cell2 = row.title ? row.title: "";
          let cell5 = row.feature ? row.feature: "";
          let cell6 = row.context ? row.context: "";
          let cell12 = row.price ? row.price: "";
          let cell15 = row.payPrice ? row.payPrice: "";
          let cell13 = row.isShelf ? row.isShelf: "";

          data.push([cell1, cell2,   cell5,cell6,cell12,cell15, cell13]);
        }
      }
      // 将数据转换为工作表
      const worksheet = XLSX.utils.aoa_to_sheet(data);

      // 创建工作簿并添加工作表
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      // 生成Excel文件
      const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

      // 使用blob和FileReader创建一个Blob URL
      const dataBlob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });
      const blobUrl = window.URL.createObjectURL(dataBlob);

      // 使用saveAs下载文件
      saveAs(dataBlob, "商户用户-" + new Date().toLocaleString() + ".xlsx");

      // 清理
      window.URL.revokeObjectURL(blobUrl);
    },

    // 查询 end


    // 新增 begin

    insertShow() {
      this.insertDialog = true;
    },

    insertCancel() {
      this.insertDialog = false;
      this.insertForm = {

        goodsType:null,
        productType:null,
        workflowType:null,

        name: null,
        title: null,

        feature: null,
        context: null,

        price: null,
        payPrice: null,
        isShelf: 1
      };
      this.mainImageFile = null;
      this.detailImageFileList = [];
      this.detailImageShow = false;
      this.detailImageShowUrl = "";
    },


    // 营业执照上传
    changeCompanyIdCard(file) {
      this.mainImageFile = file;
    },

    //身份证上传
    changeChargeUserIdCard(file, fileList) {
      this.detailImageFileList = fileList;
    },

    removeChargeUserIdCard(file) {
      for (let item in this.detailImageFileList) {
        if (item && this.detailImageFileList[item] && file && this.detailImageFileList[item].uid == file.uid) {
          this.detailImageFileList.splice(item, 1);
        }
      }
    },

    detailImageShowDialog(e) {
      this.detailImageShowUrl = e.url;
      this.detailImageShow = true;
    },


    insertSubmit() {

      if (!this.insertForm.goodsType) {
        this.showMessage("请选择产品类型", "error");
        return false;
      }

      //TODO 非空判断
      if (!this.insertForm.name) {
        this.showMessage("请填写产品名称", "error");
        return false;
      }
      if (!this.insertForm.title) {
        this.showMessage("请填写产品标题", "error");
        return false;
      }
      if (!this.insertForm.price) {
        this.showMessage("请输入产品价格", "error");
        return false;
      } if (!this.insertForm.payPrice) {
        this.showMessage("请输入产品折后价", "error");
        return false;
      }

      //数据组装
      let params = new FormData();
      params.append("name", this.insertForm.name);
      params.append("title", this.insertForm.title);
      params.append("goodsType", this.insertForm.goodsType);

      if(this.insertForm.productType){
        params.append("productType", this.insertForm.productType);
      }

      if(this.insertForm.workflowType){
        params.append("workflowType", this.insertForm.workflowType);
      }

      params.append("feature", this.insertForm.feature);
      params.append("context", this.insertForm.context);
      params.append("price", this.insertForm.price);
      params.append("payPrice", this.insertForm.payPrice);
      params.append("isShelf", this.insertForm.isShelf);

      if (this.mainImageFile) {
        params.append("mainImageMultipartFile", this.mainImageFile.raw);
      } else {
        this.showMessage("请上传缩略图", "error");
        return false;
      }

      if (this.detailImageFileList && this.detailImageFileList.length > 0) {
        for (let i = 0; i < this.detailImageFileList.length; i++) {
          let temp = this.detailImageFileList[i].raw;
          params.append("detailImageMultipartFile[]", temp);
        }
      } else {
        this.showMessage("请上传详细照片", "error");
        return false;
      }

      let url = "/admin/web/product/insertProduct";
      this.alexPost(url, params, res => {
        //状态200
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.showMessage(res.data.msg, "success");
              this.insertCancel();
              this.pageMerchantProduct();
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      }, {
        headers: {
          "Content-Type": "multipart/form-data",
          "token": localStorage.getItem("token")
        }
      });
    },
    // 新增 end

    // 详情 begin
    detailShow(data) {
      this.detailInfo = data;
      this.detailDialog = true;
    },

    detailCancel() {
      this.detailDialog = false;
      this.detailInfo = null;
    },
    // 详情 end


    // 删除 begin
    deleteProduct(item) {
      if (!item) {
        this.showMessage("请选择需要删除的产品", "warning");
        return false;
      }
      let params = [
        {
          "id": item.id
        }
      ];
      deleteProductApi(params, res => {
        //状态200
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.showMessage(res.data.msg, "success");
              this.pageMerchantProduct();
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });


    },

    deleteProductBatch() {

      if (!this.selectTableRow || this.selectTableRow.length == 0) {
        this.showMessage("请勾选需要需要删除的产品", "warning");
        return false;
      }

      let params = [];
      for (let i = 0; i < this.selectTableRow.length; i++) {
        let row = this.selectTableRow[i];
        if (row) {
          params.push({
            "id": row.id
          });
        }
      }

      deleteProductApi(params, res => {
        //状态200
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.showMessage(res.data.msg, "success");
              this.pageMerchantProduct();
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });


    },

    // 删除 end

    // 修改 begin

    updateIsShelf(id, isShelf) {
      let params = {
        "id": id,
        "isShelf": isShelf
      };
      updateIsShelfApi(params, res => {
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.showMessage(res.data.msg, "success");
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });
    },

    updateShow(data) {
      this.updateDialog = true;
      this.updateForm = {
        id: data.id,
        name: data.name,
        title: data.title,

        goodsType: data.goodsType,
        productType: data.productType,
        workflowType: data.workflowType,



        feature:data.feature,
        context: data.context,

        price: data.price,
        payPrice: data.payPrice,
        isShelf:data.isShelf,
      };


      this.updateMainImageFileUrl = "/admin/web/file/downloadFile?id=" + data.mainImageGuid;//缩略图返显 上传
      this.updateDetailImageFileListUrl = [];//详情页 图片数组
      if (data.detailImageGuid && data.detailImageGuid.length > 0) {
        for (let i = 0; i < data.detailImageGuid.length; i++) {
          let guid = data.detailImageGuid[i];
          this.updateDetailImageFileListUrl.push({
            name: guid,
            url: "/admin/web/file/downloadFile?id=" + guid
          });
        }
      }
    },


    updateCancel() {
      this.updateDialog = false;
      this.updateForm = {
        id: null,

        goodsType: null,
        productType: null,
        workflowType: null,

        name: null,
        title: null,

        feature: null,
        context: null,

        price: null,
        payPrice: null,
        isShelf: null
      };
      this.updateMainImageFile = null;
      this.updateMainImageFileUrl = null; //返现

      this.updateDetailImageShow = false;
      this.updateDetailImageShowUrl = "";
      this.updateDetailImageFileListUrl = [];
    },


    // 营业执照上传
    updateChangeCompanyIdCard(file) {
      this.updateMainImageFile = file;
      this.updateMainImageFileUrl = file.url;
    },

    //身份证上传
    updateChangeChargeUserIdCard(file, fileList) {
      this.updateDetailImageFileListUrl = fileList;
    },

    updateRemoveChargeUserIdCard(file) {
      for (let item in this.updateDetailImageFileListUrl) {
        if (item && this.updateDetailImageFileListUrl[item] && file && this.updateDetailImageFileListUrl[item].uid == file.uid) {
          this.updateDetailImageFileListUrl.splice(item, 1);
        }
      }
    },

    updateDetailImageShowDialog(e) {
      this.updateDetailImageShowUrl = e.url;
      this.updateDetailImageShow = true;
    },


    updateSubmit() {

      //TODO 非空判断
      if (!this.updateForm.name) {
        this.showMessage("请填写产品名称", "error");
        return false;
      }

      if (!this.updateForm.goodsType) {
        this.showMessage("请选择产品类型", "error");
        return false;
      }

      if (!this.updateForm.title) {
        this.showMessage("请填写产品标题", "error");
        return false;
      }
      if (!this.updateForm.price) {
        this.showMessage("请输入产品价格", "error");
        return false;
      }
      if (!this.updateForm.payPrice) {
        this.showMessage("请输入产品折后价", "error");
        return false;
      }

      //数据组装
      let params = new FormData();
      params.append("id", this.updateForm.id);
      params.append("name", this.updateForm.name);
      params.append("title", this.updateForm.title);

      params.append("goodsType", this.updateForm.goodsType);

      if(this.updateForm.productType){
        params.append("productType", this.updateForm.productType);
      }
      if(this.updateForm.workflowType){
        params.append("workflowType", this.updateForm.workflowType);
      }

      params.append("feature", this.updateForm.feature);
      params.append("context", this.updateForm.context);

      params.append("price", this.updateForm.price);
      params.append("payPrice", this.updateForm.payPrice);
      params.append("isShelf", this.updateForm.isShelf);

      if (this.updateMainImageFile) {
        params.append("mainImageMultipartFile", this.updateMainImageFile.raw);
      }

      if (this.updateDetailImageFileListUrl && this.updateDetailImageFileListUrl.length > 0) {
        for (let i = 0; i < this.updateDetailImageFileListUrl.length; i++) {
          let temp = this.updateDetailImageFileListUrl[i];
          if (temp) {
            if (temp.status == "success") {
              params.append("detailImageListFileGuid[]", temp.name);
            }
            if (temp.status == "ready") {
              params.append("detailImageMultipartFile[]", temp.raw);
            }
          }
        }
      }

      let url = "/admin/web/product/updateProduct";
      this.alexPost(url, params, res => {
        //状态200
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.showMessage(res.data.msg, "success");
              this.updateCancel();
              this.pageMerchantProduct();
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      }, {
        headers: {
          "Content-Type": "multipart/form-data",
          "token": localStorage.getItem("token")
        }
      });
    },

    // 修改 end

    // 工具
    parseDate(date) {
      if (date) {
        return new Date(date).toLocaleString();
      }
      return "";
    }
  }


}
;

</script>


<style scoped>

/deep/ .el-card__body, .el-main {
  float: left;
  width: 100%;
}

/*  通用 */
.dialog-footer {
  width: 100%;
  float: left;
  text-align: left;
}

.dialog-footer-submit {
  background-color: #25AD7F;
  color: white;
  width: 130px;
  border-radius: 50px;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 100;
}

.dialog-footer-submit:hover {
  background-color: #25AD7F;
  color: white;
  width: 130px;
  border-radius: 50px;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 100;
}

.dialog-footer-cancel {
  background-color: white;
  border: 1px solid #8080805c;
  color: #8080805c;
  width: 130px;
  border-radius: 50px;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 100;
}

.dialog-footer-cancel:hover {
  background-color: white;
  border: 1px solid #8080805c;
  color: #8080805c;
  width: 130px;
  border-radius: 50px;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 100;
}

/* 树形菜单 */

/deep/ .el-tree-node__content {
  height: 35px;
  line-height: 35px;
}

/* 表格 */

/deep/ .el-table th.el-table__cell {
  background-color: #F8F8F8;
}

/deep/ .el-table th.el-table__cell > .cell {
  color: #554 !important;
  font-weight: 600;
  font-size: 1.05rem;
}


/* 模态框 */

/deep/ .el-dialog__title {
  font-weight: 600;
}

/deep/ .el-dialog__header {
  text-align: left;
  border-bottom: 1px solid darkgray;
}

/deep/ .el-dialog__body {
  width: 100%;
  float: left;
  background-color: white;
}

/deep/ .el-upload-dragger {
  height: auto;
}

/deep/ .el-form-item__content {
  text-align: left;
}


/* 纯数字 */
/deep/ .numberInput input::-webkit-outer-spin-button,
/deep/ .numberInput input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

/deep/ .numberInput input[type="number"] {
  -moz-appearance: textfield;
}

</style>
