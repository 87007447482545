<template>
  <div class="full-container">
    <div
      style="width: 100%;float: left;height: 50px;line-height: 50px;font-size: 0.9rem; text-align: left;opacity: 0.9;">
      商户设置>资质管理
    </div>
    <div style="width:100%;height:auto;float:left;border-radius: 15px;background-color: white;padding-bottom:40px;">

      <div style="width:100%;float:left;margin-top: 10px;">

        <!-- 表单内容 -->
        <div style="width:100%;float:left;padding: 30px;">
          <el-descriptions title="主体信息" >
            <el-descriptions-item label="企业名称" >{{ merchantInfo.merchantDetailVO.companyName }}</el-descriptions-item>
            <el-descriptions-item label="企业地址">{{ merchantInfo.merchantDetailVO.companyAddress }}</el-descriptions-item>
            <el-descriptions-item label="统一社会信用代码" >{{ merchantInfo.merchantDetailVO.companyIdCard }}</el-descriptions-item>
            <el-descriptions-item label="营业执照" >
              <el-image v-if="merchantInfo.merchantDetailVO.companyIdCardGuid" title="点击放大"
                        style="width: 100px; height: 100px"
                        :src="'/admin/web/file/downloadFile?id='+merchantInfo.merchantDetailVO.companyIdCardGuid"
                        :preview-src-list="['/admin/web/file/downloadFile?id='+merchantInfo.merchantDetailVO.companyIdCardGuid]">
              </el-image>
            </el-descriptions-item>
          </el-descriptions>
          <el-descriptions style="margin-top: 30px;" title="负责人" >
            <el-descriptions-item label="姓名">{{ merchantInfo.merchantDetailVO.chargeUserName }}</el-descriptions-item>
            <el-descriptions-item label="身份证号码">{{ merchantInfo.merchantDetailVO.chargeUserIdCard }}</el-descriptions-item>
            <el-descriptions-item label="手机号码">{{ merchantInfo.merchantDetailVO.chargeUserTel }}</el-descriptions-item>
            <el-descriptions-item label="邮箱" :span="3">{{ merchantInfo.merchantDetailVO.chargeUserMail }}</el-descriptions-item>
            <el-descriptions-item label="身份证（正反面）">
              <el-image v-if="merchantInfo.merchantDetailVO.chargeUserIdCardGuid" v-for="guid in merchantInfo.merchantDetailVO.chargeUserIdCardGuid" title="点击放大"
                        style="width: 100px; height: 100px"
                        :src="'/admin/web/file/downloadFile?id='+guid"
                        :preview-src-list="['/admin/web/file/downloadFile?id='+guid]">
              </el-image>
            </el-descriptions-item>
          </el-descriptions>

        </div>

      </div>
    </div>


  </div>
</template>

<script>

import { getMyMerchantApi } from "@/router/api/merchant/merchant-api";


export default {
  name: "MerchantSetting",
  data() {
    return {

      //查询
      merchantInfo: null,
    };
  },

  mounted() {

    this.load();


  },

  methods: {

    load() {
      this.getMyMerchant();
    },


    getMyMerchant(showMessage) {
      //查询前先重置
      this.merchantInfo = {};
      getMyMerchantApi(this.selectForm, res => {
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              if (showMessage) {
                this.showMessage(res.data.msg, "success");
              }
              this.merchantInfo = res.data.resultSetData;
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });
    },


    // 工具
    parseDate(date) {
      if (date) {
        return new Date(date).toLocaleString();
      }
      return "";
    }
  }

};

</script>


<style scoped>

.dialog-footer {
  width: 100%;
  float: left;
  text-align: left;
}

.dialog-footer-submit {
  background-color: #25AD7F;
  color: white;
  width: 130px;
  border-radius: 50px;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 100;
}

.dialog-footer-submit:hover {
  background-color: #25AD7F;
  color: white;
  width: 130px;
  border-radius: 50px;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 100;
}

.dialog-footer-cancel {
  background-color: white;
  border: 1px solid #8080805c;
  color: #8080805c;
  width: 130px;
  border-radius: 50px;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 100;
}

.dialog-footer-cancel:hover {
  background-color: white;
  border: 1px solid #8080805c;
  color: #8080805c;
  width: 130px;
  border-radius: 50px;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 100;
}

/* 树形菜单 */

/deep/ .el-tree-node__content {
  height: 35px;
  line-height: 35px;
}

/* 表格 */

/deep/ .el-table th.el-table__cell {
  background-color: #F8F8F8;
}

/deep/ .el-table th.el-table__cell > .cell {
  color: #554 !important;
  font-weight: 600;
  font-size: 1.05rem;
}


/* 模态框 */

/deep/ .el-dialog__title {
  font-weight: 600;
}

/deep/ .el-dialog__header {
  text-align: left;
  border-bottom: 1px solid darkgray;
}

/deep/ .el-dialog__body {
  width: 100%;
  float: left;
  background-color: white;
}

/deep/ .el-upload-dragger {
  height: auto;
}

/deep/ .el-form-item__content {
  text-align: left;
}

</style>
