import Vue from "vue";

export function getUserAuthMenuApi(params, success) {
    let url = "/admin/web/authMenu/getUserAuthMenu";
    Vue.prototype.alexPost(url, params, success);
}

export function treeAllAuthMenuApi(params, success) {
    let url = "/admin/web/authMenu/treeAllAuthMenu";
    Vue.prototype.alexPost(url, params, success);
}

export function insertAuthMenuApi(params, success) {
    let url = "/admin/web/authMenu/insertAuthMenu";
    Vue.prototype.alexPost(url, params, success);
}

export function updateAuthMenuApi(params, success) {
    let url = "/admin/web/authMenu/updateAuthMenu";
    Vue.prototype.alexPost(url, params, success);
}

export function deleteAuthMenuApi(params, success) {
    let url = "/admin/web/authMenu/deleteAuthMenu";
    Vue.prototype.alexPost(url, params, success);
}
