<template>
  <div class="full-container">
    <div
      style="width: 100%;float: left;height: 50px;line-height: 50px;font-size: 0.9rem; text-align: left;opacity: 0.9;">
      审核
    </div>
    <div style="width:100%;height:auto;float:left;border-radius: 15px;background-color: white;padding-bottom:40px;">

      <div style="width:100%;float:left;margin-top: 10px;">
        <!-- 查询按钮区 -->
        <div style="width: 100%;height: 60px;float: left;line-height: 60px;padding-left: 15px">
          <div style="width: auto;float: left;margin-right: 20px">
            <label style="float: left;font-size: 0.9rem;opacity: 0.9;">审核类型：</label>
            <el-select size="mini" style="float: left;" v-model="selectForm.approveType" clearable filterable>
              <el-option v-for="item in approveTypeList" :key="item.value" :label="item.name" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div style="width: auto;float: left;margin-right: 20px">
            <label style="float: left;font-size: 0.9rem;opacity: 0.9;">审核状态：</label>
            <el-select size="mini" style="float: left;" v-model="selectForm.approveStatus" clearable filterable>
              <el-option v-for="item in approveStatusList" :key="item.value" :label="item.name" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div style="width: auto;float: left;margin-right: 20px">
            <label style="float: left;font-size: 0.9rem;opacity: 0.9;">审核单号：</label>
            <el-input size="mini" style="width: 200px;float: left;" placeholder="模糊查询" clearable
                      prefix-icon="el-icon-search" v-model="selectForm.approveNo"></el-input>
          </div>


          <div style="width: auto;float: left;margin-right: 20px">
            <el-button size="mini" style="background-color: #25AD7F;border:none;" type="primary"
                       @click="pageApprove(true)">搜索
            </el-button>
            <el-button size="mini" style="background-color: #25AD7F1A;border:1px solid #25AD7F;color:#25AD7F"
                       type="primary" @click="selectReset()">重置
            </el-button>
          </div>
          <!-- 右边按钮 -->
          <div style="width: auto;float: right;margin-right: 20px">
          </div>
        </div>

        <!-- 表单内容 -->
        <div style="width:100%;float:left;padding: 15px;">
          <el-table align="center" height="600" :data="tableData" style="color: #555;" size="mini">
            <el-table-column align="center" prop="approveTypeStr" label="审核类型" min-width="20%"> </el-table-column>
            <el-table-column align="center" sortable prop="approveNo"  label="审核编号" min-width="20%" style="color: red"></el-table-column>
            <el-table-column align="center" prop="approveStatusStr" label="审核状态" min-width="20%"></el-table-column>
            <el-table-column align="center" prop="approveReason" label="审核原因" min-width="20%"></el-table-column>
            <el-table-column align="center"  label="创建时间" min-width="20%">
              <template slot-scope="scope">
                {{ parseDate(scope.row.createDate) }}
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作" min-width="20%">
              <template slot-scope="scope">
                <span style="font-size:1rem;color:#25AD7F;cursor: pointer;"  @click="auditShow(scope.row)">审核</span> &nbsp;
                <span style="font-size:1rem;color:#66b1ff;cursor: pointer;"  @click="recordShow(scope.row)">记录</span>
              </template>
            </el-table-column>

          </el-table>
          <div style="width: 100%;float: right;margin-top: 10px;text-align: right;">
            <el-pagination
              @current-change="handleCurrentChange"
              :page-size="selectForm.pageSize"
              :current-page="selectForm.pageIndex"
              layout="total,prev, pager, next"
              :total="selectForm.total">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>

    <el-dialog title="审核" :visible.sync="auditDialog" width="40%" :before-close="auditCancel">
      <div style="width: 100%;float: left">
        <div style="width: 100%;float: left;border-bottom: 1px solid #80808054;padding-bottom: 15px;margin-bottom: 20px;"  v-if="auditInfo&&auditInfo.merchantDetailVO">
          <el-descriptions title="主体信息" :column=2>
            <el-descriptions-item label="企业名称" >{{ auditInfo.merchantDetailVO.companyName }}</el-descriptions-item>
            <el-descriptions-item label="企业地址">{{ auditInfo.merchantDetailVO.companyAddress }}</el-descriptions-item>
            <el-descriptions-item label="统一社会信用代码">{{ auditInfo.merchantDetailVO.companyIdCard }}</el-descriptions-item>
            <el-descriptions-item label="是否具备流程服务">{{ auditInfo.merchantDetailVO.isWorkflowStr }}</el-descriptions-item>
            <el-descriptions-item label="营业执照" >
              <el-image v-if="auditInfo.merchantDetailVO.companyIdCardGuid" title="点击放大"
                        style="width: 100px; height: 100px"
                        :src="'/admin/web/file/downloadFile?id='+auditInfo.merchantDetailVO.companyIdCardGuid"
                        :preview-src-list="['/admin/web/file/downloadFile?id='+auditInfo.merchantDetailVO.companyIdCardGuid]">
              </el-image>
            </el-descriptions-item>
          </el-descriptions>
          <el-descriptions title="负责人" :column=2>
            <el-descriptions-item label="姓名">{{ auditInfo.merchantDetailVO.chargeUserName }}</el-descriptions-item>
            <el-descriptions-item label="身份证号码">{{ auditInfo.merchantDetailVO.chargeUserIdCard }}</el-descriptions-item>
            <el-descriptions-item label="手机号码">{{ auditInfo.merchantDetailVO.chargeUserTel }}</el-descriptions-item>
            <el-descriptions-item label="邮箱">{{ auditInfo.merchantDetailVO.chargeUserMail }}</el-descriptions-item>
            <el-descriptions-item label="身份证（正反面）">
              <el-image v-if="auditInfo.merchantDetailVO.chargeUserIdCardGuid" v-for="guid in auditInfo.merchantDetailVO.chargeUserIdCardGuid" title="点击放大"
                        style="width: 100px; height: 100px"
                        :src="'/admin/web/file/downloadFile?id='+guid"
                        :preview-src-list="['/admin/web/file/downloadFile?id='+guid]">
              </el-image>
            </el-descriptions-item>
          </el-descriptions>
        </div>

        <el-form label-width="15%" style="width: 100%;float:left;">
          <el-form-item>
            <template slot="label">
              审核结果
            </template>
            <el-select size="mini" style="float: left;" v-model="auditForm.approveStatus" clearable filterable>
              <el-option v-for="item in approveStatusList" :key="item.value" :label="item.name" :value="item.value">
              </el-option>
            </el-select>
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>
          <el-form-item >
            <template slot="label">
              审核原因
            </template>
            <el-input size="mini" style="width:85%;float: left;" v-model="auditForm.approveReason" />
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>
        </el-form>
      </div>
      <div class="dialog-footer">
        <el-button class="dialog-footer-submit" @click="auditSubmit">提交审核</el-button>
        <el-button class="dialog-footer-cancel" @click="auditCancel">取消</el-button>
      </div>
    </el-dialog>

    <el-dialog title="审核记录" :visible.sync="recordDialog" width="40%" :before-close="recordCancel">
      <div style="width: 100%;float: left">
        <div style="width: 100%;float: left;border-bottom: 1px solid #80808054;padding-bottom: 15px;margin-bottom: 20px;"  v-if="recordInfo&&recordInfo.approveRecordVOList">
          <el-timeline :reverse="true" style="text-align: left;">
            <el-timeline-item
              v-for="(record, index) in recordInfo.approveRecordVOList"
              :key="index"
              :timestamp="parseDate(record.createDate)">
              {{record.approveStatusStr + '：'+ record.approveReason}}
            </el-timeline-item>
          </el-timeline>

        </div>

      </div>
      <div class="dialog-footer">
        <el-button class="dialog-footer-cancel" @click="recordCancel">关闭</el-button>
      </div>
    </el-dialog>


  </div>
</template>

<script>

import {
  listApproveTypeApi,
  listApproveStatusApi
} from "@/router/api/common/data-api";


import { auditApi,pageApproveApi } from "@/router/api/approve/approve-api";



export default {
  name: "ApproveIndex",
  data() {
    return {

      platformList: [],
      approveTypeList: [],
      approveStatusList: [],

      //查询
      tableData:[],
      selectForm: {
        approveNo: null,
        approveType: null,
        approveStatus: null,
        pageIndex: 1,
        pageSize: 10,
        total: 0
      },

      //审核
      auditDialog:false,
      auditInfo:null,
      auditForm:{
        id:null,
        approveStatus:null,
        approveReason:null,
      },

      //审核记录
      recordDialog:false ,
      recordInfo:null,
    };
  },

  mounted() {

    this.load();


  },

  methods: {

    load() {
      this.pageApprove();


      listApproveTypeApi({}, res => {
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.approveTypeList = res.data.resultSetData;
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });

      listApproveStatusApi({}, res => {
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.approveStatusList = res.data.resultSetData;
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });

    },

    // 查询
    selectReset(){
      this.selectForm = {
        approveNo: null,
        approveType: null,
        approveStatus: null,
        pageIndex: 1,
        pageSize: 10,
        total: 0
      }
      this.pageApprove(true);
    },

    handleCurrentChange(e) {
      this.selectForm.pageIndex = e;
      this.pageApprove(true);
    },

    pageApprove(showMessage){

      pageApproveApi(this.selectForm, res => {
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              if (showMessage) {
                this.showMessage(res.data.msg, "success");
              }
              this.tableData = res.data.resultSetData.list;
              this.selectForm.pageIndex = res.data.resultSetData.pageNum;
              this.selectForm.pageSize = res.data.resultSetData.pageSize;
              this.selectForm.total = res.data.resultSetData.total;

            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });
    },

    //审核
    auditShow(data) {
      this.auditDialog = true;
      this.auditInfo = data;
      this.auditForm.id = data.id;
    },

    auditCancel() {
      this.auditDialog = false;
      this.auditInfo = null;
      this.auditForm = {
        id:null,
        approveStatus:null,
        approveReason:null,
      };
    },

    auditSubmit() {

      if(!this.auditForm.approveStatus){
        this.showMessage("请填写审核结果","warning");
        return false;
      }
      if(!this.auditForm.approveReason){
        this.showMessage("请填写审核原因","warning");
        return false;
      }

      let params = this.auditForm;
      auditApi(params, res => {
        //状态200
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.showMessage(res.data.msg, "success");
              this.pageApprove();
              this.auditCancel();
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });

    },


    // 记录
    recordShow (data){
      this.recordDialog = true;
      this.recordInfo = data;

    },

    recordCancel(){
      this.recordDialog = false;
      this.recordInfo = null;
    },

    // 工具

    parseDate(date) {
      if (date) {
        return new Date(date).toLocaleString();
      }
      return "";
    }



  }

};

</script>


<style scoped>

.dialog-footer {
  width: 100%;
  float: left;
  text-align: left;
}

.dialog-footer-submit {
  background-color: #25AD7F;
  color: white;
  width: 130px;
  border-radius: 50px;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 100;
}

.dialog-footer-submit:hover {
  background-color: #25AD7F;
  color: white;
  width: 130px;
  border-radius: 50px;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 100;
}

.dialog-footer-cancel {
  background-color: white;
  border: 1px solid #8080805c;
  color: #8080805c;
  width: 130px;
  border-radius: 50px;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 100;
}

.dialog-footer-cancel:hover {
  background-color: white;
  border: 1px solid #8080805c;
  color: #8080805c;
  width: 130px;
  border-radius: 50px;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 100;
}

/* 树形菜单 */

/deep/ .el-tree-node__content {
  height: 35px;
  line-height: 35px;
}

/* 表格 */

/deep/ .el-table th.el-table__cell {
  background-color: #F8F8F8;
}

/deep/ .el-table th.el-table__cell > .cell {
  color: #554 !important;
  font-weight: 600;
  font-size: 1.05rem;
}


/* 模态框 */

/deep/ .el-dialog__title {
  font-weight: 600;
}

/deep/ .el-dialog__header {
  text-align: left;
  border-bottom: 1px solid darkgray;
}

/deep/ .el-dialog__body {
  width: 100%;
  float: left;
  background-color: white;
}

/deep/ .el-upload-dragger {
  height: auto;
}

/deep/ .el-form-item__content {
  text-align: left;
}

</style>
