import Vue from "vue";

export function pageAllBannerApi(params, success) {
  let url = "/admin/web/banner/pageAllBanner";
  Vue.prototype.alexPost(url, params, success);
}


export function deleteBannerApi(params, success) {
  let url = "/admin/web/banner/deleteBanner";
  Vue.prototype.alexPost(url, params, success);
}

export function updateIsNavApi(params, success) {
  let url = "/admin/web/banner/updateIsNav";
  Vue.prototype.alexPost(url, params, success);
}



