import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // code:null
  },
  getters: {},
  mutations: {
    // setCode(state,code){
    //   state.code = code;
    // },
  },
  actions: {},
  modules: {}
});

// thizz.$store.commit('code',"123")
// {{$store.state.code}}

