<template>
  <div id="app">
    <router-view />
  </div>
</template>


<script>

export default {
  data() {
    return {};
  },

  created() {

  },

  mounted() {

  },

  methods: {}
};
</script>

<style>


#app {
  float: left;
  height: 100%;
  width: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

/** 通用容器 */
.full-container {
  width: 100%;
  height: 100%
}







</style>
