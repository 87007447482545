import Vue from "vue";

export function insertDrugApi(params, success) {
  let url = "/admin/web/drug/insertDrug";
  Vue.prototype.alexPost(url, params, success);
}

export function updateDrugApi(params, success) {
  let url = "/admin/web/drug/updateDrug";
  Vue.prototype.alexPost(url, params, success);
}

export function deleteDrugApi(params, success) {
  let url = "/admin/web/drug/deleteDrug";
  Vue.prototype.alexPost(url, params, success);
}

export function pageMerchantDrugApi(params, success) {
  let url = "/admin/web/drug/pageMerchantDrug";
  Vue.prototype.alexPost(url, params, success);
}

export function listAllDrugApi(params, success) {
  let url = "/admin/web/drug/listAllDrug";
  Vue.prototype.alexPost(url, params, success);
}




