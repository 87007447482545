<template>
  <div class="full-container">
    <div
      style="width: 100%;float: left;height: 50px;line-height: 50px;font-size: 0.9rem; text-align: left;opacity: 0.9;">
      微信小程序>轮播图
    </div>
    <div style="width:100%;height:auto;float:left;border-radius: 15px;background-color: white;padding-bottom:40px;">

      <div style="width:100%;float:left;margin-top: 10px;">
        <!-- 查询按钮区 -->
        <div style="width: 100%;height: 60px;float: left;line-height: 60px;padding-left: 15px">
          <!-- 右边按钮 -->
          <div style="width: auto;float: right;margin-right: 20px">
            <el-button size="mini" style="background-color: #25AD7F;border:none;" type="primary" @click="insertShow()">
              <a-icon type="plus-circle" />
              新增轮播图
            </el-button>
            <a-popconfirm title="是否删除?(敏感操作!)" ok-text="确定" cancel-text="取消"
                          @confirm="deleteBannerBatch()">
              <el-button size="mini" style="background-color: #e32e2e1a;color:#e32e2e;border:1px solid #e32e2e;"
                         type="primary">
                <a-icon type="minus-circle" />
                批量删除
              </el-button>
            </a-popconfirm>
          </div>


        </div>

        <!-- 表单内容 -->
        <div style="width:100%;float:left;padding: 15px;">
          <el-table align="center" @selection-change="handleSelectionChange" height="600" :data="tableData"
                    style="color: #555;" size="mini">
            <el-table-column type="selection" width="55"></el-table-column>

            <el-table-column align="center" label="轮播图" min-width="20%">
              <template slot-scope="scope">
                <el-image v-if="scope.row.mainImageGuid" title="点击放大"
                          style="width: 100px; height: 100px"
                          :src="'/admin/web/file/downloadFile?id='+scope.row.mainImageGuid"
                          :preview-src-list="['/admin/web/file/downloadFile?id='+scope.row.mainImageGuid]">
                </el-image>
              </template>
            </el-table-column>

            <el-table-column align="center" prop="url" label="跳转路径" min-width="20%"></el-table-column>
            <el-table-column align="center" label="是否导航跳转" min-width="20%">
              <template slot-scope="scope">
                <el-switch @change="updateIsNav(scope.row.id,scope.row.isNav)" v-model="scope.row.isNav"
                           active-value="1" inactive-value="0" active-color="#13ce66" inactive-color="#ff4949">
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column align="center" sortable prop="sortNo" label="序号" min-width="20%"></el-table-column>
            <el-table-column align="center" label="创建时间" min-width="20%">
              <template slot-scope="scope">
                {{ parseDate(scope.row.createDate ? scope.row.createDate : "") }}
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作" min-width="20%">
              <template slot-scope="scope">

                <span style="font-size:0.9rem;cursor: pointer;color: #409EFF"
                      @click="updateShow(scope.row)">修改</span>
                <a-popconfirm title="是否删除?(敏感操作!)" ok-text="确定" cancel-text="取消"
                              @confirm="deleteBanner(scope.row)">
                  <span style="margin:0px 5px">|</span>
                  <span style="font-size:0.9rem;color:#e32e2e;cursor: pointer;">删除</span>
                </a-popconfirm>
              </template>
            </el-table-column>
          </el-table>
          <div style="width: 100%;float: right;margin-top: 10px;text-align: right;">
            <el-pagination
              @current-change="handleCurrentChange"
              :page-size="selectForm.pageSize"
              :current-page="selectForm.pageIndex"
              layout="total,prev, pager, next"
              :total="selectForm.total">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>

    <el-dialog title="新增轮播图" :visible.sync="insertDialog" width="40%" :before-close="insertCancel">
      <div style="width: 100%;float: left">
        <el-form label-width="15%" style="width:100%;float:left;">


          <el-form-item label="">
            <template slot="label">
              缩略图 <span style="color: red">*</span>
            </template>
            <el-upload action="#" list-type="picture-card" :on-change="changeCompanyIdCard"
                       :show-file-list="false" :multiple="false" :auto-upload="false">
              <el-image fit="contain" v-if="mainImageFile" :src="mainImageFile.url" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>

          <el-form-item style="">
            <template slot="label">
              跳转路径
            </template>
            <el-input size="mini" style="width:85%;float: left;" v-model="insertForm.url"
                      placeholder="/pages/index/index" oninput="if(value.length>35)value=value.slice(0,35)" />
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>

          <el-form-item style="">
            <template slot="label">
              是否导航跳转 <span style="color: red">*</span>
            </template>
            <el-switch v-model="insertForm.isNav" :active-value="1" :inactive-value="0" active-color="#13ce66"
                       inactive-color="#ff4949"></el-switch>
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>

          <el-form-item style="">
            <template slot="label">
              排序 <span style="color: red">*</span>
            </template>
            <el-input size="mini" style="width:85%;float: left;" v-model="insertForm.sortNo" type="number"
                      class="numberInput" placeholder="从小到大优先显示"
                      oninput="if(value.length>35)value=value.slice(0,35)" />
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>
        </el-form>

      </div>
      <div class="dialog-footer">
        <el-button class="dialog-footer-submit" @click="insertSubmit">提交</el-button>
        <el-button class="dialog-footer-cancel" @click="insertCancel">取消</el-button>
      </div>
    </el-dialog>


    <!-- 修改 -->
    <el-dialog title="修改轮播图" :visible.sync="updateDialog" width="40%" :before-close="updateCancel">
      <div style="width: 100%;float: left">
        <el-form label-width="15%" style="width:100%;float:left;">


          <el-form-item label="">
            <template slot="label">
              缩略图 <span style="color: red">*</span>
            </template>
            <el-upload action="#" list-type="picture-card" :on-change="updateChangeCompanyIdCard"
                       :show-file-list="false" :multiple="false" :auto-upload="false">
              <el-image ref="mainref" fit="contain" v-if="updateMainImageFileUrl" :src="updateMainImageFileUrl"
                        class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>

          <el-form-item style="">
            <template slot="label">
              跳转路径
            </template>
            <el-input size="mini" style="width:85%;float: left;" v-model="updateForm.url"
                      placeholder="/pages/index/index" oninput="if(value.length>35)value=value.slice(0,35)" />
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>

          <el-form-item style="">
            <template slot="label">
              是否导航跳转 <span style="color: red">*</span>
            </template>
            <el-switch v-model="updateForm.isNav" active-value="1" inactive-value="0" active-color="#13ce66"
                       inactive-color="#ff4949"></el-switch>
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>

          <el-form-item style="">
            <template slot="label">
              排序 <span style="color: red">*</span>
            </template>
            <el-input size="mini" style="width:85%;float: left;" v-model="updateForm.sortNo" type="number"
                      class="numberInput" placeholder="从小到大优先显示"
                      oninput="if(value.length>35)value=value.slice(0,35)" />
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>

        </el-form>

      </div>
      <div class="dialog-footer">
        <el-button class="dialog-footer-submit" @click="updateSubmit">提交</el-button>
        <el-button class="dialog-footer-cancel" @click="updateCancel">取消</el-button>
      </div>
    </el-dialog>


  </div>
</template>

<script>


import { deleteBannerApi, pageAllBannerApi, updateIsNavApi } from "@/router/api/banner/banner-api";

export default {
  name: "BannerIndex",
  data() {
    return {
      alexBoolList: [],

      // 查询
      tableData: [],
      selectForm: {
        pageIndex: 1,
        pageSize: 10,
        total: 0
      },
      selectTableRow: [],

      //新增
      insertDialog: false,
      insertForm: {
        url: null,
        sortNo: null,
        isNav: 0

      },
      mainImageFile: null,

      //修改
      updateDialog: false,
      updateForm: {
        id: null,
        url: null,
        sortNo: null,
        isNav: null
      },
      updateMainImageFile: null,
      updateMainImageFileUrl: null

    };
  },

  mounted() {

    this.load();


  },

  methods: {

    load() {


      this.pageAllBanner();
    },


    // 查询 begin
    selectReset() {
      this.selectForm = {
        pageIndex: 1,
        pageSize: 10,
        total: 0
      };
      this.pageAllBanner(true);
    },

    handleCurrentChange(e) {
      this.selectForm.pageIndex = e;
      this.pageAllBanner(true);
    },

    handleSelectionChange(val) {
      this.selectTableRow = val;
    },

    pageAllBanner(showMessage) {
      pageAllBannerApi(this.selectForm, res => {
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              if (showMessage) {
                this.showMessage(res.data.msg, "success");
              }
              this.tableData = res.data.resultSetData.list;
              this.selectForm.pageIndex = res.data.resultSetData.pageNum;
              this.selectForm.pageSize = res.data.resultSetData.pageSize;
              this.selectForm.total = res.data.resultSetData.total;
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });
    },


    // 查询 end


    // 新增 begin

    insertShow() {
      this.insertDialog = true;
    },

    insertCancel() {
      this.insertDialog = false;
      this.insertForm = {
        url: null,
        sortNo: null
      };
      this.mainImageFile = null;

    },


    // 营业执照上传
    changeCompanyIdCard(file) {
      this.mainImageFile = file;
    },


    insertSubmit() {

      //TODO 非空判断
      if (!this.insertForm.sortNo) {
        this.showMessage("请填写序号", "error");
        return false;
      }
      debugger;
      //数据组装
      let params = new FormData();
      if (this.insertForm.url) {
        params.append("url", this.insertForm.url);
      }
      params.append("sortNo", this.insertForm.sortNo);
      if (this.insertForm.isNav) {
        params.append("isNav", this.insertForm.isNav);
      }


      if (this.mainImageFile) {
        params.append("mainImageMultipartFile", this.mainImageFile.raw);
      } else {
        this.showMessage("请上传缩略图", "error");
        return false;
      }


      let url = "/admin/web/banner/insertBanner";
      this.alexPost(url, params, res => {
        //状态200
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.showMessage(res.data.msg, "success");
              this.insertCancel();
              this.pageAllBanner();
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      }, {
        headers: {
          "Content-Type": "multipart/form-data",
          "token": localStorage.getItem("token")
        }
      });
    },
    // 新增 end


    // 删除 begin
    deleteBanner(item) {
      if (!item) {
        this.showMessage("请选择需要删除的轮播图", "warning");
        return false;
      }
      let params = [
        {
          "id": item.id
        }
      ];
      deleteBannerApi(params, res => {
        //状态200
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.showMessage(res.data.msg, "success");
              this.pageAllBanner();
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });


    },

    deleteBannerBatch() {

      if (!this.selectTableRow || this.selectTableRow.length == 0) {
        this.showMessage("请勾选需要需要删除的轮播图", "warning");
        return false;
      }

      let params = [];
      for (let i = 0; i < this.selectTableRow.length; i++) {
        let row = this.selectTableRow[i];
        if (row) {
          params.push({
            "id": row.id
          });
        }
      }

      deleteBannerApi(params, res => {
        //状态200
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.showMessage(res.data.msg, "success");
              this.pageAllBanner();
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });


    },

    // 删除 end

    // 修改 begin

    updateIsNav(id, isNav) {
      let params = {
        "id": id,
        "isNav": isNav
      };
      updateIsNavApi(params, res => {
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.showMessage(res.data.msg, "success");
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });
    },

    updateShow(data) {
      this.updateDialog = true;
      this.updateForm = {
        id: data.id,
        url: data.url,
        sortNo: data.sortNo,
        isNav: data.isNav
      };

      this.updateMainImageFileUrl = "/admin/web/file/downloadFile?id=" + data.mainImageGuid;//缩略图返显 上传

    },


    updateCancel() {
      this.updateDialog = false;
      this.updateForm = {
        id: null,
        url: null,
        sortNo: null

      };
      this.updateMainImageFile = null;
      this.updateMainImageFileUrl = null; //返现


    },


    // 营业执照上传
    updateChangeCompanyIdCard(file) {
      this.updateMainImageFile = file;
      this.updateMainImageFileUrl = file.url;
    },


    updateSubmit() {

      //TODO 非空判断
      if (!this.updateForm.sortNo) {
        this.showMessage("请填写轮播图序号", "error");
        return false;
      }


      //数据组装
      let params = new FormData();
      if (this.updateForm.url) {
        params.append("url", this.updateForm.url);
      }
      params.append("id", this.updateForm.id);
      params.append("sortNo", this.updateForm.sortNo);
      if (this.updateForm.isNav) {
        params.append("isNav", this.updateForm.isNav);
      }

      if (this.updateMainImageFile) {
        params.append("mainImageMultipartFile", this.updateMainImageFile.raw);
      }


      let url = "/admin/web/banner/updateBanner";
      this.alexPost(url, params, res => {
        //状态200
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.showMessage(res.data.msg, "success");
              this.updateCancel();
              this.pageAllBanner();
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      }, {
        headers: {
          "Content-Type": "multipart/form-data",
          "token": localStorage.getItem("token")
        }
      });
    },

    // 修改 end

    // 工具
    parseDate(date) {
      if (date) {
        return new Date(date).toLocaleString();
      }
      return "";
    }
  }


}
;

</script>


<style scoped>

/deep/ .el-card__body, .el-main {
  float: left;
  width: 100%;
}

/*  通用 */
.dialog-footer {
  width: 100%;
  float: left;
  text-align: left;
}

.dialog-footer-submit {
  background-color: #25AD7F;
  color: white;
  width: 130px;
  border-radius: 50px;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 100;
}

.dialog-footer-submit:hover {
  background-color: #25AD7F;
  color: white;
  width: 130px;
  border-radius: 50px;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 100;
}

.dialog-footer-cancel {
  background-color: white;
  border: 1px solid #8080805c;
  color: #8080805c;
  width: 130px;
  border-radius: 50px;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 100;
}

.dialog-footer-cancel:hover {
  background-color: white;
  border: 1px solid #8080805c;
  color: #8080805c;
  width: 130px;
  border-radius: 50px;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 100;
}

/* 树形菜单 */

/deep/ .el-tree-node__content {
  height: 35px;
  line-height: 35px;
}

/* 表格 */

/deep/ .el-table th.el-table__cell {
  background-color: #F8F8F8;
}

/deep/ .el-table th.el-table__cell > .cell {
  color: #554 !important;
  font-weight: 600;
  font-size: 1.05rem;
}


/* 模态框 */

/deep/ .el-dialog__title {
  font-weight: 600;
}

/deep/ .el-dialog__header {
  text-align: left;
  border-bottom: 1px solid darkgray;
}

/deep/ .el-dialog__body {
  width: 100%;
  float: left;
  background-color: white;
}

/deep/ .el-upload-dragger {
  height: auto;
}

/deep/ .el-form-item__content {
  text-align: left;
}


/* 纯数字 */
/deep/ .numberInput input::-webkit-outer-spin-button,
/deep/ .numberInput input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

/deep/ .numberInput input[type="number"] {
  -moz-appearance: textfield;
}

</style>
