<template>
  <div class="home-container">
    空也
  </div>
</template>

<script>

export default {
  name: "EmptyIndex",
  data() {
    return {
      map: {}
    };
  },
  computed: {},
  mounted() {
  },

  methods: {}


};

</script>


<style scoped>
.home-container {
  width: 100%;
  float: left;
}

</style>
