<template>
  <div class="full-container">

    <div style="background-color: white;height: 40px;line-height: 40px;text-align: left;padding-left: 10px">
      <span style="font-size: 0.8rem;background-color: #F2F2F2;padding: 5px;border: 1px solid black;">商品管理/系列详情</span>
    </div>

    <div style="background-color: white;text-align: left;padding: 15px 10px;margin-top: 10px">
      <button @click="insertShow"
              style="font-size: 0.9rem;background-color: #02A7F0;padding: 5px;color: white;border: 1px solid #02A7F0;border-radius: 5px;">
        添加商品
      </button>
    </div>

    <div class="panel-container" style="margin-top: 10px;">
      <!-- 导航栏 -->
      <div class="panel-content">
        <!-- 表单头部 -->
        <div class="panel-head" style="border-bottom: none;">
          <!-- 查询按钮区 -->
          <div style="width: 100%;height: 60px;float: left;line-height: 60px;padding-left: 15px">
            <div style="width: auto;float: left;margin-right: 20px">
              <label style="width: auto;float:left;">是否可转发：</label>
              <el-select size="mini" style="width:100px;float: left;" v-model="selectForm.isShare" clearable filterable>
                <el-option v-for="item in alexBoolList" :key="item.value" :label="item.zhName" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div style="width: auto;float: left;margin-right: 20px">
              <label style="width: auto;float:left;">商品名称：</label>
              <el-input size="mini" style="width: 200px;float: left;" placeholder="请输入商品名称" clearable
                        prefix-icon="el-icon-search" v-model="selectForm.key"></el-input>
            </div>
            <div style="width: auto;float: left;margin-right: 20px">
              <el-button size="mini" type="primary" @click="getGoodsDetailList(true)">搜索</el-button>
            </div>
          </div>
        </div>

        <!-- 表单内容 -->
        <div class="panel-body" style="padding: 0px;">
          <el-table align="center" :data="goodsDetailTableData" style="color: #555;" size="mini">
            <el-table-column align="center" sortable prop="" label="序号" min-width="20%"></el-table-column>
            <el-table-column align="center" prop="name" label="商品名称" min-width="20%"></el-table-column>
            <el-table-column align="center" label="商品主图" min-width="20%">
              <template slot-scope="scope">
                <el-image v-if="scope.row.mainImageGuid" title="点击放大"
                          style="width: 100px; height: 100px"
                          :src="'/admin/file/downloadFile?id='+scope.row.mainImageGuid"
                          :preview-src-list="['/admin/file/downloadFile?id='+scope.row.mainImageGuid]">
                </el-image>
              </template>
            </el-table-column>

            <el-table-column align="center" prop="name" label="所属系列" min-width="20%">
              <template slot-scope="scope">
                {{goods?goods.name:''}}
              </template>


            </el-table-column>

            <el-table-column align="center" prop="" label="抽中概率" min-width="20%">
              <template slot-scope="scope">
               {{scope.row.numerator}}/{{scope.row.denominator}}
              </template>

            </el-table-column>


            <el-table-column align="center" label="是否可转发" min-width="20%">
              <template slot-scope="scope">
                <el-switch @change="updateGoodsDetailIsShare(scope.row.id,scope.row.isShare)" v-model="scope.row.isShare"
                           active-value="1" inactive-value="0" active-color="#13ce66" inactive-color="#ff4949">
                </el-switch>
              </template>
            </el-table-column>

            <el-table-column align="center" label="操作" min-width="20%">
              <template slot-scope="scope">
                <a-popconfirm title="是否删除?(敏感操作!)" ok-text="确定" cancel-text="取消" @confirm="deleteGoodsDetail(scope.row.id)">
                  <span style="font-size:1rem;color:#02A7F0;cursor: pointer;" >删除</span>|
                </a-popconfirm>
                <span style="font-size:1rem;color:#02A7F0;cursor: pointer;"   @click="updateGoodsDetail(scope.row.id)">编辑</span>
              </template>
            </el-table-column>
          </el-table>

        </div>
      </div>
    </div>


    <el-dialog title="新增商品" :visible.sync="insertDialog" width="30%" :before-close="insertCancel">
      <div>
        <el-form label-width="15%">

          <el-form-item label="系列名称">
            <span>{{goods?goods.name:''}}</span>
            <div style="width: 15%;float: left;"></div>
          </el-form-item>

          <el-form-item label="商品名称">
            <el-input maxlength="15" show-word-limit style="width:85%;float: left;" v-model="insertForm.name"/>
            <div style="width: 15%;float: left;"></div>
          </el-form-item>

          <el-form-item label="商品主图">
            <el-upload action="#" list-type="picture-card" :on-change="changeMainImage"
                       :show-file-list="false" :multiple="false" :auto-upload="false">
              <el-image :fit="contain" v-if="mainPictureUrl" :src="mainPictureUrl.url" class="avatar"/>
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>

          <el-form-item label="抽中概率">
            <el-input type="number" style="width:85%;float: left;" v-model="insertForm.numerator"/>
            <span>/</span>
            <el-input type="number" style="width:85%;float: left;" v-model="insertForm.denominator"/>
            <div style="width: 15%;float: left;"></div>
          </el-form-item>

          <el-form-item label="是否可转发">
            <el-switch v-model="insertForm.isShare" :active-value="1" :inactive-value="0" active-color="#13ce66"
                       inactive-color="#ff4949"></el-switch>
          </el-form-item>

        </el-form>

      </div>
      <span slot="footer" class="dialog-footer">
          <el-button style="background-color: #AFE3FB;color: black" @click="insertCancel">取 消</el-button>
          <el-button style="background-color: #02A7F0;color: black" type="success"
                     @click="insertSubmit">确 定</el-button>
      </span>
    </el-dialog>


  </div>
</template>

<script>

import { getAlexBoolListApi } from "@/router/api/common/data-api";

import {
  getGoodsInfoApi
} from "@/router/api/goodsApi";

import {getGoodsDetailListApi,updateGoodsDetailIsShareApi,deleteGoodsDetailApi} from "@/router/api/goodsDetailApi"

export default {
  name: "GoodsDetailIndex",
  data() {
    return {

      alexBoolList: [],
      goods:null,

      selectForm: {
        goodsId:null,
        key: null,
        isShare: null,

      },
      goodsDetailTableData: [],

      insertDialog: false,
      insertForm: {
        name: null,
        numerator: 0,
        denominator: 0,
        isShare: 1,
      },
      mainPictureUrl: "", //缩略图返显 上传

    };
  },

  mounted() {

    this.load();


  },

  methods: {

    load() {




      getGoodsInfoApi({"id":this.$route.query.goodsId},res=>{
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.goods = res.data.resultSetData;
              this.getGoodsDetailList();
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      })

      getAlexBoolListApi({}, res => {
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.alexBoolList = res.data.resultSetData;
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      })


    },


    // 查询 begin
    selectFormReset() {
      this.selectForm = {
        key: null,
        isShare: null,
      };
    },

    fileGuidConvertToUrl(stringList) {
      let urlList = [];
      if (stringList && stringList.length > 0) {
        for (let i = 0; i < stringList.length; i++) {
          urlList.push("/admin/file/downloadFile?id=" + stringList[i]);
        }

      }
      return urlList;
    },

    getGoodsDetailList(isShow) {
      this.selectForm.goodsId = this.goods.id;
      getGoodsDetailListApi(this.selectForm, res => {
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              if (isShow) {
                this.showMessage(res.data.msg, "success");
              }
              this.goodsDetailTableData = res.data.resultSetData;
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      })
    },

    // 查询 end
    // 新增 begin

    insertShow() {
      this.insertDialog = true;
    },

    insertCancel() {
      this.insertDialog = false;

      this.insertForm = {
        name: null,
        numerator: 0,
        denominator: 0,
        isShare: 1,
      }
      this.mainPictureUrl = "";//缩略图返显 上传
    },

    //选择缩略图后
    changeMainImage(file) {
      this.mainPictureUrl = file;
    },

    insertSubmit() {
      //数据组装
      let params = new FormData();
      params.append("goodsId", this.goods.id);
      params.append("name", this.insertForm.name);
      params.append("numerator", this.insertForm.numerator);
      params.append("denominator", this.insertForm.denominator);
      params.append("isShare", this.insertForm.isShare);

      if (this.mainPictureUrl) {
        params.append("mainImageFileUpload", this.mainPictureUrl.raw);
      }


      let url = "/admin/goodsDetail/insertGoodsDetail";
      this.alexPost(url, params, res => {
        //状态200
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.showMessage(res.data.msg, "success");
              this.insertCancel();
              this.getGoodsDetailList();
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      }, {
        headers: {
          "Content-Type": "multipart/form-data",
          "token": localStorage.getItem("token")
        }
      });
    },

    // 新增 end


    //修改 begin

    updateGoodsDetailIsShare(goodsDetailId, isShare) {
      let params = {
        "id": goodsDetailId,
        "isShare": isShare
      };
      updateGoodsDetailIsShareApi(params, res => {
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.showMessage(res.data.msg, "success");
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      })
    },

    //修改 end

    //////////////////////////////////////////////////////////////////////////////////////////


    deleteGoodsDetail(goodsDetailId) {
      let params = {
        "id": goodsDetailId
      };
      deleteGoodsDetailApi(params, res => {
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.showMessage(res.data.msg, "success");
              this.getGoodsDetailList();
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      })

    },

  }

};

</script>


<style scoped>

/deep/ .el-table th.el-table__cell > .cell {
  color: #554 !important;
  font-weight: 600;
  font-size: 1.05rem;
}

/deep/ .el-dialog__title {
  font-weight: 600;
}

/deep/ .el-dialog__header {
  border-bottom: 1px solid darkgray;
}

/deep/ .el-upload-dragger {
  height: auto;
}

/deep/ .el-form-item__content {
  text-align: left;
}

</style>
